import modalStyle from "../material-kit-pro-react/modalStyle.jsx";

const noteModalStyle = theme => ({
    ...modalStyle(theme),
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
        "& .last-modified": {
            marginTop: "10px",
            color: theme.customize.whiteBlackColor,
            "& span": {
                color: theme.customize.whiteBlackColor,
                fontWeight: "bold",
            }
        },
        "&.bottom-border": {
            borderBottom: "1px solid #D8D9E0",
        }
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "50px",
        paddingTop: "50px",
        paddingRight: "0px",
        paddingLeft: "0px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& h4": {
            textAlign: "center",
            marginTop: "0px",
            marginBottom: '15px',
        },
        "& li": {
            opacity: 1,
            color: theme.customize.whiteBlackColor,
        },
        "& .edit-section": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        "& span": {
            color: theme.customize.whiteBlackColor,
            fontWeight: "bold",
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
});
  
export default noteModalStyle;
  