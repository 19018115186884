import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const newFolderModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "65px",
        paddingBottom: "50px",
        "&.empty-space": {
            paddingTop: "25px",
            paddingBottom: "20px",
        },
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    backupCodeFooter: {
        flexWrap: "wrap"
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    extension: {
        display: "block",
        textAlign: "right"
    },
    backupCodes: {
        paddingLeft: "0px",
        listStyleType: "none",
        display: "flex",
        gap: "10px",
        flexWrap: "wrap",
        "& li": {
            padding: "10px",
            backgroundColor: theme.customize.gridBgColor,
            borderRadius: "5px",
            flex: "48%",
            "& p": {
                margin: "0px",
                textAlign: "center"
            },
        }
    }
});
  
export default newFolderModalStyle;
  