import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader";
import CustomInput from "../CustomInput/CustomInput.jsx";
import Close from "@material-ui/icons/Close";

import confirmationModalStyle from "../../../assets/jss/user/confirmationModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DeleteConfirmationModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            deleteText: ''
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    render() {
        const { classes, saving, confirmationMessage, checked } = this.props;
        const { deleteText } = this.state;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    {
                        saving ?
                            <></>
                        :
                            <h4 className={classes.modalTitle}>{confirmationMessage}</h4> 
                    }
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                >
                    {
                        saving ?
                            <LoaderComponent color="custom" align="center" />
                        :
                            <>
                                {
                                    this.props.deleteNote ?
                                        <p className={"delete-note"}>Any files associated to the link(s) will not be deleted.</p>
                                    :
                                        <></>
                                }
                                {
                                    checked.length > 5 ?
                                        <CustomInput
                                            id="input-deleteText"
                                            labelText="Type delete for confirm"
                                            inputProps={{
                                                onChange: (e) => this.handleChange(e,'deleteText'),
                                                value: deleteText,
                                                name: "deleteText",
                                                type: "text",
                                            }}
                                            color="custom"
                                            formControlProps={{
                                                fullWidth: true,
                                                className: "custom-input m-zero"
                                            }}
                                        />
                                    :
                                        <></>
                                }
                            </>
                    }
                </DialogContent>
                    
                
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        saving === false ?
                            <>
                                <Button color="custom" disabled={ checked.length > 5 && deleteText.toLowerCase() !== 'delete' ? true : false }className={classes.rightButton} onClick={() => this.props.onSuccess(false)} >
                                    Delete
                                </Button>
                                <Button color="white" onClick={() => this.props.onClose()}>
                                    Cancel
                                </Button>
                            </>
                        :
                        <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

DeleteConfirmationModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false,
    checked: [],
}
DeleteConfirmationModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(confirmationModalStyle)(DeleteConfirmationModal);