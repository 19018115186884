import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import processingModalStyle from "../../../assets/jss/user/processingModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ContactSupportModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
    }
    render() {
        const { classes } = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="processingModal-modal-slide-title"
                aria-describedby="processingModal-modal-slide-description"
            >
                <DialogTitle
                    id="processingModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Contact Sendlinx Support</h4>
                </DialogTitle>
                <DialogContent
                    id="processingModal-modal-slide-description"
                    className={classes.modalBody}
                >
                    <p>If you are experiencing issues and are unable to access your account, we recommend following these steps to recover your account:</p>
                    <p>Please contact our support team and include the following information in your message:</p>
                    <ul className="list-items">
                        <li><p>A detailed description of the issue (e.g., unable to receive 2FA codes via Phone SMS.</p></li>
                        <li><p>Any troubleshooting steps you have already taken (e.g., trying backup codes, ensuring device time sync, etc.).</p></li>
                        <li><p>If applicable, mention any recent changes to your device or phone number that might be affecting 2FA.</p></li>
                        <li><p>Your account email address and username to help us identify your account.</p></li>
                    </ul>
                    <p>Once we have this information, we will assist you with recovering access to your account and, if needed, disabling 2FA temporarily to help you regain access.</p>
                    <p>Please send this information to our support team at support@sendlinx.com</p>
                    <p>Thank you for your patience, and we look forward to assisting you further.</p>
                    <p className='mt-20 mb-0'>Best regards,</p>
                    <p>Sendlinx Support Team</p>
                </DialogContent>
                <DialogActions className={classes.modalFooter+" "+classes.justifyContentCenter}>
                    <Button color="custom" onClick={() => this.props.onClose()}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ContactSupportModal.defaultProps = {
    open: false,
}
ContactSupportModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
export default withStyles(processingModalStyle)(ContactSupportModal);