import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api.js";
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth.js";
import BackgroundSlider from "../../components/Slider/BackgroundSlider.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
import LoaderComponent from '../../components/Loader.js'

const AccountRecovery = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        this.state = {
            editInfo: false,
            orignalValues: values,
            values: values,
            savingInfo: false,
            showError: false,
            errorMessage: "",
            validation: {
                recovery_email: '',
                recovery_phone: '',
                isValid: false
            },
            saving: false,
            activeTab: 3,
        }
        this.newUser = null;
        this.saveUserInfo = this.saveUserInfo.bind(this);  
        this.editUserInfo = this.editUserInfo.bind(this); 
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }     
    }
    editUserInfo(editInfo = true){
        let state = {
            editInfo: editInfo
        }
        if(editInfo === false){
            state['values'] = {
                ...this.state.values,
            }
        }
        this.setState(state);
    }
    saveUserInfo(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({
            editInfo: false,
            savingInfo: true,
            showError: false
        });
        this.updateUser();
    }
    validateForm(){
        const { values } = this.state;
        const { user } = this.store.getState();

        let validation = {
            recovery_email: "success",
            recovery_phone: "success",

            isValid: true
        };

        var emailRegex = /\S+@\S+\.\S+/;
        var recovery_email = values.recovery_email.trim();
        if(recovery_email.length > 0 && (!emailRegex.test(values.recovery_email) || recovery_email === user.email)){
            validation.recovery_email = "error";
            validation.isValid = false;
        }
        
        const cellRegex = /^[0-9]{9,15}$/;
        var recovery_phone = values.recovery_phone.trim();
        if(recovery_phone.length > 0 && !cellRegex.test(values.recovery_phone)){
            validation.recovery_phone = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    updateUser(){
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.values);
        values.skipValidation = 1;

        const source = axios.CancelToken.source();
        Api.updateUser(user.id, values, source).then((data) => {
            this.newUser = data.user;
            this.setState({
                savingInfo: false, 
            });
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
            let newUser = Api.prepareMemberObject(data.user);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            console.log(err);
            this.setState({
                savingInfo: false, 
                showError: true, 
                errorMessage: err.message
            });
        });
    }
    handleChange(event, name) {
        let value = event.target.value;
        let limit = 64;
        if(name === "recovery_email"){
            limit = 128;
        }
        if(typeof(value) === "string"){
            value = value.substr(0, limit);
        }
        if(name === "recovery_email"){
            value = value.toLowerCase();
        }

        this.setState({
            values: {
                ...this.state.values, 
                [name]: value
            }
        });
    }
    handlePhoneChange(e, name) {
        const { recovery_phone } = this.state.values;

        let state = {};
        const value = e.target.value;
        state[name] = value;
        const regex = /^\d+$/;
        if((value.length > 0 && !regex.test(value)) || value.length > 16){
            state[name] = recovery_phone;
        }
        this.setState({
            values: {
                ...this.state.values, 
                [name]: state[name]
            }
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    onTabChange(urlTab){
        if(urlTab === '0'){
            this.setState({activeTab:0})
            this.history.push("/user/security");
        }else if(urlTab === '1'){
            this.setState({activeTab:1})
            this.history.push("/user/accounts/license");
        }else if(urlTab === '2'){
            this.setState({activeTab:2})
            this.history.push("/user/password");
        }else if(urlTab === '3'){
            this.setState({activeTab:3})
            this.history.push("/user/account/recovery");
        }
    }
    saveButtonDisabled(){
        const { orignalValues, values } = this.state;
        if(
            (orignalValues.recovery_email !== values.recovery_email) ||
            (orignalValues.recovery_phone !== values.recovery_phone)
        ){
            return false
        }

        return true;
    }
    render() {
        const { classes } = this.props;
        const { values, validation, showError, activeTab, savingInfo, editInfo } = this.state;
        const { user, authorized } = this.store.getState();

        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }

        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.profileContainer}>
                    <div className={classes.content}>
                        <div className={classes.controls}>
                            <GridItem className={classes.controlsTabs}>
                                <div className='sd-custom-tabs'>
                                    <div className={'sd-custom-tab '+(activeTab === 0 ? 'active': '')} onClick={() => this.onTabChange("0")}>
                                        <span>Two Factor Authentication</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 2 ? 'active': '')} onClick={() => this.onTabChange("2")}>
                                        <span>Password</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 1 ? 'active': '')} onClick={() => this.onTabChange("1")}>
                                        <span>Connected Account(s)</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 3 ? 'active': '')} onClick={() => this.onTabChange("3")}>
                                        <span>Account Recovery</span>
                                    </div>
                                </div>
                            </GridItem>
                        </div>
                        <GridItem className={classes.main} xs={12} sm={12} md={8} lg={6}>
                            <div className={"userInfo"}>
                                {
                                    showError ?
                                        this.renderErrorMessages()
                                    :
                                        <></>
                                }
                                <p className='alignLeft'>Using a different email or phone number for account recovery adds security by providing a backup in case your primary contact is compromised or inaccessible. It helps protect against account hijacking, ensures quicker recovery, and reduces dependency on a single contact method.</p>
                                <CustomInput
                                    success={validation.recovery_email === "success"}
                                    error={validation.recovery_email === "error"}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                    id="input-recovery_email"
                                    labelText="Recovery Email"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e, 'recovery_email'),
                                        name: "recovery_email",
                                        value: values.recovery_email,
                                        disabled: !editInfo,
                                    }}
                                />
                                {
                                    values.recovery_email === user.email ?
                                        <p className={classes.adminNote}>Recovery email must be different than the Sendlinx Account email</p>
                                    :
                                        <></>
                                }
                                <CustomInput
                                    success={validation.recovery_phone === "success"}
                                    error={validation.recovery_phone === "error"}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                    id="input-recovery_phone"
                                    labelText="Recovery Phone No"
                                    inputProps={{
                                        onChange: (e) => this.handlePhoneChange(e, 'recovery_phone'),
                                        name: "recovery_phone",
                                        value: values.recovery_phone,
                                        disabled: !editInfo,
                                    }}
                                />
                                <div className='footer-buttons'>
                                    {
                                        editInfo === false && savingInfo === false ?
                                            <div className={"editButton"}>
                                                <Button color="custom" onClick={() => this.editUserInfo()}>Edit</Button>
                                            </div>
                                        : savingInfo ? 
                                            <div className={"editButton"}>
                                                <Button color="custom">
                                                    <LoaderComponent color="custom" align="left" saving={true} />
                                                </Button>
                                            </div>
                                        : 
                                            <></>
                                    }
                                    {
                                        editInfo === true ?
                                            <div className={"saveButtons"}>
                                                <Button color="white" onClick={() => this.editUserInfo(false)}>
                                                    Cancel
                                                </Button>
                                                <Button color="custom" disabled={this.saveButtonDisabled()}  onClick={() => this.saveUserInfo()}>
                                                    Save
                                                </Button>
                                            </div>
                                        :
                                            <></>
                                    }
                                </div>
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

AccountRecovery.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(AccountRecovery);
