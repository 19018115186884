import React from 'react';
import axios from "axios";
import Cookie from '../../../assets/js/utils/Cookie';
import PropTypes from "prop-types";
import Config from "../../../../Config";
import { createBrowserHistory } from 'history';
import { Redirect } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import Api from "../../../assets/js/utils/Api";
import { helper } from "../../../assets/js/utils/Element";
import LoaderComponent from '../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Warning from "../../components/Typography/Warning";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import ContactSupportModal from "../../components/User/ContactSupportModal";

import loginPageStyle from "../../../assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";
const history = createBrowserHistory();

const Login = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBackupCodeSubmit = this.handleBackupCodeSubmit.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.handleForgotEmail = this.handleForgotEmail.bind(this);
        this.handleTwoFaSubmit = this.handleTwoFaSubmit.bind(this);

        this.state = {
            email: "",
            emailPhone: "",
            password: "",
            passwordConfirm: "",
            code: "",
            backupCode: "",
            cell: "",
            country_id: "",
            loading: false,
            validation: {
                email: '',
                emailPhone: '',
                password: '',
                passwordConfirm: '',
                code: '',
                backupCode: "",
                cell: '',
                isValid: false
            },
            showError: false,
            errorMessage: "",
            forgotPassword: this.props.forgot,
            forgotEmail: this.props.forgotEmail,
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
            response: null,
            phoneVerification: false,
            confirmCell: false,
            countries: [],
            securityKeyVerification: false,
            twofaVerification: false,
            backupCodeVerification: false,
            forgotEmailUsers: false,
            users: [],
            forgotPasswordMethods: false,
            forgotType: '',
            recoveryEmail: '',
            recoveryPhone: '',
            contactSupportModal: false,
            displayContactSupportLink: false,
        }
    }
    handleChange(e, name){
        let state = {};
        let value = e.target.value;
        if(name === "email" || name === "emailPhone"){
            value = value.toLowerCase();
        }
        let limit = 64;
        if(name === "email" || name === "emailPhone"){
            limit = 128;
        }
        state[name] = value.substr(0, limit);
        this.setState(state);
    }
    componentDidMount(){
        this.loadCountries();
    }
    componentDidUpdate(prevProps, prevState){
        const { password, passwordConfirm } = this.state;
        let validatePassword = false;
        let validatePasswordConfirm = false;
        if(prevState.password !== password){
            validatePassword = true;            
        }
        if(prevState.passwordConfirm !== passwordConfirm){
            validatePasswordConfirm = true;            
        }
        if(this.state.requestCode && (validatePassword || validatePasswordConfirm)){
            this.validateResetForm(validatePassword, validatePasswordConfirm);
        }
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            that.setState({
                countries: data.response,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    handleSubmit(e){
        e.preventDefault();
        if(this.state.forgotEmail === true && this.state.requestCode === true){
            this.handleForgotEmailVerifyCode();
            return;
        }
        if(this.state.forgotEmail === true){
            this.handleForgotEmailSubmit();
            return;
        }
        if(this.state.forgotPassword === true && this.state.requestCode === true){
            this.handleResetPassword();
            return;
        }
        if(this.state.forgotPassword === true){
            this.handleForgotSubmit();
            return;
        }
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        const source = axios.CancelToken.source();
        const { email, password } = this.state;
        const requestData = {
            email: email,
            password: password,
            application: "Web"
        }
        Api.login(requestData, source).then(data => {
            if(data.requirePhoneVerification){
                this.handleRequirePhoneVerification(data);
            }else if(data.requireBackupCodesVerification){
                this.handleBackupCodeVerification(data);
            }else if(data.requireSecurityKeyVerification){
                this.handleSecurityKeyVerification(data);
            }else{
                this.handleLoginSuccess(data);
            }
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    handleBackupCodeSubmit(e){
        e.preventDefault();
        const { backupCode, response, password } = this.state;
        
        if(backupCode.length < 8){
            this.setState({
                validation: {
                    ...this.state.validation,
                    backupCode: "error"
                }
            });
            return;
        }
        const requestData = {
            backupCode: backupCode,
            email: response.user.email,
            password: password
        };
        const source = axios.CancelToken.source();
        Api.verifyBackupCode(requestData, source).then((data) => {
            this.handleLoginSuccess(data);
        }).catch(err => {
            this.setState({
                loading: false, 
                showError: true, 
                cancelToken: null,
                errorMessage: err.message
            });
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    loginSecurity = async () => {
        const { response, password } = this.state;

        try {
            const source = axios.CancelToken.source();
            
            let requestData = {
                id: response.user.id
            };

            const data = await Api.securityLoginChallange(requestData, source).then(data => {
                return data;
            }).catch(err => {
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            });
    
            if (!data) return;
    
            const challenge = Uint8Array.from(atob(data.challenge), c => c.charCodeAt(0));
            
            const allowCredentials = data.security_ids.map(security_id => ({
                id: new Uint8Array(this.atobFix(security_id)),
                type: "public-key"
            }));
    
            const credential = await navigator.credentials.get({
                publicKey: {
                    challenge,
                    allowCredentials, 
                    userVerification: "preferred",
                    rpId: data.id
                }
            });
    
            requestData = {
                email: response.user.email,
                password: password,
                challenge: data.challenge,
                security_id: credential.id,
                security_data: JSON.stringify({
                    id: credential.id,
                    type: "public-key",
                    rawId: btoa(String.fromCharCode(...new Uint8Array(credential.rawId))),
                    response: { 
                        authenticatorData: btoa(String.fromCharCode(...new Uint8Array(credential.response.authenticatorData))),
                        clientDataJSON: btoa(String.fromCharCode(...new Uint8Array(credential.response.clientDataJSON))),
                        signature: btoa(String.fromCharCode(...new Uint8Array(credential.response.signature))),
                    }
                }),
            };                    
    
            Api.securityLogin(requestData, source).then(data => {
                this.handleLoginSuccess(data);
            }).catch(err => {
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            });
        } catch (error) {
            console.log("login error:", error);
        }
    };   
    atobFix(base64String) {
        base64String = base64String.replace(/-/g, '+').replace(/_/g, '/');
        while (base64String.length % 4) {
            base64String += '='; 
        }
        return Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
    }
    handle2faVerification(data){
        this.setState({
            twofaVerification: true,
            phoneVerification: false,
            backupCodeVerification: false,
            securityKeyVerification: false,
            loading: false,
            showError: false,
            cancelToken: null,
            errorMessage: ""
        })
    }
    handleBackupCodeVerification(data){
        this.setState({
            twofaVerification: false,
            phoneVerification: false,
            backupCodeVerification: true,
            securityKeyVerification: false,
            response: data,
            loading: false,
            showError: false,
            cancelToken: null,
            errorMessage: ""
        });
    }
    handleRequirePhoneVerification(data, sendCode = false){
        this.setState({
            twofaVerification: false,
            phoneVerification: true,
            backupCodeVerification: false,
            securityKeyVerification: false,
            confirmCell: !data.user.cell_verified,
            cell: data.user.cell,
            country_id: data.user.country_id,
            response: data,
            loading: false,
            showError: false,
            cancelToken: null,
            errorMessage: ""
        },() => {
            if(sendCode && data.user.cell_verified){
                this.resendCode();
            }
        });
    }
    handleSecurityKeyVerification(data){
        this.setState({
            twofaVerification: false,
            phoneVerification: false,
            backupCodeVerification: false,
            securityKeyVerification: true,
            response: data,
            loading: false,
            showError: false,
            cancelToken: null,
            errorMessage: ""
        },() => {
            this.loginSecurity();
        })
    }
    handleLoginSuccess(data){
        Cookie.write("oauth_token", data.oauth_token);
        Cookie.write("oauth_secret", data.oauth_secret);
        Cookie.write("expires_at", data.expires_at);
        localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
        let redirectUrl = helper.getHomePageUrl(data.user);
        if(data.user.level_id === 5){
            redirectUrl = "/user/shared";
        }
        const returnUrl = decodeURIComponent(helper.getParam("return"));
        if(returnUrl && returnUrl !== "null" && returnUrl.length > 0){
            redirectUrl = returnUrl;
        }
        if(data.user.account_type === -1){
            redirectUrl = "/user/plans";
        }
        this.history.push(redirectUrl);
        this.store.dispatch({
            type: "LOGIN",
            state: {
                authorized: true,
                user: data.user
            }
        });
    }
    handleForgotEmailSubmit(){
        const isValid = this.validateForgotEmailForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        const { emailPhone } = this.state;
        const requestData = {
            emailPhone: emailPhone,
        };
        this.setState({loading: true, cancelToken: source, showError: false, displayContactSupportLink: false});
        Api.forgotEmail(requestData, source).then(data => {
            this.setState({requestCode: true, validated: false, loading: false});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message,
                    displayContactSupportLink: true
                });
            }
        });
    }
    handleForgotEmailVerifyCode(){
        const { code, emailPhone } = this.state;
        
        if(code.length < 4){
            this.setState({
                validation: {
                    ...this.state.validation,
                    code: "error"
                }
            });
            return;
        }
        const requestData = {
            code: code,
            emailPhone: emailPhone,
        };
        const source = axios.CancelToken.source();
        Api.verifyForgotEmailCode(requestData, source).then((data) => {
            this.setState({
                users: data.users,
                forgotEmailUsers: true,
                forgotEmail: false, 
                requestCode: false, 
                validated: false, 
                loading: false,
                emailPhone: '',
                code: '',
            });
        }).catch(err => {
            this.setState({
                loading: false, 
                showError: true, 
                cancelToken: null,
                errorMessage: err.message
            });
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    handleSelectUser(user = null){
        let email = '';
        if(user !== null){
            email = user.email;
        }

        this.setState({
            forgotEmailUsers: false,
            email: email
        },() => {
            history.push("/auth/login");
        })
    }
    handleForgotSubmit(){
        const isValid = this.validateForgotForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        const { email } = this.state;
        const requestData = {
            email: email,
        };
        this.setState({loading: true, cancelToken: source, showError: false});
        Api.forgot(requestData, source).then(data => {
            this.setState({
                recoveryEmail: data.recoveryEmail,
                recoveryPhone: data.recoveryPhone,
                requestCode: true, 
                validated: false, 
                loading: false
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
    }
    handleResetPassword(){
        const isValid = this.validateResetForm(true,true);
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        const { email, code, password } = this.state;
        const requestData = {
            email: email,
            code: code,
            password: password
        };
        this.setState({loading: true, cancelToken: source, showError: false});
        Api.reset(requestData, source).then(data => {
            Cookie.write("oauth_token", data.oauth_token);
            Cookie.write("oauth_secret", data.oauth_secret);
            Cookie.write("expires_at", data.expires_at);
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
            this.history.push("/dashboard");
            this.store.dispatch({
                type: "LOGIN",
                state: {
                    authorized: true,
                    user: data.user
                }
            }); 
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
    }
    validateForm(){
        let validation = {
            email: 'success',
            password: 'success',
            isValid: true
        };
        var emailRegex = Config.getEmailRegex();
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        if(this.state.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateForgotEmailForm(){
        let validation = {
            emailPhone: 'success',
            isValid: true
        };
        const emailPhone = this.state.emailPhone.trim(); 
        const cellRegex = /^\+?[0-9]{9,15}$/;
        var emailRegex = Config.getEmailRegex();

        if (!cellRegex.test(emailPhone) && !emailRegex.test(emailPhone)) {
            validation.emailPhone = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    validateForgotForm(){
        let validation = {
            email: 'success',
            password: '',
            passwordConfirm: '',
            code: '',
            isValid: true
        };
        var emailRegex = Config.getEmailRegex()
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateResetForm(validatePassword = false, validatePasswordConfirm = false){
        let validation = {
            email: 'success',
            password: (validatePassword ? 'success': this.state.validation.password),
            passwordConfirm: (validatePasswordConfirm ? 'success': this.state.validation.passwordConfirm),
            code: '',
            isValid: true
        };
        if(this.state.code.length <= 3){
            validation.code = "error";
            validation.isValid = false;
        }

        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            if(validatePassword){
                validation.password = "error";
            }
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            if(validatePasswordConfirm){
                validation.passwordConfirm = "error";
            }
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    handleForgotPassword(){
        this.setState({forgotPassword: true});
        history.push("/auth/forgot");
    }
    handleForgotEmail(){
        this.setState({forgotEmail: true});
        history.push("/auth/forgot/email");
    }
    handleForgotPasswordMethods(){
        this.setState({
            forgotType: '',
            forgotPasswordMethods: true
        })
    }
    handleForgotPasswordMethod(type = 'email'){
        this.setState({
            forgotPasswordMethods: false,
            requestCode: true,
            forgotType: type
        },() => {
            this.resendCode(type);
        })
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    handleTwoFaSubmit(e){
        e.preventDefault();
        const { code, response, password, cell, confirmCell } = this.state;
        if(confirmCell && cell.length > 0){
            this.confirmCell();
            return;
        }
        if(code.length < 4){
            this.setState({
                validation: {
                    ...this.state.validation,
                    code: "error"
                }
            });
            return;
        }
        const requestData = {
            code: code,
            email: response.user.email,
            password: password
        };
        const source = axios.CancelToken.source();
        Api.verifyCode(requestData, source).then((data) => {
            this.handleLoginSuccess(data);
        }).catch(err => {
            this.setState({
                loading: false, 
                showError: true, 
                cancelToken: null,
                errorMessage: err.message
            });
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    confirmCell(){
        const { response, password, cell, country_id } = this.state;
        const requestData = {
            cell: cell,
            email: response.user.email,
            password: password,
            country_id: country_id
        };
        const source = axios.CancelToken.source();
        Api.confirmCell(requestData, source).then((data) => {
            this.setState({
                loading: false,
                confirmCell: false,
                showError: false, 
                cancelToken: null,
            });
        }).catch(err => {
            this.setState({
                loading: false, 
                showError: true, 
                cancelToken: null,
                errorMessage: err.message
            });
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    resendCode(type = ''){
        const { response } = this.state;
        if(response === null && type === ''){
            return;
        }
        const requestData = {};
        if(response !== null){
            requestData['email'] = response.user.email;
        }
        if(type !== ''){
            requestData['email'] = this.state.email;
            requestData['type'] = type;
        }

        const source = axios.CancelToken.source();

        if(type === 'recovery_email'){
            Api.resendCode(requestData, source).then((data) => {
                this.setState({
                    loading: false, 
                    showError: false, 
                    cancelToken: null,
                    errorMessage: null
                });
            }).catch(err => {
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            });
        }else{
            Api.resendSmsCode(requestData, source).then((data) => {
                this.setState({
                    loading: false, 
                    showError: false, 
                    cancelToken: null,
                    errorMessage: null
                });
            }).catch(err => {
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            });
        }
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    handleCellChange(e, name) {
        const { cell } = this.state;
        let state = {};
        const value = e.target.value;
        state[name] = value;
        const regex = /^\d+$/;
        if((value.length > 0 && !regex.test(value)) || value.length > 16){
            state[name] = cell;
        }
        if(state[name].length > 10){
            state['validation'] = {
                ...this.state.validation,
                cell: "success"
            };
        }else{
            state['validation'] = {
                ...this.state.validation,
                cell: "error"
            };
        }
        this.setState(state);
    }
    onCountryChange(e){
        const country = e.target.value;
        this.setState({
            country_id: country,
            validation: {
                ...this.state.validation,
                country_id: "success"
            }
        });
    }
    getPhoneNumber(phoneNumber = ''){
        if(phoneNumber === ''){
            return "";
        }

        let maskedPhone = '*'.repeat(phoneNumber.length - 2) + phoneNumber.slice(-2);

        return maskedPhone;
    }
    getEmail(email = ''){
        if(email === ''){
            return "";
        }

        const [name, domain] = email.split("@");
        const maskedName = name[0] + "•••••"; 
        const maskedDomain = domain[0] + "••••••" + domain.substring(domain.indexOf(".")); 
        
        return maskedName+"@"+maskedDomain;
    }
    renderUsers(){
        const { users } = this.state;

        let usersData = [];

        users.map((user, key) => {
            let userData = (<li key={key} onClick={() => this.handleSelectUser(user)}>
                <p>{user.title}</p>
                <p>{user.email}</p>
            </li>);

            usersData.push(userData);
            return null;
        });
        let userData = (<li key={users.length} onClick={() => this.handleSelectUser()}>
            <p>User another account</p>
        </li>);

        usersData.push(userData);


        return usersData;
    }
    onContactSupportModal(status = false){
        this.setState({
            contactSupportModal: status
        })
    }
    render2faVerification(){
        const { classes } = this.props;
        const { response } = this.state;
        return (
            <div className={classes.container}>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={12} md={5}>
                        <Card className={classes.loginCard}>
                            <h2 className={classes.cardTitle}>2-Step Verification</h2>
                            <CardBody signup>
                                <h5 className={classes.alignLeft}>Choose how you want to sign in:</h5>
                                <ul className='twoStepVerifcation'>
                                    {
                                        response.requirePhoneVerification ? 
                                            <li onClick={() => this.handleRequirePhoneVerification(response, true)}>
                                                <p>Get a verification code at {this.getPhoneNumber(response.user.cell)}</p>
                                                <p className='note'>2-step verification phone</p>
                                                <p className='note'>Standard rates may apply</p>
                                            </li>    
                                        :
                                            <></>
                                    }
                                    {
                                        response.requireBackupCodesVerification ?     
                                            <li onClick={() => this.handleBackupCodeVerification(response)}>
                                                <p>Enter one of your 8-digit backup codes</p>
                                            </li>   
                                        :
                                            <></>
                                    }
                                    {
                                        response.requireSecurityKeyVerification ? 
                                            <li onClick={() => this.handleSecurityKeyVerification(response)}>
                                                <p>Use your security key</p>
                                            </li>    
                                        :
                                            <></>
                                    }
                                </ul>                                
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
    renderPhoneVerification(){
        const { classes } = this.props;
        const { validation, loading, showError, cell, confirmCell, country_id, countries, response } = this.state;
        return (
            <div className={classes.container}>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={12} md={5}>
                        <Card className={classes.loginCard}>
                            <form className={classes.form} onSubmit={this.handleTwoFaSubmit} noValidate>
                                <h3 className={classes.cardTitle}>Phone Number Verification</h3>
                                <CardBody signup>
                                    {
                                        showError ?
                                            <GridContainer justifyContent="center">
                                                { this.renderErrorMessages() }
                                            </GridContainer>
                                        :
                                        <></>
                                    }
                                    {
                                        confirmCell === false ?
                                            <>
                                                <Warning>
                                                    <p>We have sent a code by sms. Enter it below to confirm your login.</p>
                                                </Warning>
                                                <CustomInput
                                                    success={validation.code === "success"}
                                                    error={validation.code === "error"}
                                                    id="input-code"
                                                    labelText="Verification Code"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'code'),
                                                        name: "code",
                                                        type: "text",
                                                        className: classes.passwordControl
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                                <div className='footer-links'>
                                                    <p className={classes.anchor} onClick={() => this.resendCode()}>Resend Code?</p>
                                                    {
                                                        response.requireBackupCodesVerification || response.requireSecurityKeyVerification ?
                                                            <p className={classes.anchor} onClick={() => this.handle2faVerification(response)}>Try another way</p>
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                            </>
                                        :
                                            <>
                                                <Warning>
                                                    <p>Please confirm your cell number before continuing.</p>
                                                </Warning>
                                                <CustomSelect
                                                    success={validation.country_id === "success"}
                                                    error={validation.country_id === "error"}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    labelText="Country"
                                                    selectProps={{
                                                        onChange: (e) => this.onCountryChange(e),
                                                        value: country_id
                                                    }}
                                                    inputProps={{
                                                        name: "country_id",
                                                        id: "input-countryId",
                                                        className: classes.alignLeft,
                                                    }}
                                                    items={countries}
                                                    itemLabel="name"
                                                    itemValue="id"
                                                />
                                                <CustomInput
                                                    success={validation.cell === "success"}
                                                    error={validation.cell === "error"}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    id="input-cell"
                                                    labelText="Cell"
                                                    inputProps={{
                                                        onChange: (e) => this.handleCellChange(e, 'cell'),
                                                        name: "cell",
                                                        value: cell,
                                                    }}
                                                />
                                                {
                                                    response.requireBackupCodesVerification || response.requireSecurityKeyVerification ?
                                                        <div className='footer-links'>
                                                            <p className={classes.anchor} onClick={() => this.handle2faVerification(response)}>Try another way</p>    
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                            </>
                                    }
                                    
                                </CardBody>
                                <div className={classes.textCenter}>
                                    {
                                        loading ?
                                            <LoaderComponent />
                                        :
                                            <Button round color="primary" type="submit">
                                                {
                                                    confirmCell ?
                                                        "Confirm"
                                                    :
                                                    "Verify"
                                                }
                                            </Button>
                                    }
                                </div>
                            </form>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
    renderBackupCodeVerification(){
        const { classes } = this.props;
        const { validation, loading, showError, response } = this.state;
        return (
            <div className={classes.container}>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={12} md={5}>
                        <Card className={classes.loginCard}>
                            <form className={classes.form} onSubmit={this.handleBackupCodeSubmit} noValidate>
                                <h3 className={classes.cardTitle}>Backup Code Verification</h3>
                                <p>Enter one of your 8-digit backup codes</p>
                                <CardBody signup>
                                    {
                                        showError ?
                                            <GridContainer justifyContent="center">
                                                { this.renderErrorMessages() }
                                            </GridContainer>
                                        :
                                            <></>
                                    }
                                    <CustomInput
                                        success={validation.backupCode === "success"}
                                        error={validation.backupCode === "error"}
                                        id="input-code"
                                        labelText="Backup Code"
                                        inputProps={{
                                            required: true,
                                            onChange: (e) => this.handleChange(e,'backupCode'),
                                            name: "backupCode",
                                            type: "text",
                                            className: classes.passwordControl
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                    <div className='footer-links'>
                                        {
                                            response.requirePhoneVerification || response.requireSecurityKeyVerification ?
                                                <p className={classes.anchor} onClick={() => this.handle2faVerification(response)}>Try another way</p>
                                            :
                                                <></>
                                        }
                                    </div>
                                </CardBody>
                                <div className={classes.textCenter}>
                                    {
                                        loading ?
                                            <LoaderComponent />
                                        :
                                            <Button round color="primary" type="submit">
                                                Submit
                                            </Button>
                                    }
                                </div>
                            </form>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
    renderSecurityKeyVerification(){
        const { classes } = this.props;
        const { showError, response } = this.state;
        return (
            <div className={classes.container}>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={12} md={5}>
                        <Card className={classes.loginCard}>
                            <h3 className={classes.cardTitle}>Security Key Verification</h3>
                            <CardBody signup>
                                {
                                    showError ?
                                        <GridContainer justifyContent="center">
                                            { this.renderErrorMessages() }
                                        </GridContainer>
                                    :
                                        <></>
                                }
                                <p>Verifying it's you...</p>
                                <p>Complete sign-in using your securtiy key</p>
                                {
                                    response.requirePhoneVerification || response.requireBackupCodesVerification ?
                                        <p className={classes.anchor+" "+classes.textCenter} onClick={() => this.handle2faVerification(response)}>Try another way</p>
                                    :
                                        <></>
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
    renderForgotEmailUsers(){
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={12} md={5}>
                        <Card className={classes.loginCard}>
                            <h3 className={classes.cardTitle}>Select an account to sign in</h3>
                            <CardBody signup>
                                <ul className='twoStepVerifcation'>
                                    { this.renderUsers() }
                                </ul>                                
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
    renderForgotPasswordMethods(){
        const { classes } = this.props;
        const { recoveryEmail, recoveryPhone, contactSupportModal } = this.state;

        return (
            <div className={classes.container}>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={12} md={5}>
                        <Card className={classes.loginCard}>
                            <h2 className={classes.cardTitle}>Account recovery</h2>
                            <CardBody signup>
                                <h5 className={classes.alignLeft}>Choose how you want to recover your account:</h5>
                                <ul className='twoStepVerifcation'>
                                    {
                                        recoveryPhone !== '' ? 
                                            <li onClick={() => this.handleForgotPasswordMethod('recovery_phone')}>
                                                <p>Get a verification code at {this.getPhoneNumber(recoveryPhone)}</p>
                                            </li> 
                                        :   
                                            <></>
                                    }
                                    {
                                        recoveryEmail !== '' ? 
                                            <li onClick={() => this.handleForgotPasswordMethod('recovery_email')}>
                                                <p>Get a verification code at {this.getEmail(recoveryEmail)}</p>
                                            </li>    
                                        :
                                            <></>
                                    }
                                    <li onClick={() => this.onContactSupportModal(true)}>
                                        <p>Contact Sendlinx Support</p>
                                    </li>
                                </ul>                                
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                {
                    contactSupportModal ?
                        <ContactSupportModal 
                            open={contactSupportModal} 
                            onClose={() => this.onContactSupportModal()} 
                            store={this.store}
                        />
                    :
                        <></>
                }
            </div>
        )
    }
    render() {
        const { classes } = this.props;
        const { validation, loading, showError, forgotPassword, forgotEmail, requestCode, email, password, passwordConfirm, 
            requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength, phoneVerification,
            securityKeyVerification, twofaVerification, backupCodeVerification, emailPhone, code, forgotEmailUsers,
            forgotPasswordMethods, recoveryPhone, recoveryEmail, forgotType, contactSupportModal, displayContactSupportLink } = this.state;
        const { authorized } = this.store.getState();
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");
        const showPasswordConfirmErrors = (validation.passwordConfirm === "success" || validation.passwordConfirm === "error");
        const requirePasswordConfirm = (passwordConfirm.length > 0 && password === passwordConfirm);
        if(authorized){
            return <Redirect to='/' />
        }
        if(twofaVerification){
            return this.render2faVerification();
        }
        if(backupCodeVerification){
            return this.renderBackupCodeVerification();
        }
        if(phoneVerification){
            return this.renderPhoneVerification();
        }
        if(securityKeyVerification){
            return this.renderSecurityKeyVerification();
        }
        if(forgotEmailUsers){
            return this.renderForgotEmailUsers();
        }
        if(forgotPasswordMethods){
            return this.renderForgotPasswordMethods();
        }

        return (
            <div>
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={12} md={5}>
                            <Card className={classes.loginCard}>
                                <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
                                    <h2 className={classes.cardTitle}>
                                        {
                                            forgotEmail ?
                                                "Forgot Email"
                                            : forgotPassword ?
                                                "Forgot Password"
                                            : 
                                                "Log in"
                                        }
                                    </h2>
                                    <CardBody signup>
                                        {
                                            showError ?
                                                <GridContainer justifyContent="center">
                                                    { this.renderErrorMessages() }
                                                </GridContainer>    
                                            :
                                                <></>
                                        }
                                        {
                                            forgotEmail ?
                                                <>
                                                    {
                                                        requestCode ? 
                                                            <>
                                                                <Warning>
                                                                    <p>We have sent a code to {this.state.emailPhone}. Enter it below to confirm your account.</p>
                                                                </Warning>
                                                                <CustomInput
                                                                    success={validation.code === "success"}
                                                                    error={validation.code === "error"}
                                                                    id="input-code"
                                                                    labelText="Verification Code"
                                                                    inputProps={{
                                                                        required: true,
                                                                        onChange: (e) => this.handleChange(e,'code'),
                                                                        name: "code",
                                                                        type: "text",
                                                                        value: code,
                                                                    }}                                    
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                />
                                                            </>
                                                        :
                                                            <>
                                                                <p>Enter your recovery phone number or recovery email</p>
                                                                <CustomInput
                                                                    id="emailPhone"
                                                                    success={validation.emailPhone === "success"}
                                                                    error={validation.emailPhone === "error"}
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    labelText="Phone Number or Email"
                                                                    inputProps={{
                                                                        type: "text",
                                                                        onChange: (e) => this.handleChange(e, 'emailPhone'),
                                                                        name: "emailPhone",
                                                                        value: emailPhone,
                                                                    }}
                                                                />
                                                            </>
                                                    }
                                                </>
                                            :
                                                <>
                                                    {
                                                        requestCode === false ?
                                                            <>
                                                                <CustomInput
                                                                    id="email"
                                                                    success={validation.email === "success"}
                                                                    error={validation.email === "error"}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                        className: forgotPassword === false ? classes.passwordControl : ''
                                                                    }}
                                                                    labelText="Email"
                                                                    inputProps={{
                                                                        type: "email",
                                                                        onChange: (e) => this.handleChange(e, 'email'),
                                                                        name: "email",
                                                                        value: email,
                                                                    }}
                                                                />
                                                                {
                                                                    forgotPassword === false ?
                                                                        <GridItem className={classes.forgotGridItem}>
                                                                            <p className={classes.anchor} onClick={this.handleForgotEmail}>Forgot Email?</p>
                                                                        </GridItem>
                                                                    :
                                                                        <></>
                                                                        
                                                                }
                                                            </>
                                                        : 
                                                            <>
                                                                <Warning>
                                                                    <p>We have sent a code to
                                                                    {
                                                                        forgotType === 'recovery_phone' && recoveryPhone !== '' ? 
                                                                            " "+this.getPhoneNumber(recoveryPhone)
                                                                        : forgotType === 'recovery_email' && recoveryEmail !== '' ? 
                                                                            " "+this.getEmail(recoveryEmail)
                                                                        :
                                                                            " "+this.state.email
                                                                    }
                                                                    . Enter it below to confirm your account.</p>
                                                                </Warning>
                                                                <CustomInput
                                                                    success={validation.code === "success"}
                                                                    error={validation.code === "error"}
                                                                    id="input-code"
                                                                    labelText="Verification Code"
                                                                    inputProps={{
                                                                        required: true,
                                                                        onChange: (e) => this.handleChange(e,'code'),
                                                                        name: "code",
                                                                        type: "text"
                                                                    }}                                    
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                />
                                                                <CustomInput
                                                                    success={validation.password === "success"}
                                                                    error={validation.password === "error"}
                                                                    id="input-password"
                                                                    labelText="New Password"
                                                                    passwordInput={true}
                                                                    inputProps={{
                                                                        required: true,
                                                                        onChange: (e) => this.handleChange(e,'password'),
                                                                        name: "password",
                                                                        type: "text",
                                                                        value: password,
                                                                        autoComplete: 'off',
                                                                    }}                                    
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                />
                                                                {
                                                                    showPasswordErrors ?
                                                                        <GridItem>
                                                                            <div>
                                                                                <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                                                                    <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                                                                </div>
                                                                                <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                                                                    <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                                                                    <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                                                                </div>
                                                                                <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                                                                    <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                                                                    <span className="checkPasswordText-symbols">Password must contain a number</span>
                                                                                </div>
                                                                                <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                                                    <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                                                    <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                                                </div>
                                                                                <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                                                    <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                                                    <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                                                </div>
                                                                            </div>
                                                                        </GridItem>
                                                                    :
                                                                    <></>
                                                                }
                                                                <CustomInput
                                                                    success={validation.passwordConfirm === "success"}
                                                                    error={validation.passwordConfirm === "error"}
                                                                    id="input-passwordConfirm"
                                                                    labelText="New Password Again"
                                                                    inputProps={{
                                                                        required: true,
                                                                        onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                                                        name: "passwordConfirm",
                                                                        type: "password",
                                                                        value: passwordConfirm
                                                                    }}                                    
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                />
                                                                {
                                                                    showPasswordConfirmErrors ?
                                                                        <GridItem>
                                                                            <div>
                                                                                <div className={(requirePasswordConfirm?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requirePasswordConfirm? '\u2713' : '\u2716' }</span>
                                                                                    <span className="checkPasswordText-lowerletter">Passwords do not match</span>
                                                                                </div>
                                                                            </div>
                                                                        </GridItem>
                                                                    :
                                                                    null
                                                                }
                                                            </>
                                                    }
                                                    {
                                                        forgotPassword === false ?
                                                            <>
                                                                <CustomInput
                                                                    id="password"
                                                                    success={validation.password === "success"}
                                                                    error={validation.password === "error"}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                        className: classes.passwordControl
                                                                    }}
                                                                    labelText={"Password"}
                                                                    inputProps={{
                                                                        type: "password",
                                                                        onChange: (e) => this.handleChange(e, 'password'),
                                                                        name: "password",
                                                                        value: password,
                                                                    }}
                                                                />
                                                                <GridItem className={classes.forgotGridItem}>
                                                                    <p className={classes.anchor} onClick={this.handleForgotPassword}>Forgot Password?</p>
                                                                    <p>
                                                                        No Account? <Link href="https://sendlinx.com/plans/">Create one!</Link>
                                                                    </p>
                                                                </GridItem>
                                                            </>
                                                        :
                                                            <></>
                                                    }
                                                </>
                                        }
                                    </CardBody>
                                    <div className={classes.textCenter}>
                                        {
                                            loading ?
                                                <LoaderComponent />
                                            : forgotEmail ?
                                                <>
                                                    <Button round color="primary" type="submit">Next</Button> 
                                                    {                                               
                                                        displayContactSupportLink ?
                                                            <p className={classes.socialLine}>Having trouble ? <span className={classes.anchor} onClick={() => this.onContactSupportModal(true)}>Contact Sendlinx Support</span></p>
                                                        :
                                                            <></>
                                                    }
                                                </>
                                            : forgotPassword === false ?
                                                <Button round color="primary" type="submit">Login</Button>
                                            : 
                                                requestCode === true ?
                                                    <>
                                                        <Button round color="primary" type="submit">Reset Password</Button>
                                                        <p className={classes.anchor} onClick={() => this.handleForgotPasswordMethods()}>Try another way</p>
                                                    </>
                                                :
                                                    <Button round color="primary" type="submit">Help me!</Button>
                                        }                                        
                                    </div>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                <BackgroundSlider store={this.store} />
                {
                    contactSupportModal ?
                        <ContactSupportModal 
                            open={contactSupportModal} 
                            onClose={() => this.onContactSupportModal()} 
                            store={this.store}
                        />
                    :
                        <></>
                }
            </div>
        )
    }
}

Login.defaultProps = {
    forgot: false,
    forgotEmail: false
}
Login.propTypes = {
    forgot: PropTypes.bool,
    forgotEmail: PropTypes.bool,
}
export default withStyles(loginPageStyle)(Login);