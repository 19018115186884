import React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from "../../../assets/js/utils/Element";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import Link from "@material-ui/core/Link";
import RefreshIcon from "@material-ui/icons/Refresh";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
const history = createBrowserHistory();

const UserFavourites = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        const backPath = this.getBackPath(path);

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            path: decodeURIComponent(path.trim()),
            backPath: backPath,
            deleteFileModal: false,
            uploadFileModal: false,
            favourites: [],
            shareFileModal: false,
            sharingFiles: false,
            downloadModal: false,
            downloadingFiles: false,
            downloadUrl: ""
        };

        this.loadingFolder = false;
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            this.loadFiles();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    getBackPath(path){
        if(typeof(path) !== "string" || path.trim().length <= 0){
            return "";
        }
        if(path.length <= 1 || path.trim() === "/"){
            return "";
        }
        if(path.substr(0, 1) !== "/"){
            path += "/";
        }
        path = decodeURIComponent(path.trim()); //Decode path
        path = path.replace(/^\/+|\/+$/g, ''); //Remove trailing slash
        const pathArray = path.split("/");
        pathArray.pop();
        let backPath = pathArray.join("/");
        if(backPath.length <= 0){
            backPath = "/";
        }
        return backPath;
    }
    loadFiles(viewMore = false, newPath = null, backPath = ""){
        const source = axios.CancelToken.source();
        let { page, path, favourites } = this.state;
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        let requestData = {
            page: page,
            limit: 200,
            favourites: 1
        };
        if(path === "/" && newPath !== null){
            path = "";
        }
        requestData['path'] = path;
        if(newPath !== null){
            path += "/"+newPath;
            requestData['path'] = path;
        }
        if(backPath !== ""){
            requestData['path'] = path = backPath;
        }
        backPath = this.getBackPath(path);
        history.push("/user/favourites?dir="+encodeURIComponent(path));
        Api.getFavouriteFiles(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            data.response.map(file => {
                if(file.favourite){
                    favourites.push(file.id);
                }
                return null;
            });
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                favourites: favourites
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            path: path,
            backPath: backPath,
            checked: viewMore ? this.state.checked : [],
            uploadFileModal: false
        });
        this.loadingFolder = true;
    }
    handleToggle(file) {
        if(this.loadingFolder){
            return;
        }
        // if(file.default){
        //     return;
        // }
        const { checked } = this.state;
        const currentIndex = checked.indexOf(file.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(file.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    getColumns(){
        let columns = [
            {
                Header: "Name",
                accessor: "orignalName",
                headerClassName: "hd_name hd_show_head",
                className: "hd_name td_name td_hide",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_name hd_hide",
                className: "hd_name td_name",
            },
            {
                Header: "Size",
                accessor: "orignalSize",
                headerClassName: "hd_size hd_show_head",
                className: "hd_size td_size td_hide",
                resizable: false
            },
            {
                Header: "Size",
                accessor: "size",
                headerClassName: "hd_size hd_hide",
                className: "hd_size td_size",
            },
            {
                Header: "Uploaded",
                accessor: "date",
                headerClassName: "hd_creation_date",
                className: "hd_creation_date td_creation_date",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Creator",
                accessor: "owner",
                headerClassName: "hd_owner",
                className: "hd_owner td_owner",
            },
            
        ];

        return columns;
    }
    getTableData(){
        const { classes } = this.props;
        const { response, backPath } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        if(backPath.length > 0 && false){
            let fileArray = {
                id: 0,
                check: "",
                orignalName: "",
                name: (
                    <Link href="/user/home" onClick={(e) => {e.preventDefault(); this.loadFiles(false, null, backPath);}}>
                        <Button color="transparent" justIcon className={classes.fileIcon}>
                            { helper.getFolderIcon() }
                        </Button>
                        {"..."}
                    </Link>
                ),
                orignalSize: "",
                size: "",
                date: "",
                owner: "",
            };
            tableData.push(fileArray);
        }
        response.map(file => {
            let fileArray = {
                id: file.id,
                default: file.default,
                orignalName: file.name,
                name: this.getFileName(file),
                orignalSize: file.orignalSize,
                size: file.size,
                date: file.date,
                owner: file.owner,
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    getFileHref(file){
        let href = "/user/home";
        href += "?dir="+encodeURIComponent(file.path);
        return href;
    }
    getFileName(file){
        const { classes } = this.props;
        const { favourites } = this.state;
        return (
            <div className="react-contextmenu-wrapper">
                <div className='file-name'>
                    <div>
                        {
                            file.type === "folder" ?
                                <RouterLink to={this.getFileHref(file)}>
                                    <Button justIcon color="transparent" className={classes.fileIcon}>
                                        {
                                            file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0 ?
                                                helper.getFolderIcon(true)                               
                                            :
                                                helper.getFolderIcon()                                                                
                                        }
                                    </Button>
                                    {file.name}
                                </RouterLink>
                            :
                                <RouterLink to={this.getFileHref(file)}>
                                    { 
                                        helper.getFileIcon(file, classes, true)
                                    }
                                    {file.name}
                                </RouterLink>
                        }
                    </div>
                    <Button justIcon color="transparent" className={classes.fileIcon+" star-icon"} onClick={() => this.favourite(file)}>
                        {
                            favourites.includes(file.id) ?
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.31605 0.511466C8.1655 0.198904 7.84735 0 7.49796 0C7.14857 0 6.83326 0.198904 6.67987 0.511466L4.85336 4.27074L0.774248 4.87314C0.433375 4.92428 0.149315 5.16297 0.0442125 5.48974C-0.0608899 5.81651 0.0243281 6.17737 0.26862 6.4189L3.22853 9.34846L2.52974 13.4885C2.47293 13.8295 2.61496 14.1761 2.89618 14.3779C3.1774 14.5796 3.54952 14.6052 3.8563 14.4432L7.5008 12.4968L11.1453 14.4432C11.4521 14.6052 11.8242 14.5825 12.1054 14.3779C12.3866 14.1733 12.5287 13.8295 12.4719 13.4885L11.7702 9.34846L14.7301 6.4189C14.9744 6.17737 15.0625 5.81651 14.9545 5.48974C14.8466 5.16297 14.5654 4.92428 14.2245 4.87314L10.1426 4.27074L8.31605 0.511466Z"/>
                                </svg>                        
                            :
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.75357 4.79753L7.49833 1.20222L9.24309 4.79753C9.37381 5.06753 9.62955 5.25511 9.92793 5.30059L13.8323 5.87754L10.9964 8.68557C10.7861 8.89305 10.6895 9.19147 10.7406 9.48421L11.4084 13.449L7.92742 11.5874C7.66031 11.4453 7.3392 11.4453 7.06925 11.5874L3.58541 13.449L4.25319 9.48421C4.3015 9.19147 4.20773 8.89589 3.99745 8.68557L1.16434 5.87754L5.06874 5.30059C5.36711 5.25796 5.62286 5.06753 5.75357 4.79753ZM11.3914 14.4693C11.6215 14.5915 11.9 14.5745 12.1103 14.421C12.3206 14.2675 12.4286 14.0089 12.3859 13.7531L11.6386 9.33358L14.7985 6.20154C14.9832 6.01965 15.0457 5.74964 14.9661 5.50522C14.8866 5.2608 14.6734 5.08174 14.4177 5.04195L10.0615 4.39963L8.11213 0.383689C7.99846 0.147791 7.75976 0 7.49833 0C7.2369 0 6.99821 0.147791 6.88454 0.383689L4.93518 4.39963L0.58181 5.04479C0.326063 5.08174 0.112941 5.2608 0.0333752 5.50806C-0.0461904 5.75533 0.0191671 6.02249 0.201031 6.20439L3.35808 9.33358L2.61357 13.7531C2.57095 14.0089 2.67609 14.2675 2.88921 14.421C3.10233 14.5745 3.38081 14.5915 3.60814 14.4693L7.50118 12.3889L11.3942 14.4693H11.3914Z"/>
                                </svg>
                        }
                    </Button>
                </div>
            </div>
        )
    }
    loadFolder(e, parent){
        e.preventDefault();
        this.loadFiles(false, parent.name);
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
                // style: {
                //     background: checked.includes(rowInfo.original.id) ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
                // }
            }
        }else{
            return {}
        }
    }
    favourite(file){
        const { favourites } = this.state;
        const currentIndex = favourites.indexOf(file.id);
        const newFavourites = [...favourites];
    
        if (currentIndex === -1) {
            newFavourites.push(file.id);
        } else {
            newFavourites.splice(currentIndex, 1);
        }

        const source = axios.CancelToken.source();
        const requestData = {
            file_id: file.id
        }
        Api.favouriteFile(requestData, source).then(data => {
            
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            favourites: newFavourites
        });
    }
    getTrGroupProps(state, rowInfo){
        const { backPath, checked } = this.state;
        if(backPath.length <= 0){
            return {};
        }
        if (rowInfo && rowInfo.row) {
            if(checked.indexOf(rowInfo.original.id) !== -1){
                return {
                    className: 'selected_row'
                }
            }
            return {
                className: (rowInfo.original.id === 0 ? 'frozen_row' : '')
            }
        }else{
            return {}
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getBreadCrumbs(){
        let { path } = this.state;
        let breadcrumbs = [{
            value: "/",
            label: "Home"
        }];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        let newPath = "";
        pathArray.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    render() {
        const { classes } = this.props;
        const { limit, loading, loadingMore, page, totalPages, backPath } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        // const breadcrumbs = this.getBreadCrumbs();
        const tableData = this.getTableData();
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content+" "+classes.flexPageContent}>
                        <GridItem>
                            <div className={classes.controls}>
                                <h4>Quick Access</h4>
                            </div>
                        </GridItem>
                        <GridItem className={classes.tableGridItem}>
                            <div className={classes.filesContainer}>
                                <div className={classes.files}>
                                    {
                                        loading ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                            <ReactTable
                                                columns={this.getColumns()}
                                                data={tableData}
                                                pageSizeOptions={[500]}
                                                defaultPageSize={limit}
                                                showPaginationTop={false}
                                                minRows={0}
                                                showPaginationBottom={false}
                                                className={"-striped -highlight "+ (loading ? classes.loading : "")+" "+classes.filesTable+" "+(backPath.length > 0 ? classes.stickyRow : "")}
                                                getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                                getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                                                getNoDataProps={() => this.getNoDataProps()}
                                            />
                                    }
                                    
                                </div>
                                <div className={classes.loadMore}>
                                    {
                                        tableData.length > 0 && loadingMore === false && page < totalPages ?
                                            <Button color="custom" id="contentViewMore" onClick={(e) => this.loadFiles(true)}>
                                                <RefreshIcon className={classes.icons} />View More
                                            </Button>
                                            
                                        :
                                            loadingMore === true ?
                                                <LoaderComponent color="custom" align="center" />
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserFavourites.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(UserFavourites);
