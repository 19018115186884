import React from 'react';
import { Helmet } from 'react-helmet';
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import LoaderComponent from '../../components/Loader'
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const Password = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        this.state = {
            values: {
                ...values,
                oldPassword: '',
                password: ''
            },
            passwordConfirm: '',
            editInfo: false,
            savingInfo: false,
            showError: false,
            errorMessage: "",
            showPasswordErrors: false,
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            validation: {
                oldPassword: '',
                password: '',
                passwordConfirm: '',
                isValid: false,
                isValidate: false,
            },
            activeTab: 2,
            viewOldPassword: false,
            viewPassword: false,
            viewConfirmPassword: false,
        }

        this.editUserInfo = this.editUserInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.saveUserInfo = this.saveUserInfo.bind(this);
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }        
    }
    editUserInfo(editInfo = true){
        let state = {
            editInfo: editInfo
        }
        if(editInfo === false){
            state['values'] = {
                ...this.state.values,
                password: ''
            }
            state['passwordConfirm'] = '';
        }
        this.setState(state);
    }
    saveUserInfo(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({
            editInfo: false, 
            savingInfo: true,
            showError: false
        });
        this.updatePassword();
    }
    validateForm(){
        const { values } = this.state;
        let validation = {
            oldPassword: "success",
            password: "success",
            passwordConfirm: "success",
            isValid: true
        };
        
        const that = this;
        const check = PasswordMatch.check(values.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
                showPasswordErrors: true,
                requireLowerletter: requireLowerletter,
                requireUpperletter: requireUpperletter,
                requireNumber: requireNumber,
                requireSymbol: requireSymbol,
                requireLength: requireLength
            });
        });
        if(values.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || values.password !== this.state.passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }
        if(values.oldPassword.length <= 0){
            validation.oldPassword = "error";
            validation.isValid = false;
        }

        validation.isValidate = true;

        this.setState({validation: validation});
        return validation.isValid;
    }
    updatePassword(){
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.values);

        const source = axios.CancelToken.source();
        Api.updatePassword(user.id, values, source).then((data) => {
            this.setState({
                savingInfo: false, 
            });
            let newUser = Api.prepareMemberObject(data.user);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            console.log(err);
            this.setState({
                savingInfo: false, 
                showError: true, 
                errorMessage: err.message
            });
        });
    }
    handleValueChange(event, name) {
        this.setState({
            values: {
                ...this.state.values, 
                [name]: event.target.value
            }
        });
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    saveButtonDisabled(){
        if(this.state.values.password !== '' && this.state.passwordConfirm !== ''){
            return false;
        }

        return true;
    }
    onTabChange(urlTab){
        if(urlTab === '0'){
            this.setState({activeTab:0})
            this.history.push("/user/security");
        }else if(urlTab === '1'){
            this.setState({activeTab:1})
            this.history.push("/user/accounts/license");
        }else if(urlTab === '2'){
            this.setState({activeTab:2})
            this.history.push("/user/password");
        }else if(urlTab === '3'){
            this.setState({activeTab:3})
            this.history.push("/user/account/recovery");
        }
    }
    onViewPassword(name = 'viewPassword'){
        console.log(!this.state[name]);
        this.setState({
            [name]: !this.state[name]
        });
    }
    render() {
        const { classes } = this.props;
        const { showError, editInfo, savingInfo, validation, values, passwordConfirm, showPasswordErrors, requireLength, 
            requireLowerletter, requireNumber, requireSymbol, requireUpperletter, activeTab, viewPassword, viewOldPassword, 
            viewConfirmPassword } = this.state;
        const { authorized } = this.store.getState();

        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.profileContainer+" "+(validation.isValidate ? ' validate' : '')}>
                    <div className={classes.content}>
                    <div className={classes.controls}>
                            <GridItem className={classes.controlsTabs}>
                                <div className='sd-custom-tabs'>
                                    <div className={'sd-custom-tab '+(activeTab === 0 ? 'active': '')} onClick={() => this.onTabChange("0")}>
                                        <span>Two Factor Authentication</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 2 ? 'active': '')} onClick={() => this.onTabChange("2")}>
                                        <span>Password</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 1 ? 'active': '')} onClick={() => this.onTabChange("1")}>
                                        <span>Connected Account(s)</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 3 ? 'active': '')} onClick={() => this.onTabChange("3")}>
                                        <span>Account Recovery</span>
                                    </div>
                                </div>
                            </GridItem>
                        </div>
                        <GridItem className={classes.main} xs={12} sm={12} md={8} lg={6}>
                            <div className={"userInfo "+(editInfo === false?classes.viewInfo:'')}>
                                {
                                    showError ?
                                        this.renderErrorMessages()
                                    :
                                    <></>
                                }
                                <CustomInput
                                    success={validation.oldPassword === "success"}
                                    error={validation.oldPassword === "error"}
                                    id="outlined-password"
                                    labelText="Old Password"    
                                    passwordInput={viewOldPassword ? false : true} 
                                    inputProps={{
                                        value: values.oldPassword,
                                        onChange: (e) => this.handleValueChange(e, 'oldPassword'),
                                        name: "oldPassword",
                                        type: "text",
                                        disabled: !editInfo,
                                        autoComplete: 'off',
                                        endAdornment: (
                                            <InputAdornment 
                                                position="end" 
                                                className={classes.inputAdornment+' viewPasswordIcon'} 
                                                onClick={() => this.onViewPassword('viewOldPassword') }
                                            >
                                                {
                                                    values.oldPassword ?
                                                        viewOldPassword ?
                                                            <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                        :
                                                            <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                    :
                                                        <></>
                                                }
                                            </InputAdornment>
                                        ),
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                />
                                <CustomInput
                                    success={validation.password === "success"}
                                    error={validation.password === "error"}
                                    id="outlined-password"
                                    labelText="Password"    
                                    passwordInput={viewPassword ? false : true}                     
                                    inputProps={{
                                        value: values.password,
                                        onChange: (e) => this.handleValueChange(e, 'password'),
                                        name: "password",
                                        type: "text",
                                        disabled: !editInfo,
                                        autoComplete: 'off',
                                        endAdornment: (
                                            <InputAdornment 
                                                position="end" 
                                                className={classes.inputAdornment+' viewPasswordIcon'} 
                                                onClick={() => this.onViewPassword('viewPassword') }
                                            >
                                                {
                                                    values.password ?
                                                        viewPassword ?
                                                            <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                        :
                                                            <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                    :
                                                        <></>
                                                }
                                            </InputAdornment>
                                        ),
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                />
                                {
                                    showPasswordErrors ?
                                        <GridItem>
                                            <div>
                                                <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                                    <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                                </div>
                                                <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                                    <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                                </div>
                                                <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                                    <span className="checkPasswordText-symbols">Password must contain a number</span>
                                                </div>
                                                <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                    <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                </div>
                                                <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                    <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                </div>
                                            </div>
                                        </GridItem>
                                    :
                                    <></>
                                }
                                <CustomInput
                                    success={validation.passwordConfirm === "success"}
                                    error={validation.passwordConfirm === "error"}
                                    id="outlined-passwordConfirm"
                                    labelText="Confirm Password" 
                                    passwordInput={viewConfirmPassword ? false : true}
                                    inputProps={{
                                        value: passwordConfirm,
                                        onChange: (e) => this.handleChange(e, 'passwordConfirm'),
                                        name: "passwordConfirm",
                                        type: "text",
                                        disabled: !editInfo,
                                        autoComplete: 'off',
                                        endAdornment: (
                                            <InputAdornment 
                                                position="end" 
                                                className={classes.inputAdornment+' viewPasswordIcon'} 
                                                onClick={() => this.onViewPassword('viewConfirmPassword') }
                                            >
                                                {
                                                    passwordConfirm ?
                                                        viewConfirmPassword ?
                                                            <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                        :
                                                            <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                    :
                                                        <></>
                                                }
                                            </InputAdornment>
                                        ),
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                />
                            </div>
                            <div className='footer-buttons'>
                                {
                                    editInfo === false && savingInfo === false ?
                                        <div className={"editButton"}>
                                            <Button color="custom" onClick={() => this.editUserInfo()}>Edit</Button>
                                        </div>
                                    :
                                    savingInfo ? <LoaderComponent color="custom" align="left" /> : <></>
                                }                    
                                {
                                    editInfo === true ?
                                        <div className={"saveButtons"}>
                                            <Button color="white" onClick={() => this.editUserInfo(false)}>
                                                Cancel
                                            </Button>
                                            <Button color="custom" disabled={this.saveButtonDisabled()}  onClick={() => this.saveUserInfo()}>
                                                Save
                                            </Button>
                                        </div>
                                    :
                                        <></>
                                }
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Password.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Password);
