import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Api from "../../../assets/js/utils/Api";
import { helper } from "../../../assets/js/utils/Element";
import axios from "axios";
import Cookie from "../../../assets/js/utils/Cookie";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DateTimePicker from "../CustomInput/DateTimePicker";
import RichTextEditor from 'react-rte';
import { RichUtils } from 'draft-js';
import LoaderComponent from "../Loader";
import CustomInput from "../CustomInput/CustomInput";
import shareLinkModalStyle from "../../../assets/jss/user/shareLinkModalStyle.jsx";
import MaterialIcon from "@mdi/react";
import {  mdiAccountGroup, mdiFolder } from '@mdi/js';
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../Grid/GridItem";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const ShareLinkModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        let yourEmail = Cookie.read("sendlinx_your_email");
        if(!yourEmail || yourEmail === "null"){
            yourEmail = "";
        }
        const { authorized, user } = this.store.getState();
        if(authorized){
            yourEmail = user.email;
        }
        
        this.state = {
            title: "",
            linkPassword: this.props.linkPassword,
            downloadLink: this.props.downloadLink,
            transferType: "email",
            emailTo: "",
            yourEmail: yourEmail,
            message: RichTextEditor.createEmptyValue(),
            recipients: [],
            validation: {
                emailTo: "",
            },
            expire: this.props.expire,
            saving: false,
            showError: false,
            errorMessage: null,
            linkHash: this.props.linkHash,
            viewPassword: false,
            response: null
        };
    }
    componentDidMount(){
        this.loadPreview();
    }
    loadPreview(){
        const { linkHash } = this.state;
        if(!linkHash || linkHash.length <= 0){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            saving: true
        });
        
        Api.getTransfer(linkHash, 0, source, true).then(data => {
            that.setState({
                response: data,
                saving: false,
            });
        }).catch(err => {
            if(err.message !== "Request Cancelled"){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    handleChange(e, name) {
        this.setState({
            [name]: e.target.value
        });
    }
    onEmailKeyUp(event) {
        if(event && event.nativeEvent.keyCode !== 13 && event.key !== "Tab"){
            return;
        }

        const { emailTo } = this.state;
        if(emailTo.length <= 0){
            return;
        }
        if(event && event.key === "Tab") {
            event.preventDefault();
        }
        
        let commaSeparatedEmails = emailTo.split(/[;,]\s*/);
        let recipients = this.state.recipients;
        // let duplicate = false;

        // recipients.map(email => {
        //     var duplicateValue = commaSeparatedEmails.filter(value => recipients.includes(value));
            
        //     if(email === emailTo || duplicateValue.length > 0){
        //         duplicate = true;
        //     }
        //     return null;
        // });
        // if(duplicate){
        //     this.setState({
        //         recipients: recipients,
        //         emailTo: ""
        //     });
        //     return;
        // }
        if(commaSeparatedEmails.length > 1){
            commaSeparatedEmails.map((email) => {
                if(!recipients.includes(email.trim()) && emailRegex.test(email.trim())){
                    recipients.push(email.trim());
                }
                return null;
            });
        }else{
            if(!recipients.includes(emailTo.trim()) && emailRegex.test(emailTo.trim())){
                recipients.push(emailTo.trim());
            }
        }

        let invalidEmails = commaSeparatedEmails.filter(email => !emailRegex.test(email.trim()));

        this.setState({
            recipients: recipients,
            emailTo: invalidEmails.length > 0 ? invalidEmails.join(", ") : "",
        });
    } 
    handleMessageChange(content, name){
        this.setState({
            [name]: content
        });
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    removeRecipient(e, email){
        e.preventDefault();
        let recipients = this.state.recipients;
        if(recipients.length <= 0 || email.length <= 0){
            return;
        }
        const index = recipients.indexOf(email);
        recipients.splice(index, 1);
        this.setState({recipients: recipients});
    }
    editRecipient(e, email){
        e.preventDefault();
        this.onEmailKeyUp();
        let recipients = this.state.recipients;
        if(recipients.length <= 0 || email.length <= 0){
            return;
        }
        const index = recipients.indexOf(email);
        recipients.splice(index, 1);
        this.setState({
            recipients: recipients,
            emailTo: email
        });
    }
    getConfig(){
        const toolbarConfig = {
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', /*'BLOCK_TYPE_DROPDOWN',*/ 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
              {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
              {label: 'Italic', style: 'ITALIC'},
              {label: 'Underline', style: 'UNDERLINE'}
            ],
            // BLOCK_TYPE_DROPDOWN: [
            //   {label: 'Normal', style: 'unstyled'},
            //   {label: 'Heading Large', style: 'header-one'},
            //   {label: 'Heading Medium', style: 'header-two'},
            //   {label: 'Heading Small', style: 'header-three'}
            // ],
            BLOCK_TYPE_BUTTONS: [
              {label: 'UL', style: 'unordered-list-item'},
              {label: 'OL', style: 'ordered-list-item'}
            ]
        };
        return toolbarConfig;
    }
    getCustomControls(){
        const { classes } = this.props;
        const { message } = this.state;
        let controls = [];
        controls.push(
            <div className="ButtonGroup__root___3lEAn" key={1}>
                <span className="Dropdown__root___3ALmx">
                    <label className={classes.hiddenLabel} htmlFor="heading_type1">Heading</label>
                    <select id="heading_type1" onChange={(e) => {
                        const newValue = message.setEditorState(RichUtils.toggleBlockType(message.getEditorState(), e.target.value));
                        this.handleMessageChange(newValue, "message");
                    }}>
                        <option value="unstyled">Normal</option>
                        <option value="header-one">Heading Large</option>
                        <option value="header-two">Heading Medium</option>
                        <option value="header-three">Heading Small</option>
                    </select>
                    <span className="Dropdown__value___34Py9"> </span>
                </span>
            </div>
        );
        return controls;
    }
    shareLink(){
        const { linkPassword, linkHash, expire, emailTo } = this.state;
        const source = axios.CancelToken.source();
        let message = this.state.message.toString("html");
        const requestData = {
            linkPassword: linkPassword,
            hash: linkHash,
            message: message
        };
        if(expire !== null){
            requestData['expire'] = expire;
        }

        let recipients = this.state.recipients;
        let commaSeparatedEmails = emailTo.split(/[;,]\s*/);
        if(emailTo.length > 0){
            if(commaSeparatedEmails.length > 1){
                commaSeparatedEmails.map((email) => {
                    if(!recipients.includes(email.trim()) && emailRegex.test(email.trim())){
                        recipients.push(email.trim());
                    }
                    return null;
                });
            }else{
                if(!recipients.includes(emailTo.trim()) && emailRegex.test(emailTo.trim())){
                    recipients.push(emailTo.trim());
                }
            }
        }
        
        if(recipients.length <= 0){
            return;
        }
        requestData['recipients'] = recipients;
        
        Api.shareLink(requestData, source).then(data => {
            this.props.onSuccess();
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    cancelToken: null,
                    showError: true,
                    errorMessage: err.message,
                    saving: false
                });
            }
        });
        this.setState({
            cancelToken: source,
            saving: true,
            showError: false,
            errorMessage: null
        });
    }
    onViewPassword(){
        this.setState({
            viewPassword: !this.state.viewPassword
        })
    }
    renderFile(item, key){
        const { classes } = this.props;
        if(item.type === "file"){
            return <li key={key}>
                <div className="file">
                    <div className="file-header">
                        <div className={classes.poptip}>
                            <h6 className="file-title">{item.file.name}</h6>
                        </div>
                        <div className="file-details">
                            <span className="file-detail">{item.file.size}</span>
                            <span className="file-detail1">{item.file.extension}</span>
                        </div>
                    </div>
                </div>
            </li>;
        }
        return <li key={key}>
            <div className="file">
                <div className="file-header">
                    <div className={classes.poptip}>
                        <h6 className="file-title">{item.name}</h6>
                    </div>
                    <div className="file-details">
                        <span className="file-detail">
                            <MaterialIcon path={mdiFolder} className="MuiSvgIcon-root"/>
                            <span className="folder-detail">Folder</span>
                        </span>
                        <span className="filedetail1">{item.files.length} items</span>
                    </div>
                </div>
            </div>
        </li>;
    }
    renderFiles(){
        const { response } = this.state;
        if(response === null){
            return null;
        }
        const { classes } = this.props;
        if(response.files.length > 10){
            return <li>
                <div className="file">
                    <div className="file-header">
                        <div className={classes.poptip}>
                            <h6 className="file-title">{response.files.length} files</h6>
                        </div>
                        <div className="file-details">
                            <span className="file-detail1">{helper.getFormatedSize(response.totalSize)}</span>
                        </div>
                    </div>
                </div>
            </li>
        }
        let renderFiles = [];
        for(var key in response.files){
            const item = response.files[key];
            renderFiles.push(this.renderFile(item, key));
        }
        return renderFiles;
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    render() {
        const { authorized } = this.store.getState();
        const { classes } = this.props;
        const { linkPassword, transferType, recipients, validation, emailTo, yourEmail, message, expire, saving, 
            showError, viewPassword, response } = this.state;
        const emailError = ((validation.emailTo === "error") || (emailTo.length > 0 && !emailRegex.test(emailTo)));
        const emailSuccess = ((validation.emailTo === "success") || (emailTo.length > 0 && emailRegex.test(emailTo)));

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="shareLinkModal-modal-slide-title"
                aria-describedby="shareLinkModal-modal-slide-description"
            >
                <DialogTitle
                    id="renameFileModal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    {
                        saving === false ?
                            <Button
                                className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                                onClick={() => this.props.onClose()}
                                simple
                            >
                                <CloseIcon className={classes.modalClose} />
                            </Button>
                        :
                            <></>
                    }
                    <h4 className={classes.modalTitle}>Share Link</h4>
                </DialogTitle>
                <DialogContent
                    id="shareLinkModal-modal-slide-description"
                    className={classes.modalBody}
                >
                        {
                            showError ?
                                <div className='error-messages'>
                                    { this.renderErrorMessages() }
                                </div>
                            :
                                <></>
                        }
                        {
                            saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                                <div className={classes.landingPageContainer}>
                                    <div className={classes.transferForm+" transferForm "+classes.fileSendingForm}>
                                        <div className={classes.transferContent}>
                                            <div className={classes.homepageFormFields+" uploader-fields"}>
                                                {
                                                    transferType === "email" ?
                                                        <>
                                                            {
                                                                recipients.length > 0 ?
                                                                    <div className={"react-tagsinput "+classes.tagsInput}>
                                                                        <div className="users-icon">
                                                                            <MaterialIcon path={mdiAccountGroup} />
                                                                        </div>
                                                                        <div className="users-lists">
                                                                            {
                                                                                recipients.map((email, key) => {
                                                                                    return (
                                                                                        <span className="react-tagsinput-tag rose" key={key}>
                                                                                            <span onClick={(e) => this.editRecipient(e, email)}>{email}</span>
                                                                                            <a href="/" onClick={(e) => this.removeRecipient(e, email)}>&nbsp;</a>
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                :
                                                                <></>
                                                            }
                                                            <div>
                                                                <div className="formElement">
                                                                    <CustomInput
                                                                        id="email"
                                                                        success={emailSuccess}
                                                                        error={emailError}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        labelText="To: user@example.com"
                                                                        inputProps={{
                                                                            type: "email",
                                                                            onChange: (e) => this.handleChange(e, 'emailTo'),
                                                                            name: "emailTo",
                                                                            value: emailTo,
                                                                            onKeyUp: (e) => this.onEmailKeyUp(e),
                                                                            onBlur: () => this.onEmailKeyUp(null),
                                                                            onKeyDown: (e) => this.onEmailKeyUp(e),
                                                                        }}
                                                                    />
                                                                    <p className="recipients-description">Press TAB or ENTER after each Email</p>
                                                                </div>
                                                            </div>           
                                                        </>
                                                    :
                                                    <></>
                                                }
                                                <div>
                                                    <div className={"formElement "+(yourEmail.length > 0 ? classes.fromEmail : "")}>
                                                        <CustomInput
                                                            id="outlined-yourEmail"
                                                            success={validation.yourEmail === "success"}
                                                            error={validation.yourEmail === "error"}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            labelText="From: user@example.com"
                                                            inputProps={{
                                                                type: "email",
                                                                onChange: (e) => this.handleChange(e, 'yourEmail'),
                                                                name: "yourEmail",
                                                                value: yourEmail,
                                                                disabled: (authorized ? true : false)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="formElemens-inline">
                                                    <div className="formElement">
                                                        <CustomInput
                                                            labelText="Link Password (optional)"
                                                            id="input-password"
                                                            passwordInput={viewPassword ? false : true}
                                                            inputProps={{
                                                                value: linkPassword,
                                                                name: "linkPassword",
                                                                type: "text",
                                                                onChange: (e) => this.handleChange(e, 'linkPassword'),
                                                                autoComplete: 'off',
                                                                endAdornment: (
                                                                    <InputAdornment 
                                                                        className={classes.inputAdornment+' viewPasswordIcon'} 
                                                                        onClick={() => this.onViewPassword() }
                                                                        position='end'
                                                                    >
                                                                        {
                                                                            linkPassword ?
                                                                                viewPassword ?
                                                                                    <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                                                :
                                                                                    <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                                            :
                                                                                <></>
                                                                        }
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="formElement dateElement">
                                                        <DateTimePicker
                                                            labelText="Expiry Date (optional)"
                                                            id="input-expire"
                                                            value={expire}
                                                            onChange={(date) => this.handleDateChange(date, 'expire')}
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            disablePast={true}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    transferType === "email" ?
                                                        <div>
                                                            <div className="formElement">
                                                                <div className={classes.editor}>
                                                                    <RichTextEditor 
                                                                        toolbarConfig={this.getConfig()} 
                                                                        customControls={this.getCustomControls()}
                                                                        onChange={(content) => this.handleMessageChange(content, "message")}
                                                                        value={message}
                                                                        toolbarClassName="sd-editor-toolbar"
                                                                    />
                                                                    <div className={classes.hidden} id="placeholder-3b60g"></div>
                                                                    <div className={classes.hidden} id="placeholder-crl20"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                        <div className={classes.transferFooter+" "+classes.homeFileSendingForm+" "+classes.fileSendingFormFooter}>
                                            <Button type="button" color="custom" onClick={() => this.shareLink()} disabled={(recipients.length <= 0 && emailError)}>
                                                <svg className="sendicon disabled" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 3760 3500">
                                                    <path d="M970 3489c-52-16-86-45-108-92-19-39-178-874-169-883 8-8 888 629 884 640-3 12-423 297-472 320-43 21-95 26-135 15zm1322-152c-40-15-1104-787-1100-798 2-5 379-378 838-830C3215 542 3575 185 3559 195c-8 5-614 481-1348 1059-734 577-1339 1049-1345 1049-13 0-743-529-790-572-67-61-89-186-47-268 45-90-23-61 1743-738C2675 379 3472 74 3542 48c138-53 167-54 195-11 9 13 16 36 15 51-1 19-1046 2900-1130 3113-18 45-74 101-127 125-56 27-146 31-203 11z"/>
                                                </svg>
                                                {transferType === "email" ? "Send" : "Get a link" }
                                            </Button>
                                        </div>
                                    </div>
                                    {
                                        response !== null ?
                                            <ul className={classes.filesList}>
                                                {this.renderFiles()}
                                            </ul>
                                        :
                                        <></>
                                            
                                    }
                                </div>
                        }
                        
                </DialogContent>
            </Dialog>
        )
    }
}

ShareLinkModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false,
    errorMessage: null,
    downloadLink: null,
    linkPassword: false,
    expire: null
}
ShareLinkModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool,
    errorMessage: PropTypes.string,
    downloadLink: PropTypes.string,
    linkPassword: PropTypes.string,
}
export default withStyles(shareLinkModalStyle)(ShareLinkModal);