import {
    whiteColor,
    grayColor,
    sidebar,
    tableHeadColumns,
    tableBody,
    controls,
    separator,
    content,
    infoColor,
    dangerColor,
    successColor,
} from "../material-kit-pro-react.jsx";

import userHomePageStyle from "./userHomePageStyle.jsx";
const linksPageStyle = theme => ({
    ...userHomePageStyle(theme),
    sidebar: {
        ...sidebar(theme)
    },
    main: {
        marginTop: "0px"
    },
    container: {
        width: "100%",
        zIndex: "2",
        top: "62px",
        position: "relative",
        paddingTop: "20px",
        height: "calc(100vh - 62px)",
        "& .MuiTabs-root": {
            marginTop: "0px",
            "&+div": {
                marginTop: "0px"
            }
        },
        "& .search-input": {
            paddingTop: "0px !important"
        }
    },
    content: {
        ...content(theme),
        display: "flex",
        flexDirection: "column",
        "& > *": {
            flexShrink: 0,
        },
        "& $tableGridItem": {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
        },
        "& $filesContainer": {
            maxHeight: "calc(100% - 60px)",
        },
    },
    controls: {
        ...controls(theme)
    },
    separator: {
        ...separator(theme)
    },
    filesContainer: {
        maxWidth: "100%",
        width: "100%",
        overflow: "auto",
        minHeight: "450px",
        maxHeight: "450px",
    },
    filesTable: {
        ...tableHeadColumns(theme),
        ...tableBody(theme),
        "&.mobile-view-table": {
            "& .rt-thead, .rt-tbody": {
                minWidth: "100% !important"
            }
        },
        "& .rt-thead": {
            position: "sticky",
            zIndex: "1",
            top: "0",
            background: theme.customize.tableHeadBackgroundColor,
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
            color: theme.customize.color,
        },
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            "& svg": {
                fill: theme.customize.tableRowActionsIconsColor,
                color: theme.customize.tableRowActionsIconsColor,
                "&.change-link": {
                    width: "22px",
                    height: "22px"
                }
            },
            "& button": {
                padding: "0px",
                height: "auto",
                width: "auto",
                minWidth: "0px",
                margin: "0px",
                color: theme.customize.color,
                "&.text-button": {
                    color: whiteColor,
                    textTransform: "capitalize",
                    padding: "5px",
                    fontSize: "12px",
                    "&.icon": {
                        color: theme.customize.color,
                        padding: "0px",
                        opacity: "0.7",
                        "& svg": {
                            width: "12px",
                            height: "12px",
                            marginLeft: "5px",
                            marginTop: "-3px",
                        }
                    }
                }
            },
            "&.td_hide": {
                display: "none"
            }
        },
        
        "& .hd_check": {
            width: "30px !important",
            textAlign: "center !important",
            flex: "none !important",
            marginRight: "10px !important",
            "& .MuiCheckbox-root": {
                paddingLeft: "0px",
                paddingRight: "0px",
                padding: "0px",
                display: "none",
                "&.Mui-checked": {
                    display: "inline-flex"
                }
            },
            "& .rt-resizer": {
                display: "none !important"
            },
        },
        "& .rt-tbody .rt-tr": {
            cursor: "auto",  
        },
        "& .rt-expander": {
            "&:after": {
                content: "'+'",
                color: theme.customize.color,
                border: "none",
                fontWeight: "bold",
                fontSize: "20px",
                top: "0%",
                transform: "none !important",
            },
            "&.-open": {
                "&:after": {
                    content: "'-'",
                }
            }
        },
        "& .td_check": {
            "& .MuiButtonBase-root": {
                padding: "0px"
            },
            "& svg": {
                fill: whiteColor+" !important"
            }
        },
        "& .hd_type": {
            textAlign: "left !Important"
        },
        "& .hd_account": {
            width: "10% !important",
            textAlign: "center !important",
        },
        "& .hd_server": {
            width: "5% !important",
            textAlign: "center !important",
        },
        "& .hd_sender": {
            width: "10% !important",
            textAlign: "left !important",
            "& div": {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            }
        },
        "& .hd_last_download": {
            width: "10% !important",
            textAlign: "left !important",
        },
        "& .hd_recipients": {
            width: "20% !important",
            textAlign: "left !important",
            "& div": {
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
            "&.hd_mobile_view": {
            }
        },
        "& .hd_message": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_path": {
            width: "12% !important",
            textAlign: "left !important",
            whiteSpace: "nowrap",
            "& div": {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            }
        },
        "& .hd_title": {
            textAlign: "left !important",
        },
        "& .hd_created": {
            width: "10% !important",
            textAlign: "left !important",
            whiteSpace: "nowrap",
        },
        "& .hd_attachments": {
            width: "20% !important",
            textAlign: "left !important",
        },
        "& .hd_link_password": {
            width: "13% !important",
            textAlign: "left !important",
            "& button": {
                height: "auto",
                paddingTop: "0px",
                paddingBottom: "0px",
                "& svg": {
                    width: "12px",
                    height: "12px",
                    marginLeft: "5px",
                },
                "&.eye-icon": {
                    "& svg": {
                        width: "14px",
                        height: "14px",
                    }
                }
            }
        },
        "& .hd_expire": {
            width: "13% !important",
            textAlign: "left !important",
            "& .expire-link": {
                color: dangerColor[0],
                whiteSpace: "nowrap"
            },
            "& svg": {
                width: "12px",
                height: "12px",
                marginLeft: "5px",
            }
        },
        "& .hd_status": {
            width: "7% !important",
            textAlign: "center !important",
            "& p": {
                color: successColor[0],
                "&.orange": {
                    color: infoColor[0]
                }
            }
        },
        "& .hd_action": {
            width: "7% !important",
            textAlign: "center !important",
        },
        "& .hd_actions": {
            width: "20% !important",
            textAlign: "center !important",
            "&>div": {
                display: "flex",
                alignItems: "center",
                columnGap: "12px",
                justifyContent: "flex-end",
                [theme.breakpoints.down("xs")]: {
                    columnGap: "5px",
                }
            }
        },
        "& .hd_mobile_icon": {
            width: "20px !important",
            flex: "20 0 auto !important",
            textAlign: "center !important",
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color: grayColor[17],
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px",
            "&.td_attachments": {
                wordBreak: "break-all",
                "& a+a": {
                    "&:before": {
                        content: "','",
                        display: "inline-block",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }
                },
                "& button": {
                    width: "auto !important",
                    height: "auto !important",
                    textTransform: "none",
                    "&:hover": {
                        textDecoration: "underline",
                    }
                }
            }
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px"
        },
        "& .sd-password-field": {
            display: "flex",
            alignItems: "center",
            "& .sd-passwords": {
                display: "flex",
                "& span:before": {
                    content: '""',
                    width: "3px",
                    height: "3px",
                    display: "block",
                    borderRadius: "100%",
                    backgroundColor: theme.customize.color,
                    marginRight: "2px"
                }
            }
        }
    },
    loadMore: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    filterGridItem: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        "& h4": {
            marginRight: "40px",
        },
        [theme.breakpoints.down("xs")]: {
            "& h4": {
                marginRight: "10px",
            },
        }
    },
    filterGridItemSm: {
        [theme.breakpoints.down("sm")]: {
            display: "block"
        }
    },
    linkFilters: {
        listStyleType: "none",
        padding: "0px",
        "& li": {
            display: "inline-block",
            cursor: "pointer",
            "&+li": {
                marginLeft: "20px",
                [theme.breakpoints.down("xs")]: {
                    marginLeft: "15px",
                }
            },
            "& img": {
                marginRight: "5px",
                width: "18px"
            },
            "&.active": {
                "& span": {
                    paddingBottom: "2px",
                    borderBottom: "3px solid "+theme.customize.iconsColor
                }
            }
        }
    },
    actionsContent: {
        margin: "20px 0px 30px",
        textAlign: "left",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "10px",
        minHeight: "50px",
        "& button": {
            "&.source": {
                fontSize: "16px",
                textTransform: "capitalize",
                padding: "0px",
                "& svg": {
                    color: theme.customize.whiteBlackColor,
                    fill: theme.customize.whiteBlackColor,
                    "&.left-icon": {
                        marginRight: "6px",
                        marginLeft: "0px"
                    },
                    "&.right-icon": {
                        marginRight: "0px",
                        marginLeft: "6px",
                        width: "10px"
                    }
                }
            }
        }
    },
    viewType: {
        margin: "10px 0px",
    },
    fileActions: {
        textAlign: "right",
        padding: "5px 20px",
        marginTop: "10px",
        backgroundColor: theme.customize.opacityColor,
        border: theme.customize.border,
        "& svg": {
            fill: theme.customize.iconsColor,
            color: theme.customize.iconsColor,
            width: "1em",
            display: "inline-block",
            verticalAlign: "middle",
            fontSize: "1.5rem",
            marginRight: "5px",
            marginTop: "-2px",
        },
        "& li": {
            "& button": {
                textTransform: "none",
                margin: "0px",
                padding: "6px 8px 4px 5px",            
                fontSize: "inherit",
                "&:hover": {
                    background: theme.customize.sidebarMenuHoverColor,
                }
            }
        },
        "& li + li": {
            "&:before": {
                content: "'|'",
                display: "inline-block",
                marginRight: "20px"
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: "10px",
                "&:before": {
                    marginRight: "10px"
                },
            }
        },
        [theme.breakpoints.down("xs")]: {
            "& li": {
                fontSize: "14px"
            },
            "& svg": {
                fontSize: "1.2rem",
                marginRight: "3px",
            }
        }
    },
    fileActionEnabled: {
        color: theme.customize.iconsColor
    },
    loading: {
        display: "none"
    }
});

export default linksPageStyle;
