import React from 'react';
import axios from "axios";
// import Cookie from '../../../assets/js/utils/Cookie';
import Api from "../../../assets/js/utils/Api";
import { Helmet } from 'react-helmet';
import Auth from "../../../assets/js/utils/Auth";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import GridItem from "../../components/Grid/GridItem";
import AddUserModal from "../../components/User/AddUserModal";
import AddMultiUserModal from "../../components/User/AddMultiUserModal"
import AccountAllowanceModal from "../../components/User/AccountAllowanceModal"
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import { helper } from '../../../assets/js/utils/Element';
import MoreHoriz from "@material-ui/icons/StorageOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import IconButton from "@material-ui/core/IconButton";
import Popover from '@material-ui/core/Popover';
import CustomFileInput from "../../components/CustomFileInput/CustomFileInput";
import CameraIcon from "@material-ui/icons/CameraAlt";
import CustomButton from "../../components/CustomButtons/Button.jsx";
import ChartistGraph from "react-chartist";
import DeleteConfirmationModal from "../../components/User/DeleteEmployeeConfirmationModal";
import ChangeStorageModal from "../../components/User/ChangeStorageModal";
import ChangeAccountTypeModal from "../../components/User/ChangeAccountTypeModal";
import Change2FAModal from "../../components/User/Change2FAModal";
import ChangeExtensionsModal from "../../components/User/ChangeExtensionsModal";

import MaterialIcon from "@mdi/react";
import { mdiAccountPlusOutline, mdiAccountMultiplePlusOutline, mdiDeleteOutline, mdiCloseCircleOutline, 
    mdiTwoFactorAuthentication, mdiLock, mdiMicrosoftOffice } from '@mdi/js';

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const Users = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            totalPages: 1,
            users: [],
            checked: [],
            errorMessage: null,
            addUserModal: false,
            accountAllowanceModal: false,
            addMultiUserModal: false,
            editMode: false,
            userDetails: this.defaultUserDetails(),
            popoverOpen: false,
            anchorEl: null,
            uploading: false,
            success: false,
            photo: null,
            user: null,
            userId: null,
            deleteModal: false,
            deletingUser: false,
            changeStorageModal: false,
            changingStorage: false,
            changeAccountTypeModal: false,
            changingAccountType: false,
            twoFAModal: false,
            changingTwoFA: false,
            extensionsModal: false,
            changingExtensions: false,
            checkedAll: false,
        };
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(user.hasOwnProperty('user_type') && user.user_type !== "business"){
            let redirectUrl = helper.getHomePageUrl(user);
            if(user.level_id === 5){
                redirectUrl = "/user/shared";
            }
            this.history.push(redirectUrl);
        }
        if(authorized){
            this.loadUsers();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    defaultUserDetails(){
        const { user } = this.store.getState();

        return {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordConfirm: "",
            quota: "",
            timezone: user.timezone,
            enable_2fa: false,
            cell: "",
            accountType: 2,
            extensions: {
                adobesign: {
                    status: false
                },
                docusign: {
                    status: false
                },
                ms_office: {
                    status: false
                },
                google_drive: {
                    status: false
                },
                one_drive: {
                    status: false
                },
                sharepoint: {
                    status: false
                }
            }
        }
    }
    onAddUserModalSuccess(){
        this.loadUsers();
    }
    onAccountAllowanceModalSuccess(newUser){
        const { user, response } = this.state;
        if(user !== null){
            response.map((user, index) => {
                if(user.id === newUser.id){
                    response[index] = newUser;
                }
                return null;
            });
            this.setState({response: response, accountAllowanceModal: false, user: null});
        }else{
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
            }
        }else{
            return {}
        }
    }
    getTrGroupProps(state, rowInfo){
        const { checked } = this.state;
        
        if (rowInfo && rowInfo.row) {
            if(checked.indexOf(rowInfo.original.id) !== -1){
                return {
                    className: 'selected_row'
                }
            }
            return {
                className: (rowInfo.original.id === 0 ? 'frozen_row' : '')
            }
        }else{
            return {}
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    onAccountAllowanceModal(status, user = null){
        this.setState({
            accountAllowanceModal: status,
            user: user
        });
    }
    onAddUserModal(status){
        if(status === false){
            this.loadUsers();
            return;
        }
        this.setState({
            addUserModal: status,
            userDetails: this.defaultUserDetails()
        });
    }
    onEditModal(status = true, user){
        if(!status){
            this.loadUsers();
            return;
        }
        let userQuota = helper.getFormatedSize(user.quota);
        this.setState({
            addUserModal: status,
            userDetails: {
                id: user.id,
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                password: "",
                passwordConfirm: "",
                quota: userQuota,
                timezone: user.timezone,
                enable_2fa: user.enable_2fa,
                cell: user.cell,
                accountType: user.account_type,
                extensions: user.extensions
            },
            editMode: status
        });
    }
    onAddMultiUserModal(status){
        if(status === false){
            this.loadUsers();
            return;
        }
        this.setState({
            addMultiUserModal: status
        })
    }
    loadUsers(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 200,
        };
        Api.getUsers(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            addUserModal: false,
            addMultiUserModal: false,
            editMode: false,
            deleteModal: false,
            deletingUser: false,
            changeStorageModal: false,
            changingStorage: false,
            changeAccountTypeModal: false,
            changingAccountType: false,
            checked: [],
            twoFAModal: false,
            changingTwoFA: false,
            extensionsModal: false,
            changingExtensions: false,
            checkedAll: false
        });
    }
    getUserTitle(user){
        let title = "";
        if(user.hasOwnProperty("first_name")){
            title += user.first_name;
        }
        if(user.hasOwnProperty("last_name")){
            title += " "+user.last_name;
        }
        return title;
    }
    onToggleAllUsers(e){
        if(this.state.loading){
            return;
        }

        const checkedUsers = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((user, key) => {
                checkedUsers.push(user.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedUsers
        });
    }
    getColumns(){
        const { classes } = this.props;

        let header = (
            <Checkbox
                tabIndex={-1}
                checked={this.state.checkedAll}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                onChange={(e) => this.onToggleAllUsers(e)}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                }}
            />
        );

        let columns = [
            {
                Header: header,
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false,
            },
            {
                Header: "Display Name",
                accessor: "displayname",
                headerClassName: "hd_displayname",
                className: "hd_displayname td_displayname left",
            },
            {
                Header: "Email",
                accessor: "email",
                headerClassName: "hd_email",
                className: "hd_email td_email left",
            },
            {
                Header: "Storage",
                accessor: "storage",
                headerClassName: "hd_storage",
                className: "hd_storage td_storage left",
            },
            {
                Header: "Account Type",
                accessor: "plan",
                headerClassName: "hd_storage",
                className: "hd_storage td_storage left",
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false,
                headerClassName: "hd_actions",
                className: "hd_actions td_actions",
            }
        ];
        
        return columns;
    }
    handleToggle(user) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(user.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(user.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked,
        });
    }
    onHandlePopover(event, status, user = null){
        this.setState({
            popoverOpen: status,
            user: user,
            anchorEl: event.currentTarget
        });

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 200);
    }
    userFirstLetter(user){
        let userFirstLetter = user.first_name.charAt(0);
        return userFirstLetter;
    }
    getPieChart(user){
        const quota = user.quota;
        const usage = user.usage;
        let usagePercentage = (usage/quota)*100;
        usagePercentage = Math.round(usagePercentage * 10) / 10;
        if(usagePercentage > 100){
            usagePercentage = 100;
        }
        let remainingPercentage = 100 - usagePercentage;
        if(remainingPercentage < -100){
            remainingPercentage = -100;
        }
        if(helper.isFloat(usagePercentage)){
            usagePercentage = usagePercentage.toFixed(1);
        }
        if(helper.isFloat(remainingPercentage)){
            remainingPercentage = remainingPercentage.toFixed(1);
        }
        let pieChart = {
            data: {
                labels: [usagePercentage+"%", remainingPercentage+"%"],
                series: [usagePercentage, remainingPercentage]
            },
            options: {
                chartPadding: 0,
                height: 67,
                width: 67
            }
        };
        return pieChart;
    }
    renderStats(user){
        if(!user.hasOwnProperty("stats")){
            return <></>
        }
        let stats = [];
        user.stats.map((stat, key) => {
            const statClassName = stat.title.toLowerCase().replace(" ","_");
            stats.push((
                <li key={key}>
                    <ul>
                        <li className="stat-name"><span className={statClassName}></span> {stat.title}</li>
                        <li className="stat-perc">{stat.storage_used}%</li>
                    </ul>
                </li>
            ));
            return null;
        });
        return stats;
    }
    onDeleteModal(status = false, userId = null){
        const { checked } = this.state;
        if(checked.length <= 0 && userId === null && status === true){
            return;
        }
        this.setState({
            deleteModal: status,
            userId: userId
        });
    }
    onDeleteModalSuccess(){
        const { userId, checked } = this.state;
        let users = [userId];
        if(userId === null){
            users = checked;
        }
        const source = axios.CancelToken.source();
        this.setState({
            deletingUser: true,
            cancelToken: source,
        });
        const requestData = {
            users: users
        }

        Api.deleteBusinessEmployee(requestData, source).then(data => {
            this.loadUsers();
        }).catch(err => {
            console.log(err);
        });
    }
    onChangeStorageModal(status = false){
        const { checked } = this.state;
        if(checked.length <= 0 && status === true){
            return;
        }
        this.setState({
            changeStorageModal: status,
            errorMessage: null
        });
    }
    onChangeStorageModalSuccess(quota){
        const { checked } = this.state;
        let users = checked;
        
        const source = axios.CancelToken.source();
        this.setState({
            changingStorage: true,
            cancelToken: source,
        });

        const requestData = {
            quota: helper.getBytesSize(quota),
            users: users
        }

        Api.changeStorageSize(requestData, source).then(data => {
            this.loadUsers();
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    errorMessage: err.message,
                    changingStorage: false,
                    cancelToken: null
                });
            }
            console.log(err);
        });
    }
    onChangeAccountTypeModal(status = false){
        const { checked } = this.state;
        if(checked.length <= 0 && status === true){
            return;
        }
        this.setState({
            changeAccountTypeModal: status,
            errorMessage: null
        });
    }
    onChangeAccountTypeModalSuccess(accountType){
        const { checked } = this.state;
        let users = checked;
        
        const source = axios.CancelToken.source();
        this.setState({
            changingAccountType: true,
            cancelToken: source,
        });

        const requestData = {
            accountType: accountType,
            users: users
        }

        Api.changeAccountType(requestData, source).then(data => {
            this.loadUsers();
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    errorMessage: err.message,
                    changingAccountType: false,
                    cancelToken: null
                });
            }
            console.log(err);
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, popoverOpen } = this.state;
        const id = popoverOpen ? 'simple-popover' : undefined;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(user => {
            let userArray = {
                id: user.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(user.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(user)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                displayname: this.getUserTitle(user),
                email: user.email,
                plan: user.plan,
                storage: (
                    <span>{ helper.getFormatedSize(user.usage) } / { helper.getFormatedSize(user.quota) }</span>
                ),
                actions: (
                    <>
                        <IconButton 
                            color="inherit" 
                            onClick={() => this.onAccountAllowanceModal(true, user)}
                            title="Account Access"
                        >
                            <MaterialIcon path={mdiLock} className="MuiSvgIcon-root" />
                        </IconButton>
                        <IconButton
                            className={classes.userFirstLetter}
                            color="inherit"
                            aria-label="open popup"
                            variant="contained"
                            onClick={(e) => this.onHandlePopover(e, true, user)}
                            aria-describedby={id}
                            title="User Detail"
                        >
                            <MoreHoriz />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            onClick={() => this.onEditModal(true, user)}
                            title="Edit"
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton 
                            color="inherit" 
                            onClick={() => this.onDeleteModal(true, user.id)}
                            title="Delete"
                        >
                            <MaterialIcon path={mdiDeleteOutline} className="MuiSvgIcon-root" />
                        </IconButton>
                    </>
                )
            };
            tableData.push(userArray);
            return null;
        });
        return tableData;
    }
    getUsersContent(response){
        const { classes } = this.props;
        const { loading, checked } = this.state; 
        const { user } = this.store.getState();

        let content = (
            <div className={classes.content}>
                <div className={classes.controls}>
                    <GridItem>
                        <h4>Active Users</h4>
                        <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                            <Button color="transparent" onClick={() => this.onAddUserModal(true)}>
                                <MaterialIcon path={mdiAccountPlusOutline} className="MuiSvgIcon-root" />
                                Add a user
                            </Button>
                            <Button color="transparent" onClick={() => this.onAddMultiUserModal(true)}>
                                <MaterialIcon path={mdiAccountMultiplePlusOutline} className="MuiSvgIcon-root" />
                                Add multiple users
                            </Button>
                            <Button color="transparent" onClick={() => this.onDeleteModal(true)} disabled={(checked.length >= 0 ? false : true)}>
                                <MaterialIcon path={mdiCloseCircleOutline} className="MuiSvgIcon-root" />
                                Delete
                            </Button>
                            <Button color="transparent" onClick={() => this.onChangeStorageModal(true)} disabled={(checked.length >= 0 ? false : true)}>
                                <MoreHoriz />
                                Change Storage
                            </Button>
                            <Button color="transparent" onClick={() => this.onChangeAccountTypeModal(true)} disabled={(checked.length >= 0 ? false : true)}>
                                <SupervisorAccountIcon />
                                Change Account Type
                            </Button>
                            <Button color="transparent" onClick={() => this.onAccountAllowanceModal(true)}>
                                <MaterialIcon path={mdiLock} className="MuiSvgIcon-root" />
                                Account Access
                            </Button>
                            <Button color="transparent" onClick={() => this.onChange2FAModal(true)} disabled={(checked.length >= 0 ? false : true)}>
                                <MaterialIcon path={mdiTwoFactorAuthentication} className="MuiSvgIcon-root" />
                                Change 2FA
                            </Button>
                            <Button color="transparent" onClick={() => this.onChangeExtensionsModal(true)} disabled={(checked.length >= 0 ? false : true)}>
                                <MaterialIcon path={mdiMicrosoftOffice} className="MuiSvgIcon-root" />
                                Change Extensions
                            </Button>
                        </div>
                    </GridItem>
                </div>
                <div className={classes.usersContainer}>
                    <GridItem>
                    <div className={classes.users}>
                        {
                            loading ?
                                <LoaderComponent color="custom" align="center" />
                            :
                                <ReactTable
                                    columns={this.getColumns()}
                                    data={this.getTableData()}
                                    pageSizeOptions={[500]}
                                    defaultPageSize={50000000000}
                                    showPaginationTop={false}
                                    minRows={0}
                                    showPaginationBottom={false}
                                    className={"-striped -highlight "+classes.filesTable+" usersTable"}
                                    getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                    getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                                    getNoDataProps={() => this.getNoDataProps()}
                                />
                        }
                    </div>
                    </GridItem>
                </div>
            </div>
        );
        
        return (
            content 
        );
    }
    handleFileChange(e, name){
        let photo = e.target.files[0];

        var requestData = new FormData();
        requestData.append('photo', photo);
        const source = axios.CancelToken.source();
        this.setState({
            [name]: photo,
            uploading: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.uploadOtherUserPhoto(requestData, source, this.state.user.id).then(data => {
            let response  = this.state.response;
            var userIndex = response.findIndex(function(c) { 
                return c.id === data.id;
            });


            var newData = [
                ...this.state.response
            ];
            newData[userIndex] = data;

            that.setState({
                success: true,
                uploading: false,
                response: newData,
                user: data
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    uploading: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    loginAsUser(user){
        localStorage.setItem("oauth-identity", user.id);
        localStorage.setItem("sendlinx_userinfo", JSON.stringify(user));
        
        window.location.reload();
    }
    userPopover(){
        const { classes } = this.props;
        const { popoverOpen, uploading, success, photo, user, anchorEl } = this.state;
        const id = popoverOpen ? 'simple-popover' : undefined;
        
        return(
            <Popover
                id={id}
                anchorEl={anchorEl}
                open={popoverOpen}
                onClose={(e) => this.onHandlePopover(e, false)}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                classes={{
                    root: classes.popoverRoot
                }}
            >
                <div className={classes.popupContent}>
                    <div className={"top-content"}>
                        <div className={classes.userFirstLetter+" user-image"}>
                            <span className="user-icon">
                                {
                                    uploading === true ?
                                        <LoaderComponent color="custom" align="center" />
                                    : success === true || user.icon_photo_url ?
                                        <img className={"profile-picture"} src={user.icon_photo_url} alt={this.userFirstLetter(user)} />
                                    : 
                                        this.userFirstLetter(user)
                                }
                            </span>
                            <div className="uploadPhotoInput">
                                <CustomFileInput
                                    id="input-photo"                            
                                    onChange={(e) => this.handleFileChange(e,'photo')}
                                    endButton={{
                                        buttonProps: {
                                            round: true,
                                            color: "info",
                                            justIcon: true,
                                            fileButton: true
                                        },
                                        icon: <CameraIcon />
                                    }}
                                    inputProps={{
                                        value: photo,
                                        placeholder: "Photo"
                                    }}
                                    color="custom"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                            </div>
                        </div>
                        <h4 className="user-name">{this.getUserTitle(user)}</h4>
                        <div className="stats-section">
                            <div className="left-stats">
                                <div className="graph-stats">
                                    <ChartistGraph
                                        data={this.getPieChart(user).data}
                                        type="Pie"
                                        options={this.getPieChart(user).options}
                                    />
                                </div>
                                <div className="data-detail">
                                    <span className="used-data">{helper.getFormatedSize(user.usage)}</span>
                                    <span className={"remaining-data "+user.quota}>{helper.getFormatedSize(user.quota)}</span>
                                </div>
                            </div>
                            <div className="right-stats">
                                <ul>
                                    {this.renderStats(user)}
                                </ul>
                            </div>
                        </div>
                        <div className="signup-btn">
                            <CustomButton onClick={() => this.loginAsUser(user)} className={"user-manage"} color="purewhite">
                                Sign In
                            </CustomButton>
                        </div>
                    </div>
                    <div className="bottom-content">
                        <ul>
                        </ul>
                    </div>
                </div>
            </Popover>
        )
    }
    onChange2FAModal(status = false){
        const { checked } = this.state;
        if(checked.length <= 0 && status === true){
            return;
        }
        this.setState({
            twoFAModal: status,
            changingTwoFA: false
        });
    }
    onChange2FAModalSuccess(enable_2fa){
        const { checked } = this.state;
        let users = checked;
        
        const source = axios.CancelToken.source();
        this.setState({
            changingTwoFA: true,
            cancelToken: source,
        });

        const requestData = {
            enable_2fa: enable_2fa,
            users: users
        }

        Api.change2FA(requestData, source).then(data => {
            this.loadUsers();
        }).catch(err => {
            console.log(err);
        });
    }
    onChangeExtensionsModal(status = false){
        const { checked } = this.state;
        if(checked.length <= 0 && status === true){
            return;
        }
        this.setState({
            extensionsModal: status,
            changingExtensions: false
        });
    }
    onChangeExtensionsModalSuccess(extensions){
        const { checked } = this.state;
        let users = checked;
        
        const source = axios.CancelToken.source();
        this.setState({
            changingExtensions: true,
            cancelToken: source,
        });

        const requestData = {
            extensions: extensions,
            users: users
        }

        Api.changeExtensions(requestData, source).then(data => {
            this.loadUsers();
        }).catch(err => {
            console.log(err);
        });
    }
    render() {
        const { classes } = this.props;
        const { response, addUserModal, editMode, userDetails, addMultiUserModal, deleteModal, deletingUser, checked, 
            changeStorageModal, changingStorage, changeAccountTypeModal, changingAccountType, accountAllowanceModal, twoFAModal, changingTwoFA,
            extensionsModal, changingExtensions, errorMessage } = this.state;
        
        const { authorized, user } = this.store.getState();
        if(!authorized || user.level_id === 5){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }   
        
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    {
                        this.getUsersContent(response)
                    }
                    {
                        this.state.user ?
                            this.userPopover()
                        :
                            <></>
                    }
                    {
                        addUserModal ?
                            <AddUserModal store={this.store} open={addUserModal} editMode={editMode} userDetails={userDetails} onClose={() => this.onAddUserModal(false)} onSuccess={() => this.onAddUserModalSuccess()} />
                        :
                            <></>
                    }
                    {
                        accountAllowanceModal ?
                            <AccountAllowanceModal user={this.state.user} open={accountAllowanceModal} onClose={() => this.onAccountAllowanceModal(false)} onSuccess={(user) => this.onAccountAllowanceModalSuccess(user)} store={this.store} />
                        :
                            <></>
                    }
                    {
                        addMultiUserModal ?
                            <AddMultiUserModal open={addMultiUserModal} onClose={() => this.onAddMultiUserModal(false)} onSuccess={() => this.onAddUserModalSuccess()} store={this.store} />
                        :
                            <></>
                    }
                    {
                        deleteModal ?
                            <DeleteConfirmationModal 
                                open={deleteModal} 
                                saving={deletingUser} 
                                onClose={() => this.onDeleteModal(false)} 
                                onSuccess={() => this.onDeleteModalSuccess()}
                                confirmationMessage="Deleting of account(s) cannot be UNDONE. Are you sure?"
                                checked={checked}
                            />
                        :
                        <></>
                    }
                    {
                        changeStorageModal ?
                            <ChangeStorageModal 
                                open={changeStorageModal} 
                                saving={changingStorage} 
                                onClose={() => this.onChangeStorageModal(false)} 
                                onSuccess={(quota) => this.onChangeStorageModalSuccess(quota)}
                                confirmationMessage="Are you sure you want to change storage size?"
                                checked={checked}
                                errorMessage={errorMessage}
                            />
                        :
                            <></>
                    }
                    {
                        changeAccountTypeModal ?
                            <ChangeAccountTypeModal 
                                open={changeAccountTypeModal} 
                                saving={changingAccountType} 
                                onClose={() => this.onChangeAccountTypeModal(false)} 
                                onSuccess={(accountType) => this.onChangeAccountTypeModalSuccess(accountType)}
                                confirmationMessage="Are you sure you want to change account type?"
                                checked={checked}
                                errorMessage={errorMessage}
                            />
                        :
                            <></>
                    }
                    {
                        twoFAModal ? 
                            <Change2FAModal 
                                open={twoFAModal} 
                                saving={changingTwoFA} 
                                onClose={() => this.onChange2FAModal(false)} 
                                onSuccess={(enable_2fa) => this.onChange2FAModalSuccess(enable_2fa)}
                                confirmationMessage="Change Two Factor Authentication"
                                checked={checked}
                            />
                        :
                        null
                    }
                    {
                        extensionsModal ? 
                            <ChangeExtensionsModal 
                                open={extensionsModal} 
                                saving={changingExtensions} 
                                onClose={() => this.onChangeExtensionsModal(false)} 
                                onSuccess={(extensions) => this.onChangeExtensionsModalSuccess(extensions)}
                                confirmationMessage="Change Extensions"
                                checked={checked}
                                store={this.store}
                            />
                        :
                        null
                    }
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Users.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Users);
