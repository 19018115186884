import {
    dangerColor, blackColor, whiteColor
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const externalLinkModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalTitleDesc: {
        color: theme.customize.whiteBlackColor,
        opacity: "0.8",
        marginBottom: "0px"
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "65px",
        paddingBottom: "50px",
        "&.success-body": {
            paddingTop: "50px",
            paddingBottom: "30px",
        },
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginRight: "10px",
            "& svg": {
                color: theme.customize.iconsColor,
                fill: theme.customize.iconsColor,
            }
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    errorMessageDiv: {
        padding: "10px 0px"
    },
    errorMessage: {
        color: dangerColor[0]
    },
    downloadLink: {
        wordBreak: "break-all",
        textAlign: "left",
        "&>p": {
            fontWeight: "700",
            marginBottom: "15px",
            lineHeight: "normal",
            color: theme.customize.whiteBlackColor
        },
        "& .share-icon-lists": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            listStyleType: "none",
            padding: '0px',
            marginBottom: theme.spacing(3),
            "& li": {
                width: "66px",
                height: "66px",
                borderRadius: '100%',
                "& a": {
                    cursor: "pointer"
                },
                "& img": {
                    maxWidth: '66px',
                    width: "100%",
                    display: 'block'
                },
                [theme.breakpoints.down("xs")]: {
                    width: "40px",
                    height: "40px", 
                    "& svg": {
                        width: "40px",
                        height: "40px"
                    },
                    "& img": {
                        maxWidth: "40px"
                    }
                }
            },
            "& .sendlinx-icon": {
                backgroundColor: "#888787",  
                "& img": {
                    padding: "5px"
                }
            },
        },
        "& .download-link-section": {
            backgroundColor: "#D9D9D94D",
            display: 'flex',
            borderRadius: '5px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '7px 6px',
            "& p": {
                fontSize: '16px',
                margin: '0px',
                flex: '1',
                color: theme.customize.whiteBlackColor,
                opacity: "0.8"
            },
            "& button": {
                margin: "0px",
                textTransform: 'none',
                padding: '0.3rem 1.0rem',
                fontSize: "16px",
                borderRadius: "5px",
                backgroundColor: blackColor,
                "& span": {
                    color: whiteColor,
                    opacity: "1"
                }
            },
            '& .icon': {                
                paddingRight: '4px',
                display: 'flex',
                paddingLeft: '4px',
                marginRight: '5px',
                "& svg": {
                    color: theme.customize.whiteBlackColor,
                    fill: theme.customize.whiteBlackColor,
                    opacity: "0.5"
                }
            }
        }
    },
    dNone: {
        display: 'none'
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    dateTimePicker: {
        position: "relative",
        "& .clear-icon": {
            position: "absolute",
            margin: "0px",
            padding: "0px",
            width: "24px",
            height: "24px",
            minWidth: "auto",
            right: "10px",
            bottom: "14px",
        }
    }
});
  
export default externalLinkModalStyle;
  