import React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from "../../../assets/js/utils/Element";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import RefreshIcon from "@material-ui/icons/Refresh";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteLinkModal from "../../components/User/DeleteLinkModal";
import RestoreLinkModal from "../../components/User/RestoreLinkModal";
import Link from "@material-ui/core/Link";
import AttachmentsModal from  "../../components/User/AttachmentsModal";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import MaterialIcon from "@mdi/react";
import { mdiArrowExpandUp } from '@mdi/js';

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
const history = createBrowserHistory();

const UserTrash = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        const backPath = this.getBackPath(path);

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            path: decodeURIComponent(path.trim()),
            backPath: backPath,
            deletelinkModal: false,
            restoreLinkModal: false,
            trash: [],
            downloadUrl: "",
            attachmentsModal: false,
            attachmentFiles: [],
            checkedAll: false,
            restoreLinkMessage: '',
            restoreLoading: false,
            viewPasswords: [],
            activeTab: 2
        };

        this.loadingFolder = false;
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            this.loadLinks();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    getBackPath(path){
        if(typeof(path) !== "string" || path.trim().length <= 0){
            return "";
        }
        if(path.length <= 1 || path.trim() === "/"){
            return "";
        }
        if(path.substr(0, 1) !== "/"){
            path += "/";
        }
        path = decodeURIComponent(path.trim()); //Decode path
        path = path.replace(/^\/+|\/+$/g, ''); //Remove trailing slash
        const pathArray = path.split("/");
        pathArray.pop();
        let backPath = pathArray.join("/");
        if(backPath.length <= 0){
            backPath = "/";
        }
        return backPath;
    }
    loadLinks(viewMore = false, newPath = null, backPath = ""){
        const source = axios.CancelToken.source();
        let { page, path, trash } = this.state;
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        let requestData = {
            page: page,
            limit: 200,
            trash: 1
        };
        backPath = this.getBackPath(path);
        history.push("/user/trash/links?dir="+encodeURIComponent(path));
        Api.getDeletedLinks(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            data.response.map(link => {
                if(link.trash){
                    trash.push(link.id);
                }
                return null;
            });
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                trash: trash
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            path: path,
            backPath: backPath,
            checked: viewMore ? this.state.checked : [],
        });
        this.loadingFolder = true;
    }
    handleToggle(link) {
        if(this.loadingFolder){
            return;
        }
        
        const { checked } = this.state;
        const currentIndex = checked.indexOf(link.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(link.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    onAttachmentsModal(status = false, files = []){
        this.setState({
            attachmentsModal: status,
            attachmentFiles: files
        })
    }
    getAttachmentsPlainText(link){
        let attachment = "";
        if(link.files.length > 2){
            attachment = (
                link.attachments
            )
        }else {
            attachment = (
                link.files.map((file, key) => {
                    return (
                        file.name
                    );
                })
            )
        }

        return attachment;
    }
    getAttachments(link){
        let attachment = "";
        if(link.files.length > 2){
            attachment = (
                <Button simple onClick={() => this.onAttachmentsModal(true, link.files)}>{link.attachments}</Button>
            )
        }else {
            attachment = (
                link.files.map((file, key) => {
                    return (
                        <Link href={file.downloadLink} target="_blank" key={key}>
                            {file.name}
                        </Link>
                    );
                })
            )
        }

        return attachment;
    }
    getType(link){
        let label = "Direct Upload";
        if(link.upload_type.length <= 0){
            link.upload_type = link.type;
        }
        switch(link.upload_type){
            case 'web':
                label = 'Web';
                break;
            case 'my_docs':
                label = 'My Docs';
                break;
            case 'direct_upload':
                label = 'Direct Upload';
                break;
            case 'outlook':
                label = 'Outlook';
                break;
            case 'desktop':
                label = 'Desktop';
                break;
            case 'mobile':
                label = 'Mobile';
                break;
            // case 'share':
            //     label = 'Shared';
            //     break;
            // case 'manager_upload':
            //     label = 'File Manager Upload';
            //     break;
            default:
                if(link.path.indexOf("Web")){
                    label = "Web";
                }else if(link.path.indexOf("Outlook")){
                    label = "Outlook";
                }else if(link.path.indexOf("My Docs")){
                    label = "Desktop";
                }
                break;
        }
        return label;
    }
    onToggleAllFiles(e){
        if(this.loadingFolder){
            return;
        }

        const checkedFiles = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((file, key) => {
                checkedFiles.push(file.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedFiles
        });
    }
    onViewPassword(link){
        const { viewPasswords } = this.state;
        const currentIndex = viewPasswords.indexOf(link.id);
        const newViewPasswords = [...viewPasswords];
    
        if (currentIndex === -1) {
            newViewPasswords.push(link.id);
        } else {
            newViewPasswords.splice(currentIndex, 1);
        }

        this.setState({
            viewPasswords: newViewPasswords
        });
    }
    convertStringToPass(password){
        let string = "";
        for (let i = 0; i < password.length; i++) {
            string +="<span></span>";
        }

        return string;
    }
    getColumns(){
        const { classes } = this.props;

        let header = (
            <Checkbox
                tabIndex={-1}
                checked={this.state.checkedAll}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                onChange={(e) => this.onToggleAllFiles(e)}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                }}
            />
        );

        let columns = [
            {
                Header: header,
                accessor: "check",
                headerClassName: "hd_check",
                className: "hd_check td_check",
                sortable: false,
                filterable: false,
                resizable: false
            },
            {
                Header: "Attachment(s)",
                accessor: "attachmentsPlainText",
                headerClassName: "hd_attachments hd_show_head",
                className: "hd_attachments td_attachments td_hide",
                resizable: false
            },
            {
                Header: "Attachment(s)",
                accessor: "attachments",
                headerClassName: "hd_attachments hd_hide",
                className: "hd_attachments td_attachments",
            },
            {
                Header: "Destination",
                accessor: "recipients",
                headerClassName: "hd_recipients",
                className: "hd_recipients td_recipients td_destination",
            },
            {
                Header: "Link Password",
                accessor: "link_password",
                headerClassName: "hd_link_password",
                className: "hd_link_password td_link_password",
                sortable: false,
            },
            {
                Header: "Type",
                accessor: "type",
                headerClassName: "hd_type",
                className: "hd_type td_type",
            },
            {
                Header: "Size",
                accessor: "orignalSize",
                headerClassName: "hd_size hd_show_head",
                className: "hd_size td_size td_hide",
                resizable: false
            },
            {
                Header: "Size",
                accessor: "size",
                headerClassName: "hd_size hd_hide",
                className: "hd_size td_size",
            },
            {
                Header: "Uploaded",
                accessor: "date",
                headerClassName: "hd_creation_date",
                className: "hd_creation_date td_creation_date",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Creator",
                accessor: "owner",
                headerClassName: "hd_owner",
                className: "hd_owner td_owner",
            },
            
        ]

        return columns;
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, backPath, viewPasswords } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        if(backPath.length > 0){
            let linkArray = {
                id: 0,
                attachmentsPlainText: "",
                attachments: "",
                recipients: "",
                link_password: "",
                type: "",
                check: "",
                path: "",
                name: (
                    <Button color="transparent" justIcon className={classes.fileIcon}>
                        { helper.getFolderIcon() }
                    </Button>
                ),
                orignalSize: "",
                size: "",
                date: "",
                owner: "",
            };
            tableData.push(linkArray);
        }
        response.map(link => {
            let linkArray = {
                id: link.id,
                attachmentsPlainText: this.getAttachmentsPlainText(link),
                attachments: this.getAttachments(link),
                recipients: (
                    link.title !== '' && link.title ?
                        <div title={ link.title }>{ link.title }</div>
                    :
                        <div title={ link.recipients.join(",") }>{ link.recipients.join(",") }</div>
                        
                ),
                link_password: (
                    <>
                        {
                            link.link_password ?
                                <>
                                    <div className="sd-password-field">
                                        { 
                                            viewPasswords.includes(link.id) ?
                                                link.link_password
                                            :
                                                <div className="sd-passwords" dangerouslySetInnerHTML={{__html: this.convertStringToPass(link.link_password)}}></div>
                                        }
                                    </div>
                                    <Button justIcon color="transparent" onClick={() => this.onViewPassword(link)} title={"View Password"}>
                                        { 
                                            viewPasswords.includes(link.id) ?
                                                <VisibilityOffIcon />
                                            :
                                                <VisibilityIcon />
                                        }
                                    </Button>
                                </>
                            :
                                <></>
                        }
                    </>
                ),
                type: this.getType(link),
                path: link.path,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(link.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(link)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                orignalSize: link.orignalSize,
                size: link.size,
                date: link.date,
                owner: link.owner,
            };
            tableData.push(linkArray);
            return null;
        });
        return tableData;
    }
    loadFolder(e, parent){
        e.preventDefault();
        this.loadLinks(false, parent.name);
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
            }
        }else{
            return {}
        }
    }
    getTrGroupProps(state, rowInfo){
        const { checked } = this.state;
        
        if (rowInfo && rowInfo.row) {
            if(checked.indexOf(rowInfo.original.id) !== -1){
                return {
                    className: 'selected_row'
                }
            }
            return {
                className: (rowInfo.original.id === 0 ? 'frozen_row' : '')
            }
        }else{
            return {}
        }
    }
    deleteSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({deletelinkModal: true});
    }
    onDeleteModalClose(deletelinkModal = true){
        let checked = this.state.checked;
        if(deletelinkModal === false){
            checked = [];
        }
        this.setState({
            deletelinkModal: deletelinkModal,
            checked: checked,
            checkedAll: false
        });
    }
    onDeleteModalSuccess(){
        const { checked, response } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            links: checked,
        };
        Api.deleteLinksPermanently(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });

        let newResponse = [];
        response.map(link => {
            if(!checked.includes(link.id)){
                newResponse.push(link);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deletelinkModal: false,
            checkedAll: false
        });
    }
    restoreSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({restoreLinkModal: true});
    }
    onRestoreModalClose(loadLinks = false, restoreLinkModal = true){
        let checked = this.state.checked;
        if(restoreLinkModal === false){
            checked = [];
        }
        this.setState({
            restoreLinkModal: restoreLinkModal,
            checked: checked,
            checkedAll: false,
            restoreLinkMessage: '',
            restoreLoading: false
        });

        if(loadLinks){
            this.loadLinks();
        }
    }
    onRestoreModalSuccess(){
        const { checked, response } = this.state;
        let newResponse = [];
        response.map(link => {
            if(!checked.includes(link.id)){
                newResponse.push(link);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            restoreLinkModal: false,
            checkedAll: false
        });
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getBreadCrumbs(){
        let { path } = this.state;
        let breadcrumbs = [{
            value: "/",
            label: "Home"
        }];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        let newPath = "";
        pathArray.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    getCheckedLinks(){
        const { response, checked } = this.state;
        if(checked.length <= 0){
            return [];
        }
        let links = [];
        response.map((link) => {
            if(checked.indexOf(link.id) !== -1){
                links.push(link);
            }
            return null;
        });
        return links;
    }
    onTabChange(urlTab){
        if(urlTab === '0'){
            this.setState({activeTab:0})
            this.history.push("/user/trash");
        }else if(urlTab === '1'){
            this.setState({activeTab:1})
            this.history.push("/user/trash/files");
        }else if(urlTab === '2'){
            this.setState({activeTab:2})
            this.history.push("/user/trash/links");
        }
    }
    render() {
        const { classes } = this.props;
        const { limit, loading, loadingMore, page, totalPages, deletelinkModal, restoreLinkModal, attachmentsModal,
            attachmentFiles, restoreLinkMessage, restoreLoading, checked, activeTab } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        const checkedLinks = this.getCheckedLinks();
        const tableData = this.getTableData();
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content+" "+classes.flexPageContent}>
                        <div className={classes.controls}>
                            <GridItem className={classes.controlsTabs}>
                                <div className='sd-custom-tabs'>
                                    <div className={'sd-custom-tab '+(activeTab === 0 ? 'active': '')} onClick={() => this.onTabChange("0")}>
                                        <span>Trash</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 1 ? 'active': '')} onClick={() => this.onTabChange("1")}>
                                        <span>Files</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 2 ? 'active': '')} onClick={() => this.onTabChange("2")}>
                                        <span>Links</span>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem>
                                <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                                    <Button color="transparent" onClick={() => this.deleteSelected()}>
                                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.74308 1.125H10.2531C10.4618 1.125 10.6554 1.22344 10.7692 1.38867L11.3498 2.25H5.65022L6.2308 1.38867C6.34085 1.22344 6.53817 1.125 6.74688 1.125H6.74308ZM12.7842 2.25L11.8013 0.794531C11.4674 0.298828 10.883 0 10.2569 0H6.74308C6.11696 0 5.53259 0.298828 5.19866 0.794531L4.21585 2.25H2.43237H1.21429H0.607143C0.273214 2.25 0 2.50312 0 2.8125C0 3.12188 0.273214 3.375 0.607143 3.375H1.30156L2.2692 15.9117C2.35647 17.0895 3.41518 18 4.69018 18H12.3098C13.5848 18 14.6397 17.0895 14.7308 15.9117L15.6984 3.375H16.3929C16.7268 3.375 17 3.12188 17 2.8125C17 2.50312 16.7268 2.25 16.3929 2.25H15.7857H14.5676H12.7842ZM14.4842 3.375L13.5203 15.8309C13.4748 16.418 12.9473 16.875 12.3098 16.875H4.69018C4.05268 16.875 3.52522 16.418 3.47969 15.8309L2.51964 3.375H14.4804H14.4842Z" />
                                        </svg>
                                        Delete
                                    </Button>
                                    <Button color="transparent" onClick={() => this.restoreSelected(true)}>
                                        <MaterialIcon path={mdiArrowExpandUp} className="MuiSvgIcon-root" />
                                        Restore
                                    </Button>
                                </div>
                            </GridItem>
                        </div>
                        <GridItem className={classes.tableGridItem}>
                            <div className={classes.filesContainer+" trash"}>
                                <div className={classes.files}>
                                    {
                                        loading ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                        null
                                    }
                                    <ReactTable
                                        columns={this.getColumns()}
                                        data={tableData}
                                        pageSizeOptions={[500]}
                                        defaultPageSize={limit}
                                        showPaginationTop={false}
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+ (loading ? classes.loading : "")+" "+classes.filesTable}
                                        getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                        getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                                        getNoDataProps={() => this.getNoDataProps()}
                                    />
                                    <div className={classes.loadMore}>
                                        {
                                            tableData.length > 0 && loadingMore === false && page < totalPages ?
                                                <Button color="custom" id="contentViewMore" onClick={(e) => this.loadLinks(true)}>
                                                    <RefreshIcon className={classes.icons} />View More
                                                </Button>
                                                
                                            :
                                                loadingMore === true ?
                                                    <LoaderComponent color="custom" align="center" />
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </GridItem>
                    </div>
                </div>
                {
                    deletelinkModal ?
                        <DeleteLinkModal deletedLinks={checkedLinks} open={deletelinkModal} onClose={() => this.onDeleteModalClose(false)} onSuccess={() => this.onDeleteModalSuccess()} />
                    :
                        <></>
                }
                {
                    restoreLinkModal ?
                        <RestoreLinkModal store={this.store} restoreLinks={checked} restoreLoading={restoreLoading} errorMessage={restoreLinkMessage ? restoreLinkMessage : ''} open={restoreLinkModal} onClose={(loadLinks) => this.onRestoreModalClose(loadLinks, false)} onSuccess={() => this.onRestoreModalSuccess()} />
                    :
                        <></>
                }
                {
                    attachmentsModal ?
                        <AttachmentsModal 
                            open={attachmentsModal} 
                            onClose={() => this.onAttachmentsModal(false)}
                            attachmentFiles={attachmentFiles}
                        />
                    :
                        <></>
                }
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserTrash.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(UserTrash);
