import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "../CustomInput/CustomInput.jsx";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader.js";

import newFileModalStyle from "../../../assets/jss/user/newFolderModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const CreateSecurityModal = class extends React.Component {
    constructor(props){
        super(props);

        const { security } = this.props;

        this.state = {
            name: security ? security.name : '',
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e, name) {
        this.setState({
            [name]: e.target.value
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.props;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    render() {
        const { classes, saving, error, securityRegistered, security } = this.props;
        const { name } = this.state;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="newFileModal-modal-slide-title"
                aria-describedby="newFileModal-modal-slide-description"
            >
                <DialogTitle
                    id="newFileModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            <Close className={classes.modalClose} />
                        </Button>
                        <h4 className={classes.modalTitle}>
                            {
                                security !== null ? 
                                    "Update Security Key"
                                :
                                    "Create Security Key"
                            }
                        </h4> 
                </DialogTitle>
                <DialogContent
                    id="newFileModal-modal-slide-description"
                    className={classes.modalBody+" "+(!securityRegistered ? 'empty-space': '')}
                >
                    {
                        error ?
                            this.renderErrorMessages()
                        :
                            <></>
                    }
                    {
                        securityRegistered ?
                            <CustomInput
                                labelText="Name"
                                id="input-name"
                                success={name.length > 0}
                                inputProps={{
                                    value: name,
                                    name: "name",
                                    type: "text",
                                    onChange: (e) => this.handleChange(e, 'name')
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: "custom-input m-zero"
                                }}
                            />
                        :
                            <></>
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    {
                        saving ?
                            <Button color="custom" >
                                <LoaderComponent color="white" align="center" saving={true} />
                            </Button>
                        : securityRegistered ? 
                            <Button color="custom" className={classes.changeButton} onClick={() => this.props.onSubmit(name)}>
                                {
                                    security !== null ?
                                        "Update"
                                    :
                                        "Submit"
                                }
                            </Button>
                        :
                            <Button color="custom" className={classes.changeButton} onClick={() => this.props.onRegister()}>
                                Create
                            </Button>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

CreateSecurityModal.defaultProps = {
    open: false,
    security: null,
    saving: false,
    fileName: "",
    extension: "",
    error: false,
    errorMessage: null,
    securityRegistered: false
}
CreateSecurityModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    saving: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    securityRegistered: PropTypes.bool,
}
export default withStyles(newFileModalStyle)(CreateSecurityModal);