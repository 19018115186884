import React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from '../../../assets/js/utils/Element';
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import LoaderComponent from "../../components/Loader";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteConfirmationModal from "../../components/User/DeleteConfirmationModal";
import ConfirmationModal from "../../components/User/ConfirmationModal";
import ChangeExpiryDateModal from "../../components/User/ChangeExpiryDateModal";
import ChangeLinkPasswordModal from "../../components/User/ChangeLinkPasswordModal";
import AttachmentsModal from  "../../components/User/AttachmentsModal";
import LinkDetailModal from  "../../components/User/LinkDetailModal";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import MaterialIcon from "@mdi/react";
import { mdiContentCopy, mdiDeleteOutline, mdiFileDocumentMultipleOutline, mdiDotsVertical } from '@mdi/js';
import SearchInput from "../../components/User/SearchInput";
import ProcessingModal from "../../components/User/ProcessingModal";
import ExternalLinks from "./ExternalLinks";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ReactTable from "react-table";
import withStyles from "@material-ui/core/styles/withStyles";
import linksPageStyle from "../../../assets/jss/user/linksPageStyle.jsx";
import Menu from '@material-ui/core/Menu';
import { MenuItem as MuiMenuItem } from '@material-ui/core';

const history = createBrowserHistory();

const UserLinks = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        let links = helper.getParam("links");
        if(!links || links === "null"){
            links = null;
        }else{
            links = links.split(",");
        }
        let files = helper.getParam("files");
        if(!files || files === "null"){
            files = null;
        }else{
            files = files.split(",");
        }
        let recipientEmail = helper.getParam("recipientEmail");
        if(!recipientEmail || recipientEmail === "null"){
            recipientEmail = null;
        }

        let viewType = 'summary';
        if(helper.detectMobile() || window.innerWidth <= 960){
            viewType = 'mobile';
        }
        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            changeLinkModal: false,
            changingLink: false,
            changingLinkSuccess: false,
            changeExpiryDateModal: false,
            changingExpiryDate: false,
            changeLinkPasswordModal: false,
            changingLinkPassword: false,
            transferId: null,
            expire: null,
            snackbarOpen: false,
            deleteLinkModal: false,
            deletingLink: false,
            filter: "",
            checked: [],
            links: links,
            files: files,
            attachmentsModal: false,
            attachmentFiles: [],
            viewType: viewType,
            searchFormData: this.defaultSearchFormData(),
            linkPassword: null,
            checkedAll: false,
            linkProcessingModal: false,
            linkDetailModal: false,
            linkDetail: null,
            activeTab: 0,
            viewPasswords: [],
            recipientEmail: recipientEmail,
            sourceMenuEl: null,
        };

        this.onChangeLinkModal = this.onChangeLinkModal.bind(this);
        this.onChangeLinkModalSuccess = this.onChangeLinkModalSuccess.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            if(user.level_id === 5){
                let redirectUrl = "/user/shared";
                this.history.push(redirectUrl);
            }

            const location = this.history.location;
            let searchFormData = null;
            if(location.state){
                searchFormData = location.state;
            }
            if(searchFormData !== null){
                this.onSearchInputSuccess(searchFormData.searchFormData);
            }else{
                this.loadLinks();
            }
        }

        window.addEventListener("resize", this.resizeFunction.bind(this));
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled');
            window.removeEventListener("resize", this.resizeFunction);
        }
    }
    resizeFunction(){
        if(helper.detectMobile() || window.innerWidth <= 960){
            this.setState({
                viewType: 'mobile'
            })
        }
    }
    defaultSearchFormData(){
        return {
            search: '',
            fileName: '',
            searchIn: '',
            attachmentContains: '',
            from: '',
            to: '',
            subject: '',
            destination: '',
            startDate: '',
            endDate: '',
            expiryStartDate: '',
            expiryEndDate: '',
            expired: false,
            notExpired: false,
            downloaded: false,
            notDownloaded: false,
            changedLinks: false
        }
    }
    clearSearch(){
        this.setState({
            searchFormData: this.defaultSearchFormData()
        }, () => {
            this.loadLinks()
        });
    }
    onSearchInputSuccess(searchData){
        const location = this.history.location;
        const pathname = location.pathname;

        if(pathname === '/user/links' && searchData.searchIn !== 'link-manager' && searchData.searchIn !== ''){
            this.history.push({
                pathname: '/user/home',
                search: '',
                state: { searchFormData: searchData }
            });
            return;
        }

        this.setState({
            searchFormData: {
                search: searchData.search,
                fileName: searchData.fileName,
                searchIn: searchData.searchIn,
                attachmentContains: searchData.attachmentContains,
                from: searchData.from,
                to: searchData.to,
                subject: searchData.subject,
                destination: searchData.destination,
                expired: searchData.expired,
                notExpired: searchData.notExpired,
                downloaded: searchData.downloaded,
                notDownloaded: searchData.notDownloaded,
                startDate: searchData.startDate,
                endDate: searchData.endDate,
                expiryStartDate:  searchData.expiryStartDate,
                expiryEndDate:  searchData.expiryEndDate,
            }
        }, () => {
            this.loadLinks();
        });
    }
    loadLinks(viewMore = false, filter = null){
        const source = axios.CancelToken.source();
        let { page, links, files, searchFormData, recipientEmail } = this.state;
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        if(filter === null){
            filter = this.state.filter;
        }

        let requestData = {
            page: page,
            limit: 200,
            filter: filter === 'all' ? '' : filter
        };
        let url = "/user/links?page="+page;
        if(links !== null){
            requestData['transfers'] = links;
            url += "&links="+links.join(",");
        }
        if(files !== null){
            requestData['files'] = files;
            url += "&files="+files.join(",");
        }
        if(recipientEmail !== null){
            requestData['to'] = recipientEmail;
            url += "&recipientEmail="+recipientEmail;
        }

        // search for request data
        if(searchFormData.search){
            requestData['search'] = searchFormData.search;
        }
        if(searchFormData.fileName){
            requestData['name'] = searchFormData.fileName;
        }
        if(searchFormData.from){
            requestData['from'] = searchFormData.from;
        }
        if(searchFormData.to){
            requestData['to'] = searchFormData.to;
        }
        if(searchFormData.subject){
            requestData['subject'] = searchFormData.subject;
        }
        if(searchFormData.destination){
            requestData['destination'] = searchFormData.destination;
        }
        if(searchFormData.expired){
            requestData['expired'] = searchFormData.expired;
        }
        if(searchFormData.notExpired){
            requestData['notExpired'] = searchFormData.notExpired;
        }
        if(searchFormData.downloaded){
            requestData['downloaded'] = searchFormData.downloaded;
        }
        if(searchFormData.notDownloaded){
            requestData['notDownloaded'] = searchFormData.notDownloaded;
        }
        if(searchFormData.startDate){
            requestData['startDate'] = searchFormData.startDate;
        }
        if(searchFormData.endDate){
            requestData['endDate'] = searchFormData.endDate;
        }
        if(searchFormData.expiryStartDate){
            requestData['expiryStartDate'] = searchFormData.expiryStartDate;
        }
        if(searchFormData.expiryEndDate){
            requestData['expiryEndDate'] = searchFormData.expiryEndDate;
        }

        history.push(url);
        Api.getLinks(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            changeLinkModal: false,
            changingLink: false,
            changingLinkSuccess: false,
            changeExpiryDateModal: false,
            changingExpiryDate: false,
            changeLinkPasswordModal: false,
            changingLinkPassword: false,
            deleteLinkModal: false,
            deletingLink: false,
            filter: filter,
            checked: [],
            checkedAll: false,
            linkDetailModal: false,
            sourceMenuEl: null
        });
    }
    onAttachmentsModal(status = false, files = []){
        this.setState({
            attachmentsModal: status,
            attachmentFiles: files
        })
    }
    getAttachmentsPlainText(link){
        let attachment = "";
        if(link.files.length > 2){
            attachment = (
                link.attachments
            )
        }else {
            attachment = (
                link.files.map((file, key) => {
                    return (
                        file.name
                    );
                })
            )
        }

        return attachment;
    }
    getAttachments(link, length = 2){
        let attachment = "";
        if(link.files.length > length){
            if(length === 0){   
                attachment = (
                    <Button justIcon color="transparent" onClick={() => this.onAttachmentsModal(true, link.files)} title={"Files"}>
                        <MaterialIcon path={mdiFileDocumentMultipleOutline} className="MuiSvgIcon-root" />
                    </Button>
                )
            }else{
                attachment = (
                    <Button simple onClick={() => this.onAttachmentsModal(true, link.files)}>{link.attachments}</Button>
                )
            }
        }else {
            attachment = (
                link.files.map((file, key) => {
                    return (
                        <Link href={file.downloadLink} target="_blank" key={key}>
                            {file.name}
                        </Link>
                    );
                })
            )
        }

        return attachment;
    }
    getFormatedExpiry(link){
        if(!link.expire_at || link.expire_at.length <= 0){
            return (
                <>
                    <Button color="transparent" className="text-button icon" onClick={() => this.onChangeExpiryDate(true, link.id, link.expire_at)}>
                        Add Expiry
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.1875 0C2.35938 0 2.5 0.140625 2.5 0.3125V1.25H6.25V0.3125C6.25 0.140625 6.39062 0 6.5625 0C6.73438 0 6.875 0.140625 6.875 0.3125V1.25H7.5C8.18945 1.25 8.75 1.81055 8.75 2.5V3.125V3.75V8.75C8.75 9.43945 8.18945 10 7.5 10H1.25C0.560547 10 0 9.43945 0 8.75V3.75V3.125V2.5C0 1.81055 0.560547 1.25 1.25 1.25H1.875V0.3125C1.875 0.140625 2.01562 0 2.1875 0ZM8.125 3.75H0.625V8.75C0.625 9.0957 0.904297 9.375 1.25 9.375H7.5C7.8457 9.375 8.125 9.0957 8.125 8.75V3.75ZM7.5 1.875H1.25C0.904297 1.875 0.625 2.1543 0.625 2.5V3.125H8.125V2.5C8.125 2.1543 7.8457 1.875 7.5 1.875ZM6.25 6.5625C6.25 6.73438 6.10938 6.875 5.9375 6.875H4.6875V8.125C4.6875 8.29688 4.54688 8.4375 4.375 8.4375C4.20312 8.4375 4.0625 8.29688 4.0625 8.125V6.875H2.8125C2.64062 6.875 2.5 6.73438 2.5 6.5625C2.5 6.39062 2.64062 6.25 2.8125 6.25H4.0625V5C4.0625 4.82812 4.20312 4.6875 4.375 4.6875C4.54688 4.6875 4.6875 4.82812 4.6875 5V6.25H5.9375C6.10938 6.25 6.25 6.39062 6.25 6.5625Z"/>
                        </svg>
                    </Button>
                </>
            );
        }
        return (
            <>
                { moment(link.expire_at).format("MM/DD/YYYY h:mm A") }
                <Button justIcon color="transparent" onClick={() => this.onChangeExpiryDate(true, link.id, link.expire_at)}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.08397 0.376953L6.13866 1.32227L8.67772 3.86133L9.62303 2.91602C10.1113 2.42773 10.1113 1.63672 9.62303 1.14844L8.8535 0.376953C8.36522 -0.111328 7.57421 -0.111328 7.08592 0.376953H7.08397ZM5.69725 1.76367L1.14452 6.31836C0.941393 6.52148 0.792955 6.77344 0.710924 7.04883L0.019518 9.39844C-0.0293102 9.56445 0.0156117 9.74219 0.136705 9.86328C0.257799 9.98438 0.435534 10.0293 0.599596 9.98242L2.94921 9.29102C3.2246 9.20899 3.47655 9.06055 3.67967 8.85742L8.23631 4.30273L5.69725 1.76367Z"/>
                    </svg>
                </Button>
            </>
        )
    }
    getLinkPassword(link){
        const { viewPasswords } = this.state;

        return (
            <>
                {
                    link.link_password ?
                        <div className="sd-password-field">
                            <div>
                                { 
                                    viewPasswords.includes(link.id) ?
                                        link.link_password
                                    :
                                        <div className="sd-passwords" dangerouslySetInnerHTML={{__html: this.convertStringToPass(link.link_password)}}></div>
                                }
                            </div>
                            <Button justIcon className="eye-icon" color="transparent" onClick={() => this.onViewPassword(link)} title={"View Password"}>
                                { 
                                    viewPasswords.includes(link.id) ?
                                        <VisibilityOffIcon />
                                    :
                                        <VisibilityIcon />
                                }
                            </Button>
                            <Button justIcon color="transparent" onClick={() => this.onChangeLinkPassword(true, link.id, link.link_password)} title={"Link Password"}>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.08397 0.376953L6.13866 1.32227L8.67772 3.86133L9.62303 2.91602C10.1113 2.42773 10.1113 1.63672 9.62303 1.14844L8.8535 0.376953C8.36522 -0.111328 7.57421 -0.111328 7.08592 0.376953H7.08397ZM5.69725 1.76367L1.14452 6.31836C0.941393 6.52148 0.792955 6.77344 0.710924 7.04883L0.019518 9.39844C-0.0293102 9.56445 0.0156117 9.74219 0.136705 9.86328C0.257799 9.98438 0.435534 10.0293 0.599596 9.98242L2.94921 9.29102C3.2246 9.20899 3.47655 9.06055 3.67967 8.85742L8.23631 4.30273L5.69725 1.76367Z"/>
                                </svg>
                            </Button>
                        </div>
                    :
                        <Button className="text-button icon" color="transparent" onClick={() => this.onChangeLinkPassword(true, link.id, link.link_password)} title={"Link Password"}>
                            Add Password
                            <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.5 2.5V3.75H6.25V2.5C6.25 1.46484 5.41016 0.625 4.375 0.625C3.33984 0.625 2.5 1.46484 2.5 2.5ZM1.875 3.75V2.5C1.875 1.11914 2.99414 0 4.375 0C5.75586 0 6.875 1.11914 6.875 2.5V3.75H7.1875C8.05078 3.75 8.75 4.44922 8.75 5.3125V8.4375C8.75 9.30078 8.05078 10 7.1875 10H1.5625C0.699219 10 0 9.30078 0 8.4375V5.3125C0 4.44922 0.699219 3.75 1.5625 3.75H1.875ZM0.625 5.3125V8.4375C0.625 8.95508 1.04492 9.375 1.5625 9.375H7.1875C7.70508 9.375 8.125 8.95508 8.125 8.4375V5.3125C8.125 4.79492 7.70508 4.375 7.1875 4.375H1.5625C1.04492 4.375 0.625 4.79492 0.625 5.3125Z"/>
                            </svg>
                        </Button>
                }
            </>
        );
    }
    onExpandTableRow(){
        console.log("test");
    }
    getFormatedDownloadDate(date){
        if(!date || date.length <= 0){
            return "";
        }
        return moment(date).format("MM/DD/YYYY h:mm A");
    }
    onViewPassword(link){
        const { viewPasswords } = this.state;
        const currentIndex = viewPasswords.indexOf(link.id);
        const newViewPasswords = [...viewPasswords];
    
        if (currentIndex === -1) {
            newViewPasswords.push(link.id);
        } else {
            newViewPasswords.splice(currentIndex, 1);
        }

        this.setState({
            viewPasswords: newViewPasswords
        });
    }
    convertStringToPass(password){
        let string = "";
        for (let i = 0; i < password.length; i++) {
            string +="<span></span>";
        }

        return string;
    }
    getColumns(){
        const { classes } = this.props;
        const { checkedAll } = this.state;

        let columns = [
            {
                Header: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checkedAll}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        onChange={(e) => this.onToggleAllLinks(e)}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                accessor: "check",
                sortable: false,
                filterable: false,
                resizable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
            },
            {
                Header: "Name/Recipient",
                accessor: "recipients",
                headerClassName: "hd_recipients",
                className: "hd_recipients td_recipients",
            },
            {
                Header: "Date",
                accessor: "created_at",
                headerClassName: "hd_created",
                className: "hd_created td_created",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Attachment(s)",
                accessor: "attachmentsPlainText",
                headerClassName: "hd_attachments hd_show_head",
                className: "hd_attachments td_attachments td_hide",
                resizable: false
            },
            {
                Header: "Attachment(s)",
                accessor: "attachments",
                headerClassName: "hd_attachments hd_hide",
                className: "hd_attachments td_attachments",
            },
            {
                Header: "Password",
                accessor: "link_password",
                headerClassName: "hd_link_password",
                className: "hd_link_password td_link_password",
            },
            {
                Header: "Expiry",
                accessor: "expire_at",
                headerClassName: "hd_expire",
                className: "hd_expire td_expire",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "",
                accessor: "actions",
                headerClassName: "hd_actions",
                className: "hd_actions td_actions",
                sortable: false,
            },
        ];

        return columns;
    }
    getTableData(){
        const { response, checked } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        const { classes } = this.props;
        response.map(link => {
            let recipents = link.recipients.join(", ");
            if(link.title !== '' && link.title && link.recipients.length > 0){
                recipents = link.title+", "+link.recipients.join(", ");
            }else if(link.title !== '' && link.title){
                recipents = link.title;
            }

            let fileArray = {
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(link.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(link)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                type: this.getType(link),
                account: link.account,
                server: "Sendlinx",
                id: link.id,
                sender_email_orignal: link.sender_email,
                sender_email: (
                    <div title={ link.sender_email }>
                        { link.sender_email }
                    </div>
                ),
                recipients: (
                    <div title={recipents}>
                        { recipents }
                    </div>
                ),
                message: (
                    <div dangerouslySetInnerHTML={{__html: link.message}}></div>
                ),
                subject: link.subject,
                created_at: link.created_at,
                attachmentsPlainText: this.getAttachmentsPlainText(link),
                attachments: this.getAttachments(link),
                attachmentsMobile: this.getAttachments(link, 0),
                expire_at: (
                    <div className={(link.status === 'expire' ? 'expire-link' : 'active-link')}>
                        {this.getFormatedExpiry(link)}
                    </div>
                ),
                last_downloaded: this.getFormatedDownloadDate(link.download_date),
                status: (
                    link.state === 'uploaded' || link.state === 'processing' ?
                        <p className='orange'>
                            Processing ...
                        </p>
                    :
                        <p>
                            Ready
                        </p>
                ),
                webLink: (
                    <Button justIcon color="transparent" onClick={() => this.copyDownloadLink(link.webLink, link.state)} title={"Copy Link"}>
                        <MaterialIcon path={mdiContentCopy} className="MuiSvgIcon-root" />
                    </Button>
                ),
                action: (
                    <Button justIcon color="transparent" onClick={() => this.onChangeLinkModal(true, link.id, link.state)} title={"Change Link"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className='change-link' width="28" height="28" fill="none" viewBox="0 0 16 16">
                            <path d="M9.49999 3H10.5C12.433 3 14 4.567 14 6.5 14 6.80964 13.9598 7.10989 13.8843 7.39581 13.5943 7.20702 13.2732 7.08576 12.9433 7.03204 12.9804 6.86059 13 6.68258 13 6.5 13 5.17452 11.9685 4.08996 10.6644 4.00532L10.5 4H9.49999C9.22386 4 8.99999 3.77614 8.99999 3.5 8.99999 3.25454 9.17687 3.05039 9.41012 3.00806L9.49999 3zM5.5 3H6.5C6.77614 3 7 3.22386 7 3.5 7 3.74546 6.82312 3.94961 6.58988 3.99194L6.5 4H5.5C4.11929 4 3 5.11929 3 6.5 3 7.82548 4.03154 8.91004 5.33562 8.99468L5.5 9H6.5C6.77614 9 7 9.22386 7 9.5 7 9.74546 6.82312 9.94961 6.58988 9.99194L6.5 10H5.5C3.567 10 2 8.433 2 6.5 2 4.63144 3.46428 3.10487 5.30796 3.00518L5.5 3z"/>
                            <path d="M5.50023 6L10.5002 6.0023C10.7764 6.00242 11.0001 6.22638 11 6.50252 10.9999 6.74798 10.8229 6.95205 10.5897 6.99428L10.4998 7.0023 5.49977 7C5.22363 6.99987 4.99987 6.77591 5 6.49977 5.00011 6.25431 5.17708 6.05024 5.41035 6.00801L5.50023 6zM11.5183 8.42604C12.0863 7.85799 13.0073 7.85799 13.5754 8.42604 14.1434 8.99409 14.1434 9.91508 13.5754 10.4831L9.81912 14.2394C9.60012 14.4584 9.32571 14.6137 9.02524 14.6889L7.8602 14.9801C7.35355 15.1068 6.89462 14.6478 7.02128 14.1412L7.31254 12.9762C7.38766 12.6757 7.54303 12.4013 7.76203 12.1823L11.5183 8.42604zM12.2254 9.13314L8.46914 12.8894C8.37829 12.9802 8.31385 13.0941 8.28269 13.2187L8.11601 13.8854 8.78271 13.7187C8.90734 13.6875 9.02117 13.6231 9.11202 13.5323L12.8683 9.77602C13.0458 9.5985 13.0458 9.31067 12.8683 9.13314 12.6907 8.95562 12.4029 8.95562 12.2254 9.13314z"/>
                        </svg>
                    </Button>
                ),
                delete: (
                    <Button justIcon color="transparent" onClick={() => this.onDeleteLinkModal(true, link.id)} title={"Delete"}>
                        <MaterialIcon path={mdiDeleteOutline} className="MuiSvgIcon-root" />
                    </Button>
                ),
                orignalPath: link.path,
                path: (
                    <div title={ link.path }>
                        { link.path }
                    </div>
                ),
                title: link.title,
                link_password: this.getLinkPassword(link),
                three_dot: (
                    <Button justIcon color="transparent" onClick={() => this.onLinkDetailModal(true, link)} title={""}>
                        <MaterialIcon path={mdiDotsVertical} className="MuiSvgIcon-root" />
                    </Button>
                ),
                shared_via: (
                    link.shared_via && link.shared_via !== '' ?
                        <div title={link.shared_via}>
                            { link.shared_via }
                        </div>
                    :
                        "N/A"
                ),
                actions: (
                    <div>
                        <Button className={"text-button"} color="custom" onClick={() => this.onLinkDetailModal(true, link)} title={""}>
                            View Details
                        </Button>
                        <Button justIcon color="transparent" onClick={() => this.copyDownloadLink(link.webLink, link.state)} title={"Copy Link"}>
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 13.75H8.75C8.05859 13.75 7.5 13.1914 7.5 12.5V2.5C7.5 1.80859 8.05859 1.25 8.75 1.25H12.9727C13.1367 1.25 13.2969 1.31641 13.4141 1.43359L16.0664 4.08594C16.1836 4.20312 16.25 4.36328 16.25 4.52734V12.5C16.25 13.1914 15.6914 13.75 15 13.75ZM16.9492 3.19922L14.3008 0.550781C13.9492 0.199219 13.4727 0 12.9766 0H8.75C7.37109 0 6.25 1.12109 6.25 2.5V12.5C6.25 13.8789 7.37109 15 8.75 15H15C16.3789 15 17.5 13.8789 17.5 12.5V4.52734C17.5 4.03125 17.3008 3.55469 16.9492 3.20312V3.19922ZM2.5 5C1.12109 5 0 6.12109 0 7.5V17.5C0 18.8789 1.12109 20 2.5 20H8.75C10.1289 20 11.25 18.8789 11.25 17.5V16.25H10V17.5C10 18.1914 9.44141 18.75 8.75 18.75H2.5C1.80859 18.75 1.25 18.1914 1.25 17.5V7.5C1.25 6.80859 1.80859 6.25 2.5 6.25H5V5H2.5Z" fill="#556DC2"/>
                            </svg>
                        </Button>
                        <Button justIcon color="transparent" onClick={() => this.onChangeLinkModal(true, link.id, link.state)} title={"Change Link"}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71094 7.22656C2.50781 3.94141 5.46875 1.5 9 1.5C11.7773 1.5 14.1992 3.00781 15.4961 5.25H13.375C13.0312 5.25 12.75 5.53125 12.75 5.875C12.75 6.21875 13.0312 6.5 13.375 6.5H16.5273H17.125C17.4688 6.5 17.75 6.21875 17.75 5.875V2.125C17.75 1.78125 17.4688 1.5 17.125 1.5C16.7812 1.5 16.5 1.78125 16.5 2.125V4.49219C14.9687 1.94922 12.1836 0.25 9 0.25C4.84375 0.25 1.36328 3.14844 0.472656 7.03125C0.386719 7.40625 0.683594 7.75 1.07031 7.75C1.37891 7.75 1.64062 7.52734 1.71484 7.22656H1.71094ZM16.2891 10.7773C15.4922 14.0586 12.5312 16.5 9 16.5C6.22266 16.5 3.80078 14.9922 2.50391 12.75H4.63281C4.97656 12.75 5.25781 12.4688 5.25781 12.125C5.25781 11.7812 4.97656 11.5 4.63281 11.5H1.47266H0.882812C0.539062 11.5 0.257812 11.7812 0.257812 12.125V15.875C0.257812 16.2188 0.539062 16.5 0.882812 16.5C1.22656 16.5 1.50781 16.2188 1.50781 15.875V13.5234C3.03906 16.0547 5.82422 17.75 9 17.75C13.1562 17.75 16.6367 14.8516 17.5273 10.9688C17.6133 10.5938 17.3164 10.25 16.9297 10.25C16.6211 10.25 16.3594 10.4727 16.2852 10.7734L16.2891 10.7773Z" fill="#556DC2"/>
                            </svg>
                        </Button>
                        <Button justIcon color="transparent" onClick={() => this.onDeleteLinkModal(true, link.id)} title={"Delete"}>
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.94141 1.25H10.5547C10.7695 1.25 10.9688 1.35938 11.0859 1.54297L11.6836 2.5H5.81641L6.41406 1.54297C6.52734 1.35938 6.73047 1.25 6.94531 1.25H6.94141ZM13.1602 2.5L12.1484 0.882812C11.8047 0.332031 11.2031 0 10.5586 0H6.94141C6.29688 0 5.69531 0.332031 5.35156 0.882812L4.33984 2.5H2.50391H1.25H0.625C0.28125 2.5 0 2.78125 0 3.125C0 3.46875 0.28125 3.75 0.625 3.75H1.33984L2.33594 17.6797C2.42578 18.9883 3.51562 20 4.82812 20H12.6719C13.9844 20 15.0703 18.9883 15.1641 17.6797L16.1602 3.75H16.875C17.2188 3.75 17.5 3.46875 17.5 3.125C17.5 2.78125 17.2188 2.5 16.875 2.5H16.25H14.9961H13.1602ZM14.9102 3.75L13.918 17.5898C13.8711 18.2422 13.3281 18.75 12.6719 18.75H4.82812C4.17188 18.75 3.62891 18.2422 3.58203 17.5898L2.59375 3.75H14.9062H14.9102Z" fill="#556DC2"/>
                            </svg>
                        </Button>
                    </div>
                )
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    handleToggle(link) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(link.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(link.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked,
        });
    }
    onToggleAllLinks(e){
        const checkedLinks = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((file, key) => {
                checkedLinks.push(file.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedLinks
        });
    }
    onChangeLinkModal(status = false, transferId = null, state = null){
        const { checked, changingLinkSuccess } = this.state;
        if(checked.length <= 0 && transferId === null && status === true){
            return;
        }
        if(state === 'uploaded' || state === 'processing'){
            this.setState({
                linkProcessingModal: true
            });
            
            return;
        }
        
        this.setState({
            changeLinkModal: status,
            transferId: transferId
        }, () => {
            if(status ===  false && changingLinkSuccess){
                this.loadLinks();
            }
        });
    }
    onChangeLinkModalSuccess(){
        let { transferId, checked } = this.state;
        let links = [transferId];
        if(transferId === null){
            links = checked;
        }
        const source = axios.CancelToken.source();
        this.setState({
            changingLink: true,
            cancelToken: source,
        });

        Api.changeLink(transferId, {files: links}, source).then(data => {
            this.setState({
                changingLink: false,
                changingLinkSuccess: true,
            })
        }).catch(err => {
            console.log(err);
        });
    }
    onChangeExpiryDate(status = false, transferId = null, expire = null){
        const { checked } = this.state;
        if(checked.length <= 0 && transferId === null && status === true){
            return;
        }
        this.setState({
            changeExpiryDateModal: status,
            transferId: transferId,
            expire: expire
        });
    }
    onChangeExpiryDateSuccess(expire){
        let { transferId, checked } = this.state;
        let links = [transferId];
        if(transferId === null){
            links = checked;
        }
        const source = axios.CancelToken.source();
        this.setState({
            changingExpiryDate: true,
            cancelToken: source,
        });

        let orignalExpire = expire;
        const parsedDate = new Date(expire);

        if(expire !== '' && parsedDate !== "Invalid Date"){
           const day = parsedDate.getDate();
            const month = parsedDate.getMonth() + 1;
            const year = parsedDate.getFullYear();
            const hours = parsedDate.getHours();
            const mins = parsedDate.getMinutes();
            const secs = parsedDate.getSeconds();
            expire = day+"-"+month+"-"+year+" "+hours+":"+mins+":"+secs;
        }

        const requestData = {
            expire: expire,
            files: links
        };

        Api.changeExpiryDate(transferId, requestData, source).then(data => {
            if(this.state.linkDetail !== null){
                this.setState({
                    linkDetail: {
                        ...this.state.linkDetail,
                        expire_at:  orignalExpire
                    }
                })
            }

            this.loadLinks();
        }).catch(err => {
            console.log(err);
        });
    }
    onChangeLinkPassword(status = false, transferId = null, linkPassword = null){
        const { checked } = this.state;
        if(checked.length <= 0 && transferId === null && status === true){
            return;
        }
        this.setState({
            changeLinkPasswordModal: status,
            transferId: transferId,
            linkPassword: linkPassword
        });
    }
    onChangeLinkPasswordSuccess(linkPassword){
        let { transferId, checked } = this.state;
        let links = [transferId];
        if(transferId === null){
            links = checked;
        }
        const source = axios.CancelToken.source();
        this.setState({
            changingLinkPassword: true,
            cancelToken: source,
        });

        const requestData = {
            linkPassword: linkPassword,
            files: links
        };

        Api.changeLinkPassword(transferId, requestData, source).then(data => {
            if(this.state.linkDetail !== null){
                this.setState({
                    linkDetail: {
                        ...this.state.linkDetail,
                        link_password:  linkPassword
                    }
                })
            }

            this.loadLinks();
        }).catch(err => {
            console.log(err);
        });
    }
    copyDownloadLink(link, state){
        if(state === 'uploaded' || state === 'processing'){
            this.setState({
                linkProcessingModal: true
            });
            
            return;
        }
        helper.copyTextToClipboard(link);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    onDeleteLinkModal(status = false, transferId = null){
        const { checked } = this.state;
        if(checked.length <= 0 && transferId === null && status === true){
            return;
        }
        this.setState({
            deleteLinkModal: status,
            transferId: transferId
        });
    }
    onDeleteLinkModalSuccess(){
        const { transferId, checked } = this.state;
        let links = [transferId];
        if(transferId === null){
            links = checked;
        }
        const source = axios.CancelToken.source();
        this.setState({
            deletingLink: true,
            cancelToken: source,
        });
        const requestData = {
            links: links
        }

        Api.deleteLink(requestData, source).then(data => {
            this.loadLinks();
        }).catch(err => {
            console.log(err);
        });
    }
    getType(link){
        let label = "Direct Upload";
        if(link.upload_type.length <= 0){
            link.upload_type = link.type;
        }
        switch(link.upload_type){
            case 'web':
                label = 'Web';
                break;
            case 'my_docs':
                label = 'My Docs';
                break;
            case 'direct_upload':
                label = 'Direct Upload';
                break;
            case 'outlook':
                label = 'Outlook';
                break;
            case 'desktop':
                label = 'Desktop';
                break;
            case 'mobile':
                label = 'Mobile';
                break;
            // case 'share':
            //     label = 'Shared';
            //     break;
            // case 'manager_upload':
            //     label = 'File Manager Upload';
            //     break;
            default:
                if(link.path.indexOf("Web")){
                    label = "Web";
                }else if(link.path.indexOf("Outlook")){
                    label = "Outlook";
                }else if(link.path.indexOf("My Docs")){
                    label = "Desktop";
                }
                break;
        }
        return label;
    }
    onChangeView(type){ 
        this.setState({
            viewType: type
        })
    }
    onLinkProcessingModalSuccess(){
        this.setState({
            linkProcessingModal: false,
        });
    }
    onLinkDetailModal(status = false, linkDetail = null){
        const { checked } = this.state;
        if(checked.length <= 0 && linkDetail === null && status === true){
            return;
        }
        this.setState({
            linkDetailModal: status,
            linkDetail: linkDetail,
        });
    }
    onTabChange(activeTab){
        if(activeTab === '1'){
            this.history.push('/user/external-links');
            return;
        }
        this.setState({
            activeTab: parseInt(activeTab)
        });
    }
    getTrProps(state, rowInfo){
        return {}
    }
    getTrGroupProps(state, rowInfo){
        const { checked } = this.state;
        if (rowInfo && rowInfo.row) {
            if(checked.indexOf(rowInfo.original.id) !== -1){
                return {
                    className: 'selected_row'
                }
            }
            return {
                className: (rowInfo.original.id === 0 ? 'frozen_row' : '')
            }
        }else{
            return {}
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    onCloseSourceMenu(status = false){
        this.setState({
            sourceMenuEl: null,
        });
    }
    onSourceMenu(event){
        this.setState({
            sourceMenuEl: event.currentTarget
        });
    }
    renderSourceMenu(){
        const { sourceMenuEl } = this.state;
        const { classes } = this.props;

        return (
            <Menu
                id="source-menu"
                anchorEl={sourceMenuEl}
                keepMounted
                open={Boolean(sourceMenuEl)}
                onClose={() => this.onCloseSourceMenu()}
                className={classes.createMenu}
                PaperProps={{
                    style: {
                      marginTop: '40px',
                    },
                }}
            >
                <MuiMenuItem onClick={() => this.loadLinks(false, "all")} className='folder-menu'>
                    All Links
                </MuiMenuItem>
                <MuiMenuItem onClick={() => this.loadLinks(false, "mydocs")} className='folder-menu'>
                    My Docs
                </MuiMenuItem>
                <MuiMenuItem onClick={() => this.loadLinks(false, "outlook")} className='folder-menu'>
                    Outlook
                </MuiMenuItem>
                <MuiMenuItem onClick={() => this.loadLinks(false, "web")} className='folder-menu'>
                    Web
                </MuiMenuItem>
            </Menu>
        )
    }
    getSourceMenuText(){
        const { filter } = this.state;

        let text = 'source';
        if(filter === 'mydocs'){
            text = 'My Docs';
        }else if(filter === 'outlook'){
            text = 'Outlook'
        }else if(filter === 'web'){
            text = 'Web'
        }else if(filter === 'all'){
            text = 'All Links'
        }

        return text;
    }
    render() {
        const { classes } = this.props;
        const { limit, loading, loadingMore, page, totalPages, changeLinkModal, changingLink, changingLinkSuccess, 
            changeExpiryDateModal, changingExpiryDate, expire, deleteLinkModal, deletingLink, checked,
             attachmentsModal, attachmentFiles, searchFormData, changeLinkPasswordModal, changingLinkPassword, 
             linkPassword, linkProcessingModal, linkDetailModal, linkDetail, activeTab } = this.state;
        const { authorized } = this.store.getState();

        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        const tableData = this.getTableData();
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content+" content-section"}>
                        <div className={classes.controls}>
                            <GridItem className={classes.controlsTabs}>
                                <GridContainer className={classes.alignItems}>
                                    <GridItem xs={12} md={8}>
                                        <div className='sd-custom-tabs'>
                                            <div className={'sd-custom-tab '+(activeTab === 0 ? 'active': '')} onClick={() => this.onTabChange("0")}>
                                                <span>Internal Links</span>
                                            </div>
                                            <div className={'sd-custom-tab '+(activeTab === 1 ? 'active': '')} onClick={() => this.onTabChange("1")}>
                                                <span>External Links</span>
                                            </div>
                                        </div>
                                    </GridItem>
                                    {
                                        activeTab === 0 ?
                                            <GridItem xs={12} md={4}>                                
                                                <SearchInput
                                                    searchFormData={searchFormData}
                                                    onSuccess={(searchData) => this.onSearchInputSuccess(searchData)}
                                                    clearSearch={() => this.clearSearch()}
                                                    history={this.history}
                                                    store={this.store}
                                                    inputProps={{
                                                        placeholder: "Type File Name(s) or Destination"
                                                    }}
                                                />
                                            </GridItem>
                                        :
                                        null
                                    }
                                </GridContainer>
                            </GridItem>
                        </div>
                        {
                            activeTab === 0 ?
                                <GridItem className={classes.actionsContent}>
                                    <Button className="source" color="transparent" aria-controls="source-menu" aria-haspopup="true" onClick={(event) => this.onSourceMenu(event)}>
                                        <svg className='left-icon' width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.692446 0.34375C0.692446 0.154687 0.536646 0 0.346223 0C0.1558 0 0 0.154687 0 0.34375V2.40625V7.90625C0 8.47559 0.465237 8.9375 1.03867 8.9375H5.53957V8.25H1.03867C0.848246 8.25 0.692446 8.09531 0.692446 7.90625V2.75H5.53957V2.0625H0.692446V0.34375ZM8.54089 0.6875L9.01045 1.15371C9.15327 1.29551 9.34802 1.375 9.54926 1.375H11.7716V4.125H6.92446V0.6875H8.54089ZM6.23201 0.6875V4.125C6.23201 4.50527 6.54145 4.8125 6.92446 4.8125H11.7716C12.1546 4.8125 12.464 4.50527 12.464 4.125V1.375C12.464 0.994727 12.1546 0.6875 11.7716 0.6875H9.54926C9.53195 0.6875 9.51248 0.681055 9.49949 0.668164L9.03209 0.201953C8.90226 0.0730469 8.72698 0 8.54305 0H6.92446C6.54145 0 6.23201 0.307227 6.23201 0.6875ZM6.92446 6.875H8.54089L9.01045 7.34121C9.15327 7.48301 9.34802 7.5625 9.54926 7.5625H11.7716V10.3125H6.92446V6.875ZM6.23201 6.875V10.3125C6.23201 10.6928 6.54145 11 6.92446 11H11.7716C12.1546 11 12.464 10.6928 12.464 10.3125V7.5625C12.464 7.18223 12.1546 6.875 11.7716 6.875H9.54926C9.53195 6.875 9.51248 6.86855 9.49949 6.85566L9.02993 6.38945C8.90009 6.26055 8.72482 6.1875 8.54089 6.1875H6.92446C6.54145 6.1875 6.23201 6.49473 6.23201 6.875Z"/>
                                        </svg>
                                        {this.getSourceMenuText()}
                                        <svg className='right-icon' width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.95387 5.78032C4.70283 6.07323 4.29516 6.07323 4.04413 5.78032L0.188273 1.28119C-0.0627584 0.988283 -0.0627584 0.512595 0.188273 0.219684C0.439305 -0.0732279 0.846982 -0.0732279 1.09801 0.219684L4.5 4.18922L7.90199 0.222027C8.15302 -0.0708846 8.56069 -0.0708846 8.81173 0.222027C9.06276 0.514938 9.06276 0.990627 8.81173 1.28354L4.95587 5.78266L4.95387 5.78032Z"/>
                                        </svg>
                                    </Button>
                                    {this.renderSourceMenu()}
                                    {
                                        checked.length > 0 ?   
                                            <>
                                                <Button color="custom" onClick={() => this.onChangeLinkModal(true)}>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.71094 7.22656C2.50781 3.94141 5.46875 1.5 9 1.5C11.7773 1.5 14.1992 3.00781 15.4961 5.25H13.375C13.0312 5.25 12.75 5.53125 12.75 5.875C12.75 6.21875 13.0312 6.5 13.375 6.5H16.5273H17.125C17.4688 6.5 17.75 6.21875 17.75 5.875V2.125C17.75 1.78125 17.4688 1.5 17.125 1.5C16.7812 1.5 16.5 1.78125 16.5 2.125V4.49219C14.9687 1.94922 12.1836 0.25 9 0.25C4.84375 0.25 1.36328 3.14844 0.472656 7.03125C0.386719 7.40625 0.683594 7.75 1.07031 7.75C1.37891 7.75 1.64062 7.52734 1.71484 7.22656H1.71094ZM16.2891 10.7773C15.4922 14.0586 12.5312 16.5 9 16.5C6.22266 16.5 3.80078 14.9922 2.50391 12.75H4.63281C4.97656 12.75 5.25781 12.4688 5.25781 12.125C5.25781 11.7812 4.97656 11.5 4.63281 11.5H1.47266H0.882812C0.539062 11.5 0.257812 11.7812 0.257812 12.125V15.875C0.257812 16.2188 0.539062 16.5 0.882812 16.5C1.22656 16.5 1.50781 16.2188 1.50781 15.875V13.5234C3.03906 16.0547 5.82422 17.75 9 17.75C13.1562 17.75 16.6367 14.8516 17.5273 10.9688C17.6133 10.5938 17.3164 10.25 16.9297 10.25C16.6211 10.25 16.3594 10.4727 16.2852 10.7734L16.2891 10.7773Z"/>
                                                    </svg>
                                                    <span>Change Link</span>
                                                </Button>
                                                <Button color="custom" onClick={() => this.onChangeExpiryDate(true)}>
                                                    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.1875 0C2.35938 0 2.5 0.140625 2.5 0.3125V1.25H6.25V0.3125C6.25 0.140625 6.39062 0 6.5625 0C6.73438 0 6.875 0.140625 6.875 0.3125V1.25H7.5C8.18945 1.25 8.75 1.81055 8.75 2.5V3.125V3.75V8.75C8.75 9.43945 8.18945 10 7.5 10H1.25C0.560547 10 0 9.43945 0 8.75V3.75V3.125V2.5C0 1.81055 0.560547 1.25 1.25 1.25H1.875V0.3125C1.875 0.140625 2.01562 0 2.1875 0ZM8.125 3.75H0.625V8.75C0.625 9.0957 0.904297 9.375 1.25 9.375H7.5C7.8457 9.375 8.125 9.0957 8.125 8.75V3.75ZM7.5 1.875H1.25C0.904297 1.875 0.625 2.1543 0.625 2.5V3.125H8.125V2.5C8.125 2.1543 7.8457 1.875 7.5 1.875ZM6.25 6.5625C6.25 6.73438 6.10938 6.875 5.9375 6.875H4.6875V8.125C4.6875 8.29688 4.54688 8.4375 4.375 8.4375C4.20312 8.4375 4.0625 8.29688 4.0625 8.125V6.875H2.8125C2.64062 6.875 2.5 6.73438 2.5 6.5625C2.5 6.39062 2.64062 6.25 2.8125 6.25H4.0625V5C4.0625 4.82812 4.20312 4.6875 4.375 4.6875C4.54688 4.6875 4.6875 4.82812 4.6875 5V6.25H5.9375C6.10938 6.25 6.25 6.39062 6.25 6.5625Z"/>
                                                    </svg>
                                                    <span>Change Expiry</span>
                                                </Button>
                                                <Button color="custom" onClick={() => this.onDeleteLinkModal(true)}>
                                                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.94141 1.25H10.5547C10.7695 1.25 10.9688 1.35938 11.0859 1.54297L11.6836 2.5H5.81641L6.41406 1.54297C6.52734 1.35938 6.73047 1.25 6.94531 1.25H6.94141ZM13.1602 2.5L12.1484 0.882812C11.8047 0.332031 11.2031 0 10.5586 0H6.94141C6.29688 0 5.69531 0.332031 5.35156 0.882812L4.33984 2.5H2.50391H1.25H0.625C0.28125 2.5 0 2.78125 0 3.125C0 3.46875 0.28125 3.75 0.625 3.75H1.33984L2.33594 17.6797C2.42578 18.9883 3.51562 20 4.82812 20H12.6719C13.9844 20 15.0703 18.9883 15.1641 17.6797L16.1602 3.75H16.875C17.2188 3.75 17.5 3.46875 17.5 3.125C17.5 2.78125 17.2188 2.5 16.875 2.5H16.25H14.9961H13.1602ZM14.9102 3.75L13.918 17.5898C13.8711 18.2422 13.3281 18.75 12.6719 18.75H4.82812C4.17188 18.75 3.62891 18.2422 3.58203 17.5898L2.59375 3.75H14.9062H14.9102Z"/>
                                                    </svg>
                                                    <span>Delete</span>
                                                </Button>
                                            </>
                                        :
                                            <></>
                                    }
                                </GridItem>
                            :
                                <></>
                        }
                        {
                            activeTab === 0 ?
                                <GridItem className={classes.tableGridItem}>
                                    <div className={classes.filesContainer}>
                                        <div className={classes.files}>
                                            {
                                                loading ?
                                                    <LoaderComponent color="custom" align="center" />
                                                :
                                                    null
                                            }
                                            <div className={(loading ? classes.loading : "")}>
                                                <ReactTable
                                                    columns={this.getColumns()}
                                                    data={tableData}
                                                    pageSizeOptions={[500]}
                                                    defaultPageSize={limit}
                                                    showPaginationTop={false}
                                                    minRows={0}
                                                    showPaginationBottom={false}
                                                    className={"-striped -highlight desktop-view-table "+classes.filesTable}
                                                    getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                                    getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                                                    getNoDataProps={() => this.getNoDataProps()}
                                                />
                                            </div>
                                            <div className={classes.loadMore}>
                                                {
                                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                                        <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadLinks(true)}>
                                                            <RefreshIcon className={classes.icons} />View More
                                                        </Button>
                                                        
                                                    :
                                                        loadingMore === true ?
                                                            <LoaderComponent color="custom" align="center" />
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </GridItem>
                            :
                                <ExternalLinks sidebar={false} store={this.store} history={this.history} />
                        }
                    </div>
                    {
                        changeLinkModal ?
                            <ConfirmationModal 
                                open={changeLinkModal} 
                                saving={changingLink} 
                                onClose={() => this.onChangeLinkModal(false)} 
                                onSuccess={() => this.onChangeLinkModalSuccess()}
                                confirmationMessage="Are you sure you want to change link?"
                                successMessage="Link Changed Successfully"
                                success={changingLinkSuccess}
                            />
                        :
                        <></>
                    }
                    {
                        deleteLinkModal ?
                            <DeleteConfirmationModal 
                                deleteNote={true} 
                                open={deleteLinkModal} 
                                saving={deletingLink} 
                                onClose={() => this.onDeleteLinkModal(false)} 
                                onSuccess={() => this.onDeleteLinkModalSuccess()}
                                confirmationMessage="Are you sure you want to delete link?"
                                checked={checked}
                            />
                        :
                        <></>
                    }
                    {
                        attachmentsModal ?
                            <AttachmentsModal 
                                open={attachmentsModal} 
                                onClose={() => this.onAttachmentsModal(false)}
                                attachmentFiles={attachmentFiles}
                            />
                        :
                            <></>
                    }
                    {
                        linkDetailModal ?
                            <LinkDetailModal 
                                open={linkDetailModal} 
                                onClose={() => this.onLinkDetailModal(false)}
                                linkDetail={linkDetail}
                                onChangeLinkPassword={(status, transferId, linkPassword) => this.onChangeLinkPassword(status, transferId, linkPassword)}
                                onChangeExpiryDate={(status, transferId, expire) => this.onChangeExpiryDate(status, transferId, expire)}
                                onChangeLink={(status, transferId) => this.onChangeLinkModal(status, transferId)}
                                onDeleteLink={(status, transferId) => this.onDeleteLinkModal(status, transferId)}
                            />
                        :
                            <></>
                    }
                    {
                        changeExpiryDateModal ?
                            <ChangeExpiryDateModal 
                                open={changeExpiryDateModal} 
                                saving={changingExpiryDate} 
                                onClose={() => this.onChangeExpiryDate(false)} 
                                onSuccess={(expire) => this.onChangeExpiryDateSuccess(expire)}
                                confirmationMessage="Are you sure you want to change the expiry?"
                                expire={expire}
                            />
                        :
                        <></>
                    }
                    {
                        changeLinkPasswordModal ?
                            <ChangeLinkPasswordModal 
                                open={changeLinkPasswordModal} 
                                saving={changingLinkPassword} 
                                onClose={() => this.onChangeLinkPassword(false)} 
                                onSuccess={(linkPassword) => this.onChangeLinkPasswordSuccess(linkPassword)}
                                confirmationMessage="Are you sure you want to change the password?"
                                linkPassword={linkPassword}
                            />
                        :
                        <></>
                    }
                    {
                        linkProcessingModal ?
                            <ProcessingModal 
                                open={linkProcessingModal} 
                                onSuccess={() => this.onLinkProcessingModalSuccess()}
                                successMessage='Your link is still processing. Please check back in a few moments.'
                                store={this.store}
                            />
                        :
                        <></>
                    }
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.snackbarOpen}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Your link has been copied</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.closeSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserLinks.propTypes = {
    classes: PropTypes.object
};

export default withStyles(linksPageStyle)(UserLinks);
