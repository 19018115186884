import React, { Component } from "react";
import Button from "../CustomButtons/Button.jsx";
import ApiGoogleDrive from "../../../assets/js/utils/GoogleDrive/Api";
import Config from "../../../../Config.js";

class GoogleDrivePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
          pickerApiLoaded: false,
        };
    }
    
    componentDidMount() {
        this.loadGooglePicker();
    }
    
    loadGooglePicker = () => {
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/api.js";
        script.onload = this.onApiLoad;
        document.body.appendChild(script);
    };
    
    onApiLoad = () => {
        window.gapi.load("picker", { callback: this.onPickerApiLoad });
    };
    
    onPickerApiLoad = () => {
        this.setState({ pickerApiLoaded: true });
    };

    openPicker = () => {
        const { pickerApiLoaded } = this.state;
        const { accessToken } = this.props;
    
        if (pickerApiLoaded && accessToken) {
            const view = new window.google.picker.DocsView()
                .setIncludeFolders(true) 
                .setSelectFolderEnabled(true) 
                .setOwnedByMe(true)
                .setMode(window.google.picker.DocsViewMode.LIST);;

            const picker = new window.google.picker.PickerBuilder()
                .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
                .setOAuthToken(accessToken)
                .addView(view)
                .setCallback(this.pickerCallback)
                .setAppId(Config.getGoogleDriveAppId())
                .build();
    
            picker.setVisible(true);
        } else {
            this.getGoogleDriveData();
        }
    };

    getGoogleDriveData(){
        ApiGoogleDrive.getGoogleDriveEmail().then(data => {
            // console.log(data);
        }).catch(err => {
            console.log('error',err);
        });
    }
    
    pickerCallback = (data) => {
        if (data.action === window.google.picker.Action.PICKED) {
            this.props.loadFiles();
        }
    };

    render() {
        return (
            <div>
                <Button onClick={this.openPicker} color={"transparent"} className="google-drive">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 52c0-19.9 16.1-36 36-36s36 16.1 36 36l0 140.2c0 2.9 1.6 5.6 4.2 7s5.7 1.3 8.2-.3c6.8-4.4 15-6.9 23.7-6.9c19 0 35.3 12.1 41.4 29c.9 2.4 2.8 4.2 5.2 4.9s5.1 .3 7.1-1.3c7.3-5.5 16.4-8.7 26.3-8.7c19.9 0 36.7 13.2 42.1 31.3c.7 2.2 2.3 4.1 4.4 5s4.6 .9 6.7-.1c5.7-2.7 12.1-4.2 18.8-4.2c24.3 0 44 19.7 44 44l0 28 0 88c0 48.6-39.4 88-88 88l-72 0-16 0-49 0c-39.5 0-76.8-18.2-101-49.4L22.7 339.6c-10.9-13.9-8.3-34.1 5.6-44.9s34.1-8.3 44.9 5.6l40.4 52c2.1 2.7 5.7 3.8 8.9 2.7s5.4-4.2 5.4-7.6L128 52zM164 0c-28.7 0-52 23.3-52 52l0 272.1L85.9 290.5c-16.3-20.9-46.4-24.7-67.4-8.4s-24.7 46.4-8.4 67.4L93.3 456.4C120.6 491.5 162.5 512 207 512l49 0 16 0 72 0c57.4 0 104-46.6 104-104l0-88 0-28c0-33.1-26.9-60-60-60c-6.1 0-12 .9-17.6 2.6C360.8 214.2 340.1 200 316 200c-10 0-19.3 2.4-27.6 6.7C278.1 188.4 258.5 176 236 176c-7 0-13.7 1.2-20 3.4L216 52c0-28.7-23.3-52-52-52zm60 312c0-4.4-3.6-8-8-8s-8 3.6-8 8l0 112c0 4.4 3.6 8 8 8s8-3.6 8-8l0-112zm64 0c0-4.4-3.6-8-8-8s-8 3.6-8 8l0 112c0 4.4 3.6 8 8 8s8-3.6 8-8l0-112zm64 0c0-4.4-3.6-8-8-8s-8 3.6-8 8l0 112c0 4.4 3.6 8 8 8s8-3.6 8-8l0-112z"/></svg>
                    Select from Google Drive
                </Button>
            </div>
        );
    }
}

export default GoogleDrivePicker;