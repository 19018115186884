import {
    sidebar,
    content,
    tableHeadColumns,
    grayColor,
    blackColor
} from "../material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const userSettingsPageStyle = theme => ({
    ...customCheckboxRadioSwitchStyle(theme),
    main: {
        marginTop: "0px"
    },
    container: {
        width: "100%",
        zIndex: "2",
        top: "62px",
        position: "relative",
        paddingTop: "20px",
        height: "calc(100vh - 62px)"
    },
    downloadsMainContainer: {
        height: "100%"
    },
    sidebar: {
        ...sidebar(theme)
    },
    content: {
        ...content(theme)
    },
    label: {
        ...customCheckboxRadioSwitchStyle(theme).label,
        paddingLeft: "0px",
    },
    settingsContainer: {
        textAlign: "left",
        "& h3": {
            color: theme.customize.pageTitleColor
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: "500px"
        }
    },
    extensionsContainer: {
        textAlign: "left",
        marginBottom: "60px",
        "& h3": {
            color: theme.customize.pageTitleColor
        },
    },
    customSelectFormControl: {
        "& .MuiInputLabel-root": {
            color: theme.customize.color+" !important",
            transform: "none !important",
            top: "0px !important"
        },
        "& .MuiSelect-select": {
            "&.Mui-disabled": {
                paddingLeft: "10px",
                backgroundColor: grayColor[11]
            }
        },
        "& .MuiInputBase-formControl": {
            borderRadius: "3px",
            overflow: "hidden",
        }
    },
    alignLeft: {
        textAlign: "left"
    },
    themeToggle: {
        "& label": {
            color: theme.customize.color,
            fontSize: "12px",
            marginRight: "0px"
        },
        "& .MuiFormControlLabel-root": {
            display: "block"
        }
    },
    houseKeepingContainer: {
        textAlign: "left",
        "& .note": {
            marginTop: "10px",
            "& a": {
                color: theme.customize.linksColor
            }
        },
        "& h3": {
            color: theme.customize.pageTitleColor,
            fontSize: "18px"
        },
        "& .Mui-disabled": {
            "& $checkedIcon": {
                background: grayColor[11],
                borderColor: grayColor[11],
                fill: blackColor
            }
        }
    },
    houseKeepingTable: {
        ...tableHeadColumns(theme),
        "& .rt-table": {
            border: "1px solid",
            borderColor: theme.customize.borderColor,
            "&:not(:hover)": {
                "&::-webkit-scrollbar": {
                    width: "15px",
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(0,0,0,0.3)",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                    
                }
            }
        },
        "& .rt-thead": {
            "& .rt-th":{
                textAlign: "center !important",
                fontSize: "14px"
            },
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "14px",
            color: theme.customize.color,
        },
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            textAlign: "left",
            "&.td_name": {
                margin: "0px",
                padding: "0px",
                "& .react-contextmenu-wrapper": {
                    padding: "5px 5px",
                    margin: "0px",
                    "&>div": {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        display: "block",
                        width: "100%",
                    }
                }
            },
            "&.td_hide": {
                display: "none"
            }
        },
        "& .hd_status": {
            width: "70px !important",
            textAlign: "center !important",
            flex: "none !important",
            marginRight: "10px !important",
        },
        "& .hd_delete": {
            display: "flex",
            justifyContent: "space-around",
            "& .MuiButtonBase-root": {
                padding: "0px",
            }  
        },
        "& .hd_duration": {
            width: "15% !important",
            textAlign: "left !important",
            "& .MuiFormControl-root": {
                margin: "0px !important"
            },
            "& .MuiInput-formControl": {
                marginTop: "0px"
            },
            "& .duration_flex": {
                display: "flex",
                flexDirection: "column",
                rowGap: "5px",
                "& .MuiFormControl-fullWidth": {
                    paddingTop: "0px"
                },
                "& input": {
                    color: theme.customize.color
                }
            }
        },
        "& .hd_criteria": {
            width: "20% !important",
            textAlign: "left !important",
        },
        "& .hd_more_info": {
            width: "130px !important",
            flex: "none !important",
            "& .MuiButton-text": {
                textTransform: "capitalize",
            }
        },
        "& .rt-tr": {
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: theme.customize.borderColor
        },
        "& .rt-tbody": {
            overflow: "unset",
            "& .rt-tr-group": {
                flexGrow: "0",
            }
        },
    },
    houseKeepingFooter: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
        marginBottom: "80px",
        "& .run-save": {
            display: "flex",
            columnGap: "20px"
        }
    },
    formFooter: {
        paddingTop: "10px"
    },
    extensionsLists: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "15px",
        alignItems: "flex-start",
        "& .extension": {
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
            width: "100%",
            padding: "10px",
            backgroundColor: theme.customize.horizontalMenuBgColor,
            borderRadius: "5px",
            // border: theme.customize.border,
            "& .title": {
                fontWeight: "500",
                marginBottom: "0px",
            },
            "& .description": {
                marginBottom: "0px"
            },
            "& .icon-detail": {
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                columnGap: "10px",
                minHeight: "50px",
                [theme.breakpoints.down("sm")]: {
                    minHeight: "auto",
                }
            },
            "& .MuiFormControlLabel-root": {
                display: "block",
                textAlign: "right",
                margin: "0px",
                "& .MuiSwitch-root": {
                    width: "auto"
                }
            },
            "& img": {
                maxWidth: "50px",
                width: "100%"
            },
            "& .extension-actions": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "& .buttons": {
                    display: "flex",
                    columnGap: "10px"
                }
            },
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            }
        }
    },
    tableSection: {
        flex: "1",
        "& th.MuiTableCell-root": {
            borderBottom: 'none !important',
        },
        "& td.MuiTableCell-root": {
            borderBottom: 'none !important',
        },
        "& .MuiTableCell-root": {
            display: 'table-cell',
            padding: '0px',
            paddingRight: "10px",
            textWrap: "nowrap",
            color: theme.customize.color
        },
        "& .MuiTableRow-root": {
            "&>td": {
                "&:last-child": {
                    paddingRight: "0px"
                }
            },
            "&>th": {
                "&:last-child": {
                    paddingRight: "0px"
                }
            },
            "& .path": {
                "& div": {
                    maxWidth: "250px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }
            },
            "& .MuiButton-root": {
                padding: "0px",
                width: "auto",
                height: "auto",
                minWidth: "auto",
            }
        },
        "& .TabContainer": {
            overflowX: 'auto',
            width: '100%',
        },
        "& .MuiCheckbox-root": {
            padding: "0px"
        },
    },
});

export default userSettingsPageStyle;