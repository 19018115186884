import React from 'react'
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader.js";
import { helper } from '../../../assets/js/utils/Element';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import newFileModalStyle from "../../../assets/jss/user/newFolderModalStyle.jsx";
import DeleteConfirmationModal from './DeleteConfirmationModal.js';
import responsiveLogo from "../../../assets/img/logo.png";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const BackupCodesModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            loading: false,
            saving: false,
            error: false,
            errorMessage: "",
            backupCodes: [],
            deleteModal: false,
            deleting: false,
            userData: null,
            snackbarOpen: false,
        }
    }
    componentDidMount(){
        this.loadBackupCodes();
    }
    loadBackupCodes(){
        const source = axios.CancelToken.source();
        this.setState({
            loading: true
        })

        let requestData = {};

        Api.getBackupCodes(requestData, source).then(data => {  
            this.setState({
                backupCodes: data.backupCodes,
                loading: false,
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onSubmit(regenrate = false){
        const source = axios.CancelToken.source();

        this.setState({
            saving: true
        })

        let requestData = {};

        if(regenrate === true){
            requestData['regenrate'] = 1;
        }

        Api.createBackupCodes(requestData, source).then(data => {
            let stateObj = {
                saving: false,
                backupCodes: data.backupCodes,
            };
            if(data.hasOwnProperty('user')){
                stateObj['userData'] = data.user;
            }

            this.setState(stateObj);
        }).catch(err => {
            this.setState({
                saving: false, 
                error: true, 
                errorMessage: err.message
            });
        });
    }
    renderCodes(){
        const { backupCodes } = this.state;

        let backupCodesData = [];
        if(backupCodes.length <= 0){
            return backupCodesData;
        }
                
        backupCodes.map((backupCode, key) => {
            let codeData =  (
                <li key={key}>
                    {
                        backupCode.used ? 
                            <p>--------</p>
                        :
                            <p>{backupCode.code}</p>
                    }
                </li>
            );

            backupCodesData.push(codeData);
            return null;
        });

        return backupCodesData;
    }
    onCopyCodes(){
        const { backupCodes } = this.state;
        const formattedText = backupCodes.map((item) => (item.used ? 'Already Used' : item.code)).join("\n");
        helper.copyTextToClipboard(formattedText);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    onDeleteModal(status = false){
        this.setState({
            deleteModal: status
        })
    }
    onDeleteModalSuccess(){
        this.setState({
            deleting: true
        })
       
        Api.deleteBackupCodes().then(data => {
            this.setState({
                deleting: false,
                deleteModal: false,
                backupCodes: [],
            },() => {
                this.props.onDeleteBackupCodes(data);
            })
        }).catch(err => {
            this.setState({
                deleting: false,
                error: true, 
                errorMessage: err.message
            })
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    printCodes(){
        window.print();
    };
    render() {
        const { saving, error, loading, backupCodes, deleteModal, deleting, userData } = this.state;
        const { classes } = this.props;
        const { user } = this.store.getState();

        return (
            <>
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal
                    }}
                    open={this.props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="newFileModal-modal-slide-title"
                    aria-describedby="newFileModal-modal-slide-description"
                >
                    <DialogTitle
                        id="newFileModal-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                        >   
                        {
                            saving ?
                                <></>
                            :
                                <Button
                                    simple
                                    className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                                    key="close"
                                    aria-label="Close"
                                    onClick={() => this.props.onClose(false, userData)}
                                >
                                    <Close className={classes.modalClose} />
                                </Button>
                        }
                        <h4 className={classes.modalTitle}>Backup codes</h4> 
                    </DialogTitle>
                    <DialogContent
                        id="newFileModal-modal-slide-description"
                        className={classes.modalBody+" empty-space"}
                    >
                        {
                            error ?
                                this.renderErrorMessages()
                            :
                                <></>
                        }
                        {
                            loading ? 
                                <LoaderComponent color="custom" align="center" />
                            : backupCodes.length > 0 ?
                                <ul className={classes.backupCodes}>
                                    { this.renderCodes() }
                                </ul>
                            :
                                <p>Get a list of codes to keep with you that you can enter to sign in. Keep these backup codes somewhere safe but accessible.</p>
                        }
                    </DialogContent>
                    <DialogActions className={classes.modalFooter+" "+classes.backupCodeFooter}>
                        {
                            loading ?
                                <></>
                            : saving ?
                                <Button color="custom" >
                                    <LoaderComponent color="white" align="center" saving={true} />
                                </Button>
                            : backupCodes.length > 0 ?
                                <>
                                    <Button color="custom" onClick={() => this.printCodes()}>
                                        Print
                                    </Button>
                                    <Button color="custom" onClick={() => this.onCopyCodes()} >
                                        Copy
                                    </Button>
                                    <Button color="custom" className={classes.changeButton} onClick={() => this.onDeleteModal(true)}>
                                        Delete
                                    </Button>
                                    <Button color="custom" className={classes.changeButton} onClick={() => this.onSubmit(true)}>
                                        Regenerate
                                    </Button>
                                </>
                            :
                                <Button color="custom" className={classes.changeButton} onClick={() => this.onSubmit()}>
                                    Create
                                </Button>
                        }
                    </DialogActions>
                    {
                        deleteModal ?
                            <DeleteConfirmationModal 
                                open={deleteModal} 
                                saving={deleting} 
                                onClose={() => this.onDeleteModal(false)} 
                                onSuccess={() => this.onDeleteModalSuccess()}
                                confirmationMessage="Are you sure you want to delete backup codes?"
                            />
                        :
                            <></>
                    }
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        className={classes.snackbar}
                        open={this.state.snackbarOpen}
                        autoHideDuration={1000}
                        onClose={() => this.closeSnackbar()}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Backup Codes Copied to Clipboard</span>}
                        action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={() => this.closeSnackbar()}
                        >
                            <CloseIcon />
                        </IconButton>,
                        ]}
                    />
                    <div className="codes-print-area" style={{ display: "none" }}>
                        <div className='codes-section'>
                            <div>
                                <img src={responsiveLogo} alt={process.env.REACT_APP_TITLE} />
                                <p className='bold'>Backup Codes</p>
                                <p>{user.email}</p>
                                <p>You can only use each backup code once</p>
                            </div>
                            <div className='codes-list'>
                                {
                                    backupCodes.map((code) => (
                                        code.used ? 
                                            <p className='item'>Already Used</p>
                                        :
                                            <p className='item'>{code.code}</p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <style>
                        {`
                            @media print {
                                body * {
                                    visibility: hidden;
                                }
                                .codes-print-area, .codes-print-area * {
                                    visibility: visible;
                                    display: block !important;
                                    text-align: center;
                                }
                                .codes-print-area {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 100%;
                                    -webkit-print-color-adjust: exact;
                                    print-color-adjust: exact; 
                                }
                                .codes-print-area .codes-section {
                                    width: 500px; 
                                    padding: 20px; 
                                    border: 1px solid #ddd;
                                    margin: auto;
                                }
                                .codes-print-area img {
                                    max-width: 150px;
                                    margin: 0px auto 10px;
                                }
                                .codes-print-area p {
                                    font-size: 12px;
                                }
                                .codes-print-area p.bold {
                                    font-size: 14px;
                                    font-weight: bold;
                                }
                                .codes-list {
                                    overflow: hidden; 
                                }
                                .codes-list p.item {
                                    background: #556DC21A;
                                    -webkit-print-color-adjust: exact;
                                    print-color-adjust: exact;
                                    float: left;
                                    width: 49%; 
                                    border-radius: 5px; 
                                    padding: 10px;
                                }
                                .codes-list p:nth-child(even) {
                                    margin-left: 2%;
                                }
                            }
                        `}
                    </style>
                </Dialog>
            </>
        )
    }
}

BackupCodesModal.defaultProps = {
    open: false,    
}
BackupCodesModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
export default withStyles(newFileModalStyle)(BackupCodesModal);