import { whiteColor, grayColor, infoColor, successColor } from "../material-kit-pro-react.jsx";

import homePageStyle from "../material-kit-pro-react/views/homePageStyle.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
const fileVersionModalStyle = theme => ({
    ...homePageStyle(theme),
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalTitleDesc: {
        color: theme.customize.whiteBlackColor,
        opacity: "0.8",
        marginBottom: "0px"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "51px",
        paddingRight: "0px",
        paddingLeft: "0px",
        "&.uploading": {
            paddingTop: "0px",
        },
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& h4": {
            textAlign: "center",
            marginTop: "0px",
            marginBottom: '15px',
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginRight: "10px",
            "& svg": {
                color: theme.customize.iconsColor,
                fill: theme.customize.iconsColor,
            }
        },
        "& li": {
            opacity: 1,
            color: theme.customize.whiteBlackColor,
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    filesList: {
        listStyle: "none",
        margin: "0px",
        padding: "0px",
        color: whiteColor,
        textAlign: "left",
        fontSize: "14px",
        "& li": {
            padding: "5px 0px",
            marginTop: "5px",
            borderTop: "1px solid "+grayColor[4],
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
    },
    zipName: {
        "& input": {
            color: whiteColor
        },
        "& .MuiInput-underline": {
            border: "1px solid "+grayColor[2],
            paddingLeft: "10px",
            borderRadius: "3px",
            "&:before": {
                display: "none !important"
            },
            "&:after": {
                bottom: "-1px",
                borderBottomWidth: "1px"
            }
        }
    },
    zipPassword: {
        "& .MuiFormControl-root": {
            paddingTop: "0px"
        }
    },
    removeButtonBorder: {
        "& button": {
            border: "0px !important"
        }
    },
    inlineFields: {
        "& .MuiFormControl-root": {
            width: "calc(50% - 10px)",
            "&:first-child": {
                marginRight: "5px",
            },
            "&:first-child + div": {
                marginLeft: "5px"
            },
            "& .MuiTextField-root": {
                width: "100%"
            }
        }
    },
    inputAdornmentIcon: {
        color: whiteColor+" !important"
    },
    checkIcon: {
        color: successColor[0]
    },
    skipIcon: {
        color: infoColor[2]
    },
    description: {
        color: whiteColor,
        textAlign: "center",
        padding: "10px",
        marginBottom: "0px"
    },
    inputFile: {
        display: "none !important"
    },
    fileHeader: {
        marginBottom: "5px"
    },
    fileVersion: {
        marginRight: "10px"
    },
    fileDate: {
        marginRight: "10px",
        fontSize: "12px"
    },
    fileName: {
        color: grayColor[12]
    },
    fileOwner: {
        color: grayColor[12]
    },
    fileOptionsMenu: {
        "& .MuiSvgIcon-root": {
            marginRight: "5px",
            color: grayColor[0]
        }
    },
    files: {
        textAlign: "left"
    },
    infinityIcon: {
        maxWidth: '30px',
        width: '100%',
        marginRight: "15px"
    },
    uploadingProgress: {
        ...homePageStyle(theme).uploadingProgress,
        border: "none",
        "& $transferFooter": {
            height: "auto",
            "& button": {
                marginBottom: "0px",
            }
        }
    },
    uploadingContent: {
        marginBottom: "20px",
        textAlign: "center",
        "& .transfer_loader": {
            marginTop: "0px",
        },
    }
});

export default fileVersionModalStyle;
