/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
    container,
    description,
    cardTitle,
    blackColor,
    whiteColor,
    hexToRgb,
    primaryColor
} from "../../material-kit-pro-react.jsx";

const signupPageStyle = theme => ({
    description,
    cardTitle: {
        ...cardTitle,
        textDecoration: "none",
        textAlign: "center !important",
        marginBottom: "0.75rem"
    },
    container: {
        ...container,
        zIndex: "2",
        position: "relative",
        paddingTop: "5.5em",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "100px"
        }
    },
    pageHeader: {
        color: whiteColor,
        border: "0",
        height: "100%",
        margin: "0",
        display: "flex!important",
        padding: "120px 0px",
        position: "relative",
        minHeight: "100vh",
        alignItems: "center",
        "&:before": {
            background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
        },
        "&:before,&:after": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: '""'
        },
        [theme.breakpoints.down("sm")]: {
            padding: "80px 0px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "60px 0px",
        }
    },
    form: {
        margin: "0"
    },
    cardHeader: {
        width: "auto",
        textAlign: "center"
    },
    socialLine: {
        marginTop: "1rem",
        textAlign: "center",
        padding: "0"
    },
    iconButtons: {
        marginRight: "3px !important",
        marginLeft: "3px !important"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block",
        "&,& *,& *:hover,& *:focus": {
        color: whiteColor + "  !important"
        }
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right",
        "&,& *,& *:hover,& *:focus": {
        color: whiteColor + "  !important"
        }
    },
    icon: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
    footer: {
        position: "absolute",
        width: "100%",
        background: "transparent",
        bottom: "0",
        color: whiteColor,
        zIndex: "2"
    },
    anchor: {
        // color: whiteColor,
        color: primaryColor[0],
        cursor: "pointer",
        textAlign: "left",
    },
    passwordControl: {
        marginBottom: "5px"
    },
    loginCard: {
        marginTop: "0px",
        marginBottom: "100px",
        padding: "40px 0px",
        // backgroundColor: "rgba(0, 0, 0, 0.3)",
        // "& form>h2, label, .MuiInput-input,  .MuiInput-input::placeholder": {
        //     color: whiteColor+" !Important" 
        // },
        "& .MuiFormLabel-root": {
            top: "10px"
        },
        "& .footer-links": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        "& .twoStepVerifcation": {
            paddingLeft: "0px",
            textAlign: "left",
            listStyle: "none",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            "& li": {
                cursor: "pointer",
                background: theme.customize.gridBgColor,
                padding: "10px",
                borderRadius: "5px",
                "& p": {
                    marginBottom: "0px",
                    "&.note": {
                        fontSize: "12px",
                        lineHeight: "16px",
                        opacity: "0.8"
                    }
                }
            }
        }
    },
    // inputIconsColor: {
    //     color: whiteColor+" !Important" 
    // }
    forgotGridItem: {
        padding: "0px",
        display: "flex",
        justifyContent: "space-between"
    },
    alignLeft: {
        textAlign: "left"
    },
    textCenter: {
        textAlign: "center",
        "& $anchor": {
            textAlign: "center",
            margin: "10px 0px 0px"
        }
    },
});

export default signupPageStyle;
