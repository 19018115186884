import {
    whiteColor,
    grayColor,
    dangerColor,
    blackColor,
    controls,
    controlButtons,
    sidebar,
    tableHeadColumns,
    tableBody,
    separator,
    infoColor,
    content,
    editor,
} from "../material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const userHomePageStyle = theme => ({
    ...customCheckboxRadioSwitchStyle(theme),
    controls: {
        ...controls(theme),
        "& .tabs-info": {
            "& p":{
                marginBottom: "0px",
                "& .link-color": {
                    color: theme.customize.linksColor
                }
            }
        }
    },
    controlButtons: {
        ...controlButtons(theme),
    },
    sidebar: {
        ...sidebar(theme)
    },
    controlsTabs: {
        "& button": {
            "&.MuiTab-labelIcon": {
                minHeight: '48px',
                paddingTop: "6px",
            },
            "& .MuiTab-wrapper": {
                flexDirection: "row",
                "& img": {
                    width: '30px',
                    marginBottom: '0px'
                }
            }
        },
        "& .sd-custom-tabs": {
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid "+theme.customize.borderColor,
            width: "max-content",
            "& .sd-custom-tab": {
                minWidth: "160px",
                maxWidth: "250px",
                padding: "6px 12px",
                marginLeft: "0px",
                marginRight: "0px",
                fontSize: "12px",
                boxSizing: "border-box",
                minHeight: "48px",
                flexShrink: "0",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                textTransform: "uppercase",
                color: theme.customize.customTabsTextColor,
                "&.active": {
                    color: theme.customize.customTabsActiveTextColor,
                    borderBottom: "3px solid "+theme.customize.brandColor
                },
                "& img": {
                    width: "30px"
                },
                "& button": {
                    padding: "0px",
                    width: "auto",
                    height: "auto",
                    minWidth: "auto",
                    "& svg": {
                        marginRight: "0px"
                    }
                },
                [theme.breakpoints.down("xs")]: {
                    paddingRight: "5px",
                    paddingLeft: "5px",
                    minWidth: "72px"
                }
            }
        }
    },
    main: {
        marginTop: "0px",
        "& .react-contextmenu": {
            zIndex: "3",
            backgroundColor: theme.customize.menuBgColor,
            cursor: "pointer",
            minWidth: "160px",
            textAlign: "left",
            boxShadow: theme.customize.menuBoxShadowColor,
            borderRadius: "5px",
            padding: "5px",
            overflow: "hidden",
            "& .border-bottom": {
                backgroundColor: theme.customize.borderColor,
                height: "1px",
                width: "100%",
                display: "block",
            },
            "& .react-contextmenu-item": {
                padding: "5px 10px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                color: theme.customize.gridTextColor,
                "& $fileIcon": {
                    padding: "0px"
                },
                "& svg": {
                    width: "15px",
                    marginRight: "7px",
                    maxHeight: "15px",
                    color: theme.customize.gridTextColor,
                    fill: theme.customize.gridTextColor,
                },
            },
        }
    },
    container: {
        width: "100%",
        zIndex: "2",
        top: "62px",
        position: "relative",
        paddingTop: "20px",
        height: "calc(100vh - 62px)"
    },
    downloadsMainContainer: {
        height: "100%"
    },
    content: {
        ...content(theme),
        
    },
    flexPageContent: {
        display: "flex",
        flexDirection: "column",
        "& > *": {
            flexShrink: 0,
        },
        "& $tableGridItem": {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
        },
        "& $filesContainer": {
            maxHeight: "calc(100% - 60px)",
            "&.external-links, &.trash": {
                maxHeight: "calc(100% - 110px)",
            }
        },
    },
    separator: {
        ...separator(theme)
    },
    filesTable: {
        ...tableHeadColumns(theme),
        ...tableBody(theme),
        "&$homePageTable":{
            "& .rt-thead": {
                position: "sticky",
                zIndex: "1",
                top: "0",
                background: theme.customize.tableHeadBackgroundColor,
            },
        },
        "& .rt-table": {
            "&:not(:hover)": {
                "&::-webkit-scrollbar": {
                    width: "15px",
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(0,0,0,0.3)",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                    
                }
            }
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
            color: theme.customize.color,
        },
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            "&.td_name": {
                margin: "0px",
                padding: "0px",
                "& .react-contextmenu-wrapper": {
                    padding: "5px 5px",
                    margin: "0px",
                    "&>div": {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        display: "block",
                        width: "100%",
                    }
                },
                "& .file-name": {
                    display: "flex !important",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "&>div": {
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "90%",
                    },
                    "& $fileIcon": {
                        maxWidth: "25px",
                        width: "100%",
                        marginRight: "15px",
                        flexShrink: "0",
                        padding: "0px",
                        "&.star-icon": {
                            marginRight: "0px",
                            "& svg": {
                                fill: theme.customize.brandColor,
                                color: theme.customize.brandColor
                            }
                        }
                    },
                    "& svg": {
                        width: "auto",
                        height: "auto",
                        fill: theme.customize.brandColor,
                        color: theme.customize.brandColor
                    }
                }
            },
            "&.td_hide": {
                display: "none"
            },
            "&.hd_related_links": {
                "& a": {
                    textDecoration: "underline"
                }
            },
            "&.td_attachments": {
                wordBreak: "break-all",
                "& a+a": {
                    "&:before": {
                        content: "','",
                        display: "inline-block",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }
                },
                "& button": {
                    padding: "0px",
                    margin: "0px",
                    width: "auto !important",
                    height: "auto !important",
                    textTransform: "none",
                    color: theme.customize.color,
                    "&:hover": {
                        textDecoration: "underline",
                    }
                }
            },
        },
        "& .hd_check": {
            width: "30px !important",
            textAlign: "center !important",
            flex: "none !important",
            marginRight: "10px !important",
            "& .MuiCheckbox-root": {
                paddingLeft: "0px",
                paddingRight: "0px",
                padding: "0px",
                display: "none",
                "&.Mui-checked": {
                    display: "inline-flex"
                }
            },
            "& .rt-resizer": {
                display: "none !important"
            },
        },
        "& .hd_name": {
            width: "25% !important",
            textAlign: "left !important",
        },
        "& .hd_size": {
            // width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_creation_date": {
            width: "13% !important",
            textAlign: "left !important",
        },
        "& .hd_related_links": {
            // width: "15% !important",
            textAlign: "left !important",
            "& svg": {
                color: theme.customize.tableRowActionsIconsColor,
                fill: theme.customize.tableRowActionsIconsColor,
                display: "block"
            }
        },
        "& .td_recipients": {
            width: "15% !important",
            textAlign: "left !important",
            overflow: "visible",
            "&.td_destination": {
                overflow: "hidden",
            }
        },
        "& .hd_owner": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_deleted_from": {
            width: "15% !important",
            textAlign: "left !important",
            "& div": {
                width: "90%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
            }
        },
        "& .hd_activity": {
            width: "20% !important",
            textAlign: "left !important",
            "& span": {
                marginRight: "10px",
            },
            "& .MuiButton-text": {
                textTransform: "capitalize",
                padding: "0px",
                margin: "0px",
                marginBottom: "1px",
                fontWeight: "bold",
            }
        },
        "& .td_check": {
            "& .MuiButtonBase-root": {
                padding: "0px",
            }            
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px"
        },
        "& .hd_notes": {
            textAlign: "left",
            "& svg": {
                display: "block"
            }
        },
        "&.usersTable": {
            "& .left": {
                textAlign: "left"
            }
        },
        "&.loginVideosTable": {
            "& .left": {
                textAlign: "left !important"
            },
            "& .hd_type, & .hd_actions": {
                width: "50px !important",
                flex: "50 0 auto !important",
            },
            "& .hd_title": {
                width: "160px !important",
                flex: "160 0 auto !important",
            },
            "& .hd_actions": {
                "& button": {
                    "&:first-child": {
                        paddingLeft: "0px",
                    },
                    "&:last-child": {
                        paddingRight: "0px"
                    }
                }
            }
        },
        "& .hd_sender": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_path": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_recipients": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_expire": {
            width: "12% !important",
            textAlign: "left !important",
        },
        "& .hd_select_menu": {
            paddingRight: "15px !important",
            width: "30px !important",
            "&>div": {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
            },
            "& .file-menu-icon": {
                cursor: "pointer",
                "& svg": {
                    color: theme.customize.iconColor,
                    fill: theme.customize.iconColor,
                }
            },
        },
        "& .hd_attachments": {
            width: "15% !important",
            textAlign: "left !important",
            "& button": {
                textTransform: "none",
                "&:hover": {
                    textDecoration: "underline"
                }
            }
        },
        "& .hd_type": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_link_password": {
            width: "12% !important",
            textAlign: "left !important",
            display: "flex",
            alignItems: "center",
            "& button": {
                height: "auto",
                paddingTop: "0px",
                paddingBottom: "0px"
            }
        },
        "& .sd-password-field": {
            "& .sd-passwords": {
                display: "flex",
                "& span:before": {
                    content: '""',
                    width: "3px",
                    height: "3px",
                    display: "block",
                    borderRadius: "100%",
                    backgroundColor: theme.customize.color,
                    marginRight: "2px"
                }
            }
        }
    },
    modalFilesTable: {
        "& .rt-tbody": {
            overflow: "unset",
            minHeight: "100px",
        },
        "& .hd_check": {
            "& .MuiCheckbox-root": {
                display: "inline-flex"
            }
        }
    },
    fileIcon: {
        height: "auto",
        width: "auto",
        padding: "0px 3px",
        minWidth: "0px",
        margin: "0px",
        marginRight: "5px",
        "& img": {
            width: "23px",
            height: "23px",
            objectFit: "cover"
        },
        "& .questionmark_icon": {
            color: theme.customize.tableRowActionsIconsColor,
            width: "24px",
            height: "24px"
        },
        color: theme.customize.color,
        "& .star_filled": {
            color: theme.customize.sidebarMenuIconsColor
        },
        "& .privacy-icon": {
            marginLeft: "5px"
        }
    },
    loadMore: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        color: theme.customize.pageTitleColor,
        marginBottom: "20px",
        width: "100%",
        marginTop: "0px",
        textAlign: "left",
    },
    videoTitle: {
        color: theme.customize.color,
        textShadow: "2px 2px 2px #000",
        fontSize: "16px"
    },
    videoContainer: {
        textAlign: "left",
        "& iframe": {
            [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
            },
            [theme.breakpoints.down("sm")]: {
                maxHeight: "300px",
            },
            [theme.breakpoints.down("xs")]: {
                maxHeight: "250px",
            }
        },
        
    },
    supportContainer: {
        textAlign: "left",
        "& $formFooter":{
            backgroundColor: theme.customize.opacityColor,
            border: theme.customize.border,
            "& button": {
                color: theme.customize.color,
            }
        }
    },
    supportResources: {
        textAlign: "left",
        "& .section-title": {
            margin: "0 0 2rem",
            fontWeight: "500",
            color: theme.customize.color,
            "&::after": {
                content: '""',
                margin: ".75rem 0 0",
                border: "none",
                borderRadius: ".125rem",
                backgroundColor: theme.customize.color,
                display: "block",
                height: ".25rem",
            }
        },
        "& .section-content": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            borderRadius: "5px",
        },
        "& .resources-section-content": {
            "& .resources-content": {
                display: "flex",
                alignItems: "center",
                marginBottom: theme.spacing(3),
                "& svg": {
                    marginRight: "10px",
                    width: "1.6em",
                    height: "1.6em",
                    fill: theme.customize.color
                },
                "& a": {
                    color: theme.customize.linksColor,
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                    fontWeight: "500",
                    "&:hover": {
                        textDecoration: "none"
                    }
                },
                "& p":{
                    marginBottom: "0px",
                    color: theme.customize.color
                }
            }
        },
    },
    formFields: {
        "& > div": {
            marginBottom: "10px",
            overflow: "hidden",
            clear: "both",
            "& .MuiInput-multiline": {
                "&:before, &:after": {
                    content: "none"
                }
            }
        },
        "& .formElement": {
            width: "100%",
            padding: "0",
            position: "relative",
            "& p":{
                color: whiteColor,
                textAlign: "left",
                fontSize: "14px",
                margin: "0px",
                textShadow: "2px 2px 2px #000",
            }
        },
        "& input, textarea": {
            backgroundColor: whiteColor,
            padding: "10px",
            boxSizing: "border-box",
            borderRadius: "3px",
            width: "100%",
            verticalAlign: "top",
            display: "inline-block",
            border: theme.customize.border,
            float: "right",
        },
        "& textarea": {
            minHeight: "200px"
        },
        "& label": {
            color: whiteColor,
            maxWidth: "150px",
            width: "100%",
            textAlign: "right",
            fontSize: "18px",
            marginTop: "7px",
            display: "inline-block",
            textShadow: "2px 2px 2px #000",
        },
        "& .claer-icon": {
            position: "absolute",
            top: "5px",
            right: "15px",
            fill: dangerColor[0]
        },
        [theme.breakpoints.down("sm")]: {
            "& label":{
                maxWidth: "100%",
                textAlign: "left",
                display: "block",
                padding: "0",
                fontSize: "16px",
                marginTop: "0px"
            },
            "& textarea": {
                minHeight: "135px",
            },
            "& .formElement": {
                width: "100%",
                float: "none"
            }
        },
    },
    formFooter: {
        position: "relative",
        padding: "0",
        borderRadius: "5px",
        textAlign: "left",
        width: "100% !Important",
        border: "none",
        height: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        "& img": {
            width: "1.5em",
            marginTop: "-5px",
            marginRight: "0.2em",
        },
        "& Button": {
            "& svg":{
                cursor: "pointer",
                height: "1.5em",
                marginRight: "0.2em",
                width: "1.5em",
                fill: theme.customize.iconsColor,
                fontSize: "inherit",
                transform: "rotate(-45deg)",
                display: "inline-block",
                verticalAlign: 'top',
                marginTop: "-2px",
                "&:hover": {
                    fill: theme.customize.iconsColor,
                },
                "&.sendicon": {
                    transform: "none",
                }
            },
            display: "block",
            fontSize: "1em",
            color: whiteColor,
            textTransform: "capitalize",
            textAlign: "left",
            backgroundColor: "transparent !Important",
            width: "100%",
            borderRadius: "0px",
            padding: "10px 8px",
            boxShadow: "none !Important",
            margin: "0px"
        },
    },
    breadcrumbs: {
        textAlign: "left",
        maxWidth: "100%",
        overflow: "hidden",
        fontWeight: "600",
        "& a": {
            fontSize: "20px",
            color: theme.customize.breadcrumbsColor+" !important",
            verticalAlign: "middle",
            "&.breadcrumb-home": {
                textDecoration: "none",
                cursor: "text",
            }
        },
        "&>div:last-child": {
            "& a": {
                color: theme.customize.tableHeadTextColor+" !important",
            }
        },
        "& div": {
            display: "inline-block",
            verticalAlign: "middle",
        },
        "& span": {
            margin: "0px 5px",
            verticalAlign: "middle",
            display: "inline-block",
            height: "18px",
            "& svg": {
                width: "18px",
                height: "18px"
            }
        },
        "& img": {
            width: "18px",
            marginRight: "5px"
        }
    },
    downloadsContainer: {
        "& >div": {
            "&+div": {
                marginTop: theme.spacing(8),
            },
            "&:last-child": {
                marginBottom: theme.spacing(8)
            }
        }
    },
    imageColumn: {
        "& img": {
            width: "100%",
            maxHeight: "150px",
            objectFit: "cover",
            objectPosition: "center",
            [theme.breakpoints.down("md")]: {
                maxHeight: "180px"
            }
        },
    },
    textColumn: {
        whiteSpace: "break-spaces",
        "& p": {
            color: theme.customize.color
        },
        "& h3": {
            color: theme.customize.color,
            marginTop: "0px",
            lineHeight: "normal"
        }
    },
    buttonColumn: {
        textAlign: "center",
        "& img":{
            maxWidth: "120px",
            width: "100%"
        },
        "& a": {
            cursor: "pointer",
        },
        "& button": {
            maxWidth: "120px",
            width: "100%",
            padding: "0px",
            color: theme.customize.color,
            "&:hover": {
                color: theme.customize.color,
            },
            "& img": {
                maxWidth: "18px",
                width: "100%",
                marginRight: "5px"
            },
            "&.window": {
                backgroundColor: "#20b5c9"
            },
            "&.mac": {
                backgroundColor: "#b98cd1"
            }
        }
    },
    tutorialsMainContainer: {
        paddingBottom: theme.spacing(8),
        height: "100%"
    },
    mainVideoColumn: {
        "& iframe": {
            width: "100%"
        },
        "& p": {
            lineHeight: "normal",
        },
        "& .video-title": {
            fontWeight: "bold",
            marginTop: "10px",
            marginBottom: "3px"
        },
    },
    sideVideosColumn: {
        "& >div+div": {
            marginTop: theme.spacing(1),
        },
        "& img": {
            width: "100%",
            maxHeight: "120px",
            height: "100%",
            objectFit: "cover",
            cursor: "pointer",
            [theme.breakpoints.down("sm")]: {
                height: "auto"
            }
        },
        "& .video-thumbnail": {
            paddingRight: "8px",
        },
        "& .video-detail": {
            paddingLeft: "0px"
        },
        "& p": {
            margin: "0px",
            lineHeight: "normal",
        },
        "& .video-title": {
            fontWeight: "bold",
            cursor: "pointer",
            marginBottom: "3px"
        },
        [theme.breakpoints.down("md")]: {
            marginTop: theme.spacing(4)
        }
    },
    filesContainer: {
        maxWidth: "100%",
        width: "100%",
        maxHeight: "420px",
        overflow: "auto",
        minHeight: "420px",
        "&.external-links, &.trash": {
            marginTop: "50px"
        }
    },
    recipients: {
        "& div": {
            display: "flex",
            "& .recipients-list": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            "& .recipients-count": {
                fontWeight: "bold",
            }
        },
        "& ul": {
            display: "none",
            listStyleType: "none",
            paddingLeft: "0px",
            position: "absolute",
            zIndex: "1",
            background: theme.customize.buttonsColor,
            borderRadius: "3px",
            "& li": {
                padding: "3px 5px"
            }
        },
        "&:hover": {
            "& ul": {
                display: "block"
            }
        }
    },
    accountContainer: {
        textAlign: "left",
        "& .section-title": {
            margin: "1rem 0 2rem",
            fontWeight: "500",
            color: theme.customize.color,
            "&::after": {
                content: '""',
                margin: ".75rem 0 0",
                border: "none",
                borderRadius: ".125rem",
                backgroundColor: theme.customize.color,
                display: "block",
                height: ".25rem",
            }
        },
        "& .section-content": {
            backgroundColor: theme.customize.opacityColor,
            border: theme.customize.border,
            borderRadius: "5px",
        },
        "& .profile-section-content": {
            padding: "2.5rem 1rem 1.25rem",
            textAlign: "center",
            "& .user-image": {
                display: "inline-block",
                position: "relative",
                marginBottom: "1.5rem",
                "& .user-icon": {
                    width: "8rem",
                    height: "8rem",
                    display: "inline-block",
                    backgroundColor: grayColor[7],
                    borderRadius: "100%",
                    lineHeight: "8rem",
                    fontSize: "40px",
                    fontWeight: "bold",
                    color: whiteColor,
                    "& img.profile-picture": {
                        width: "100%",
                        height: "100%",
                        borderRadius: "100%",
                        verticalAlign: "top",
                        objectFit: "cover"
                    }
                },
            },
            "& .user-name": {
                fontSize: "1.75rem",
                lineHeight: "normal",
                wordBreak: "break-all",
                marginTop: "0px",
                marginBottom: ".75rem",
                color: theme.customize.color
            },
            "& .user-email": {
                marginBottom: "1.75rem",
                color: theme.customize.color,
                wordBreak: "break-all"
            }
        },
        "& .plans-section-content": {
            "&>div":{
                padding: "1.25rem",
            },
            "& .plan-header": {
                minHeight: "5.5rem",
                "& img": {
                    height: "2rem"
                },
                "& h4": {
                    margin: ".5rem 0 0",
                    fontSize: "1.25rem",
                }
            },
            "& .plan-body": {
                minHeight: "7.9rem",
                "& p": {
                    marginBottom: "0px"
                }
            }
        },
        "& .resources-section-content": {
            "& .resources-content": {
                display: "flex",
                alignItems: "center",
                marginBottom: theme.spacing(3),
                "& svg": {
                    marginRight: "10px",
                    width: "1.6em",
                    height: "1.6em",
                    fill: theme.customize.color
                },
                "& a": {
                    color: theme.customize.linksColor,
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                    fontWeight: "500",
                    "&:hover": {
                        textDecoration: "none"
                    }
                },
                "& p":{
                    marginBottom: "0px",
                    color: theme.customize.color
                }
            }
        },
        "& .tasks-section-content": {
            "& ul":{
                listStyleType: "none",
                padding: "0px",
                "& li":{
                    marginBottom: ".5rem",
                    "& a": {
                        color: theme.customize.linksColor,
                        fontSize: "1.125rem",
                        lineHeight: "1.75rem",
                        fontWeight: "500"
                    }
                }
            }
        }
    },
    profileContainer: {
        "& .userInfo": {
            marginTop: theme.spacing(2),
            "& input, & label, & label.MuiFormLabel-root:not(.Mui-error), & .MuiSelect-select": {
                color: theme.customize.color+" !important",
                textAlign: "left",
                "&.MuiFormControlLabel-root": {
                    width: "100%",
                    "& a": {
                        color: theme.customize.linksColor
                    }
                },
                "& span": {
                    color: theme.customize.color
                },
                "&[class*='labelRootError']": {
                    color: "#f44336 !important",
                }
            },
            "& .MuiInputBase-root.Mui-disabled": {
                backgroundColor: "transparent"
            },
            "& label.Mui-disabled span": {
                color: grayColor[12]
            },
            "& .saveButtonsBottom": {
                marginBottom: "0px",
            },
            "& .alignLeft": {
                textAlign: "left"
            }
        },
        "& .adManagement": {
            marginBottom: theme.spacing(2)
        },
        "& .saveButtons": {
            display: "flex",
            justifyContent: "space-between",
            "&.saveButtonsBottom": {
                marginBottom: theme.spacing(5)
            }
        },
        "& .editButton": {
            display: "flex",
            justifyContent: "flex-end",
            "&.editButtonBottom": {
                marginBottom: theme.spacing(5)
            }
        },
        "& .passwordCheck-notValid-customizable": {
            color: theme.customize.linksColor,
        },
        "& .form-error-message": {
            marginBottom: "10px",
        },
        "& .footer-buttons": {
            marginTop: "20px"
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginRight: "10px",
            "& svg": {
                color: theme.customize.iconsColor,
                fill: theme.customize.iconsColor,
            }
        },
        "&.validate": {
            "& .viewPasswordIcon": {
                marginRight: "32px"
            },
        }
    },
    accountsContainer: {
        "& .accounts-section": {
            border: "1px solid",
            "& h4": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                padding: "10px",
                marginTop: "0px",
                textAlign: "left",
                fontWeight: "500",
                color: whiteColor,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            },
        },
        "& .accounts-content": {
            padding: "10px",
            "& ul": {
                listStyleType: "none",
                paddingLeft: "0px",
                textAlign: "left",
                "& span": {
                    display: "inline-block",
                    fontWeight: "500",
                    width: "30%",
                }
            },
            "& .rt-tbody .rt-td, .rt-thead .rt-th": {
                textAlign: "left"
            },
            "& .td_actions": {
                "& button": {
                    padding: "0px",
                    margin: "0px",
                    textTransform: "capitalize"
                }
            },
            "& .rt-tbody": {
                "& .td_state": {
                    textTransform: "capitalize"
                }
            }
        },
    },
    privacyContainer: {
        "& .privacy-section": {
            textAlign: "left",
            "& h4": {
                color: theme.customize.color
            },
            "& p": {
                color: theme.customize.color
            },
            "& ul": {
                paddingLeft: "30px",
                "& li::marker": {
                    color: theme.customize.color
                },
                "& a": {
                    color: theme.customize.linksColor,
                }
            },
            "& .delete-account": {
                backgroundColor: theme.customize.opacityColor,
                padding: theme.spacing(3),
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& p": {
                    marginBottom: "0px",
                    wordBreak: "break-word",
                }
            }
        }
    },
    exportContainer: {
        "& .export-section": {
            textAlign: "left",
            "& .error": {
                "& svg": {
                    borderColor: dangerColor[0],
                },
                "& .MuiFormControlLabel-label": {
                    //color: dangerColor[0]
                }
            },
            "& p": {
                color: theme.customize.color
            }
        },
        "& input, & label, & label.MuiFormLabel-root:not(.Mui-error), & .MuiSelect-select": {
            color: theme.customize.color+" !important",
            textAlign: "left",
            "&.MuiFormControlLabel-root": {
                width: "100%",
                "& a": {
                    color: theme.customize.linksColor
                }
            },
            "& span": {
                color: theme.customize.color
            }
        },
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: "transparent"
        },
        "& .request-button": {
            padding: theme.spacing(0, 5, 5, 0),
            display: "flex",
            justifyContent: "flex-end",
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(0, 0),
            }
        },
        "& .custom-select": {
            maxWidth: "150px",
            width: "100%",
            marginTop: "0px !Important",
            "& svg": {
                color: theme.customize.color
            }
        },
        "& .size-section": {
            display: "flex",
            alignItems: "flex-start",
            "& ul": {
                listStyleType: "none",
                paddingLeft: "30px",
                "& li": {
                    color: theme.customize.color
                }
            },
            [theme.breakpoints.down("xs")]: {
                display: "block",
                "& ul": {
                    paddingLeft: "0px"
                }
            }
        },
        "& .date-range-section": {
            display: "flex",
            alignItems: "center",
            "& >*": {
                width: "auto !important",
                paddingTop: "0px !important",
                marginBottom: "0px !important",
                marginRight: "30px",
                "&:last-child": {
                    marginRight: "0px"
                }
            },
            [theme.breakpoints.down("xs")]: {
                display: "block",
                marginBottom: "10px",
                "& >*": {
                    width: "100% !Important",
                }
            }
        }
    },
    downloadContainer: {
        "& .download-section": {
            textAlign: "left",
            "& h4, & p, & li": {
                color: theme.customize.color
            }
        },
        "& .download-detail": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            "& ul": {
                listStyleType: "none",
                paddingLeft: "0px",
                "& span": {
                    fontWeight: "bold"
                }
            },
            "& button": {
                padding: "0px",
                margin: "0px",
                color: theme.customize.linksColor,
                textTransform: "none",
                fontSize: "16px",
                "& svg": {
                    marginLeft: "5px",
                    width: "22px",
                    height: "22px",
                }
            }
        },
        "& .download-files": {
            "& ul": {
                paddingLeft: "0px",
                listStyleType: "none",
                marginBottom: "0px",
                "&.download-files-lists>li": {
                    borderTop: "1px solid "+theme.customize.borderColor,
                    paddingTop: "10px",
                    paddingBottom: "10px"
                },
                "& ul": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "& Button": {
                        padding: "0px",
                        margin: "0px",
                        marginLeft: "5px",
                        marginTop: "-3px",
                        color: theme.customize.linksColor,
                        "& svg": {
                            width: "22px",
                            height: "22px"
                        }
                    },
                    "& img": {
                        width: "36px",
                        height: "36px",
                        marginRight: "10px"
                    }
                }
            }
        }
    },
    customTabs: {
        "& .card": {
            backgroundColor: "transparent",
            marginLeft: "0px",
            marginRight: "0px",
            boxShadow: "none",
        },
        "& .card-header": {
            marginLeft: "0px",
            marginRight: "0px",
            boxShadow: "none",
            background: "rgba(0, 0, 0, 0.5)",
            padding: "10px 0px",
        }
    },
    usersContainer: {
        marginTop: "50px",
        maxWidth: "100%",
        width: "100%",
        maxHeight: "500px",
        overflow: "auto",
        minHeight: "500px",
        "& .td_actions": {
            "& button": {
                paddingTop: "0px",
                paddingBottom: "0px",
                margin: "0px",
                textTransform: "capitalize"
            }
        },
        "& .hd_actions": {
            textAlign: "center !important"
        }
    },
    popupContent: {
        color: blackColor,
        width: "300px",
        borderRadius: "8px",
        "& .top-content": {
            padding: "20px 30px 0px",  
            [theme.breakpoints.down("md")]: {
                padding: "20px 20px 0px",  
            },
            textAlign: "Center",
            "& .user-manage": {
                border: "1px solid "+ theme.customize.borderColor
            },
            "& .user-image": {
                marginBottom: "0px",
                display: "inline-block",
                position: "relative",
                "& .uploadPhotoInput": {
                    "&  .MuiFormControl-root":{
                        margin: "0px",
                        padding: "0px",
                        "& .MuiInput-formControl":{
                            "&:before": {
                                display: "none"
                            }
                        },
                        "& input": {
                            display: "none"
                        }
                    }
                },
                "& button": {
                    position: "absolute",
                    backgroundColor: whiteColor,
                    bottom: "0px",
                    right: "0px",
                    padding: "5px",
                    width: "auto",
                    height: "auto",
                    minWidth: "auto",
                    margin: "0px",
                    "& svg": {
                        width: "16px",
                        height: "16px",
                        color: grayColor[7],
                        fill: grayColor[7]
                    }
                },
                "& .user-icon": {
                    width: "80px",
                    height: "80px",
                    lineHeight: "80px",
                    fontSize: "40px",
                    fontWeight: "bold",
                    "& .sd_loader": {
                        height: "80px"
                    },
                }
            },
            "& h4": {
                margin: "0px",
                lineHeight: "normal",
            },
            "& p": {
                marginBottom: "5px",
            },
            "& .stats-section": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
                marginTop: "10px",
            },
            "& .left-stats": {
                marginRight: "30px",
                "& .graph-stats": {
                    // width: "70px",
                    height: "70px",
                    //border: "1px solid "+grayColor[11],
                    margin: "0px auto",
                    borderRadius: "100%",
                    "& span": {
                        width: "100%",
                        height: "100%",
                        display: "block",
                        backgroundColor: theme.customize.buttonsColor,
                        borderRadius: "100%",
                        border: "3px solid "+theme.customize.borderColor,
                    },
                    "& .ct-chart": {
                        width: "67px",
                        height: "67px",
                        margin: "0px auto",
                        "& .ct-label": {
                            fill: whiteColor
                        },
                        "& .ct-series-b .ct-slice-pie": {
                            fill: grayColor[13]
                        },
                        "& .ct-series-a .ct-slice-pie": {
                            fill: theme.customize.iconsColor
                        }
                    }
                },
                "& .data-detail": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "6px",
                    "& span": {
                        fontSize: "12px",
                        lineHeight: "normal",
                        "&.used-data": {
                            color: theme.customize.linksColor,
                            marginRight: "5px",
                            paddingRight: "5px",
                            borderRight: "1px solid "+theme.customize.borderColorModal,
                        },
                    }
                }
            },
            "& .right-stats": {
                maxWidth: "110px",
                width: "100%",
                "& ul":{
                    listStyleType: "none",
                    padding: "0px",
                    margin: "0px",
                    "& ul": {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "12px",
                        lineHeight: "normal",
                        marginBottom: "4px",
                        "& .stat-name": {
                            display: "flex",
                            "& span": {
                                width: "12px",
                                height: "12px",
                                display: "inline-block",
                                marginRight: "5px",
                                backgroundColor: grayColor[7],
                                "&.web": {
                                    backgroundColor: "#17965b",
                                },
                                "&.outlook": {
                                    backgroundColor: "#4374dd",
                                },
                                "&.app": {
                                    backgroundColor: "#7f3897",
                                },
                                "&.uploads": {
                                    backgroundColor: "#4071e7",
                                },
                                "&.my_docs": {
                                    backgroundColor: "#ff0303",
                                }
                            }
                        }
                    }
                }
            },
            "& .signup-btn": {
                marginBottom: "-25px",
            },
        },
        "& .bottom-content": { 
            "& ul": {
                display: "flex",
                padding: "0px",
                listStyleType: "none",
                justifyContent: "center",
                borderTop: "1px solid "+grayColor[11],
                paddingTop: "25px",
                paddingBottom: "10px",
                margin: "0px",
                "& >li:first-child": {
                    "&:after": {
                        content: '""',
                        width: "3px",
                        height: "3px",
                        borderRadius: "100%",
                        backgroundColor: blackColor,
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginTop: "2px",
                        marginLeft: "10px",
                        marginRight: "10px"
                    }
                },
                "& a": {
                    color: blackColor,
                    fontSize: "12px",
                    "&:hover": {
                        textDecoration: "none"
                    }
                }
            }
        },
    },
    popoverRoot: {
        "& .MuiPaper-root": {
            left: "auto !important",
            right: "40px !important",
            transformOrigin: "unset !important"
        }
    },
    userFirstLetter: {
        "& .user-icon": {
            width: "32px",
            height: "32px",
            backgroundColor: grayColor[7],
            color: whiteColor,
            borderRadius: "100%",
            border: "none",
            fontSize: "16px",
            lineHeight: "32px",
            display: "inline-block",
            "& img.profile-picture": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "100%",
                verticalAlign: "top"
            },
        }
    },
    rulesContainer: {
        "& .td_emails": {
            "& span": {
                display: "block"
            }
        },
    },
    businessInfoContainer: {
        "& .user-image": {
            marginBottom: "0px",
            display: "inline-block",
            position: "relative",
            "& .uploadPhotoInput": {
                "&  .MuiFormControl-root":{
                    margin: "0px",
                    padding: "0px",
                    "& .MuiInput-formControl":{
                        "&:before": {
                            display: "none"
                        }
                    },
                    "& input": {
                        display: "none"
                    }
                }
            },
            "& button": {
                "& svg": {
                    width: "16px",
                    height: "16px",
                    color: grayColor[7]
                }
            },
            "& .user-icon": {
                width: "190px",
                height: "60px",
                fontSize: "40px",
                fontWeight: "bold",
                borderRadius: "0px",
                display: "block",
                "& .sd_loader": {
                    height: "60px"
                },
                "&.user-picture": {
                    fontSize: "0px",
                    width: "auto",
                    height: "auto",
                    background: "transparent",
                    borderRadius: "0px",
                    lineHeight: "normal",
                    "& img": {
                        borderRadius: "0px",
                        maxHeight: "60px",
                        width: "auto",
                        height: "auto"
                    },
                    "&+.uploadPhotoInput": {
                        marginTop: "30px",
                    }
                },
            },
            "&.background-image": {
                "& .user-icon": {
                    width: "200px",
                    height: "100px",
                    borderRadius: "0px",
                    "& .sd_loader": {
                        height: "100px"
                    },
                    "&.user-picture": {
                        "& img": {
                            maxHeight: "100px",
                        },
                    }
                },
            },
            "& .photos-action": {
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
            }
        },
        "& .logo-note": {
            marginBottom: "50px",
            color: theme.customize.color
        },
        "& .logo-title": {
            color: theme.customize.color
        }
    },
    editor: {
        ...editor(theme)
    },
    aboutLabel: {
        display: "block",
        textAlign: "left",
        marginBottom: "5px",
        marginTop: "17px",
        color: theme.customize.color
    },
    alignItems: {
        alignItems: "center"
    },
    filterGridItem: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        "& h4": {
            marginRight: "40px",
        },
        [theme.breakpoints.down("xs")]: {
            "& h4": {
                marginRight: "10px",
            },
        }
    },
    filterGridItemSm: {
        [theme.breakpoints.down("sm")]: {
            display: "block"
        }
    },
    sharedFilters: {
        listStyleType: "none",
        padding: "0px",
        margin: "10px 0px",
        "& li": {
            display: "inline-block",
            cursor: "pointer",
            "&+li": {
                marginLeft: "20px"
            },
            "& img": {
                marginRight: "5px",
                width: "18px"
            },
            "&.active": {
                "& span": {
                    paddingBottom: "2px",
                    borderBottom: "3px solid "+theme.customize.linksColor
                }
            }
        }
    },
    flexItems: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    downloadStyle: {
        textAlign: "left",
        "& $label": {
            color: theme.customize.color
        }
    },
    businessAdsContainer: {
        "& .photo-section-bottom": {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginTop: "-15px",
            "& .note": {
                marginTop: "0px",
                textAlign: "left",
                marginBottom: "0px",
            },
        },
        "& .photo-section": {
            position: "relative",
            "&>img": {
                position: "absolute",
                left: "0px",
                top: "34px",
                width: "40px",
                height: "40px",
                objectFit: "cover",
            },
            "& input": {
                paddingLeft: "45px",
            }
        }
    },
    itemCount: {
        float: "right"
    },
    linkFilters: {
        listStyleType: "none",
        padding: "0px",
        margin: "10px 0px",
        "& li": {
            display: "inline-block",
            cursor: "pointer",
            "&+li": {
                marginLeft: "20px"
            },
            "& img": {
                marginRight: "5px",
                width: "18px"
            },
            "&.active": {
                "& span": {
                    paddingBottom: "2px",
                    borderBottom: "3px solid "+theme.customize.linksColor
                }
            }
        }
    },
    viewType: {
        margin: "10px 0px",
    },
    fileActions: { 
        textAlign: "right",
        padding: "5px 20px",
        marginTop: "10px",
        backgroundColor: theme.customize.opacityColor,
        border: theme.customize.border,
        "& svg": {
            fill: theme.customize.iconsColor,
            color: theme.customize.iconsColor,
            width: "1em",
            display: "inline-block",
            verticalAlign: "middle",
            fontSize: "1.5rem",
            marginRight: "5px"
        },
        "& li": {
            "& button": {
                textTransform: "none",
                margin: "0px",
                padding: "6px 8px 4px 5px",            
                fontSize: "inherit",
                "&:hover": {
                    background: theme.customize.sidebarMenuHoverColor,
                }
            }
        },
        "& li + li": {
            "&:before": {
                content: "'|'",
                display: "inline-block",
                marginRight: "20px"
            }
        }
    },
    fileActionEnabled: {
        color: theme.customize.iconsColor
    },
    archivesTable: {
        "& .rt-tbody": {
            overflow: "unset",
            minHeight: "310px",
            "& .rt-tr-group": {
                flexGrow: "0"
            }
        },
    },
    archivesContainer: {
        maxWidth: "99%",
        width: "100%",
        maxHeight: "370px",
        minHeight: "370px",
    },
    stickyRow: {
        "& .rt-tbody": {
            paddingTop: "0px",
            position: "relative",
            "& .rt-tr-group.frozen_row": {
                position: "absolute",
                top: "0px",
                width: "100%",
                left: "0px",
                display: "none"
            }
        }
    },
    snackErrorMessage: {
        "& span": {
            color: whiteColor
        }
    },
    shareViewContent: {
        width: "95%",
        margin: "0px auto",
        "& $filesTable": {
            "& .hd_check": {
                width: "30px !important",
                textAlign: "left !important",
                flex: "30 0 auto !Important",
                padding: "0px",
            },
            [theme.breakpoints.down("xs")]: {
                padding: "10px 0px"
            },
        },
        "& $breadcrumbs": {
            maxWidth: "100%",
            padding: "5px 0px",
        },
        "& $filesContainer": {
            maxWidth: "100%",
        },
        "& $controls": {
            "& >.MuiGrid-item": {
                paddingLeft: "0px",
                paddingRight: "0px"
            }
        },
        "& $controlButtons": {
            "& button.MuiButton-text": {
                paddingLeft: "0px",
                paddingRight: "0px",
                marginLeft: "0px",
                marginRight: "0px"
            }
        }
    },
    loading: {
        display: "none"
    },
    adminNote: {
        textAlign: "left",
        fontSize: "13px",
        margin: "0px"
    },
    archivesFiles: {
        paddingTop: "20px",
        maxWidth: "650px",
        "& .files-table": {
            display: "block",
            "& h5": {
                textAlign: "left",
                fontWeight: "500",
                color: theme.customize.headingsColor,
            },
            "& ul": {
                paddingLeft: "10px",
                listStyleType: "none",
            },
            "& .file-list": {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: "5px",
                "& li": {
                    flex: '25%',
                    textAlign: 'left',
                    fontSize: "14px",
                    "&:first-child": {
                        flex: "50%"
                    },
                    "& button": {
                        padding: "0px",
                        textTransform: "capitalize",
                        fontSize: "14px"
                    }
                }
            }
        }
    },
    gridTableData: {
        display: "flex",
        flexWrap: "wrap",
        rowGap: "22px",
        columnGap: "22px",
        alignItems: "flex-start",
        [theme.breakpoints.down("xs")]: {
            columnGap: "6%",
        },
    },
    gridDataItem: {
        maxWidth: "166px",
        width: "100%",
        overflow: "hidden",
        borderRadius: "5px",
        position: "relative",
        "&:hover": {
            boxShadow: "6px 6px 8px 0px #00000040",
            "& .checked-icon": {
                display: "block"
            }
        },
        "& .file-content": {
            borderRadius: "5px",
            background: theme.customize.gridBgColor,
            overflow: "hidden",
            "& .checked-icon": {
                border: "1px solid "+theme.customize.brandColor,
                width: "12px",
                height: "12px",
                display: "none",
                position: "absolute",
                top: "8px",
                right: "8px",
                backgroundColor: whiteColor,
                borderRadius: "100%",
                zIndex: "1",
                "& svg": {
                    display: "none"
                }
            },
            "&.checked": {
                border: "2px solid "+theme.customize.brandColor,
                "& .thumbnail-icon": {
                    height: "94px"
                },
                "& .filename-section": {
                    padding: "1px 8px"
                },
                "& .checked-icon": {
                    backgroundColor: theme.customize.brandColor,
                    borderColor: theme.customize.brandColor,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& svg": {
                        display: "block",
                    }
                }
            },
        },
        "& .filename-section": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: theme.customize.gridTextBgColor,
            padding: "2px 8px",
            "& svg": {
                color: theme.customize.gridIconColor,
                fill: theme.customize.gridIconColor
            }
        },
        "& .filename": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "80%",
            whiteSpace: "nowrap",
            lineHeight: "normal",
            fontSize: "12px",
            color: theme.customize.gridTextColor,
            textAlign: "left",
            "& a": {
                color: theme.customize.gridTextColor,
                textDecoration: "none"
            },
        },
        "& .file-menu-icon": {
            cursor: "pointer"
        },
        "& .thumbnail-icon": { 
            height: "96px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            "& button": {
                margin: "0px",
                padding: "0px",
                cursor: "auto"
            },
            "& svg, & img": {
                width: "60px !important",
                height: "60px !important",
                objectFit: "contain"
            },
            "& img.thumbnail": {
                width: "100% !important",
                objectFit: "cover",
                height: "96px !important",
            }
        }
    },
    recentFilesSlider: {
        width: "100%",
        "& .slick-arrow": {
            display: "none !important",
            width: "20px !important",
            "&.slick-prev": {
                left: "-8"
            },
            "&.slick-next": {
                right: "-8"
            }
        },
        "& .slick-dots": {
            margin: "0px",
            bottom: "-40px"
        },
        "& .slick-track": {
            minWidth: "100%",
        },
        "& .slick-prev.slick-disabled":{
            "&+.slick-list": {
                "& .slick-track": {
                    transform: "translate3d(0, 0, 0) !important",
                }
            }
        }
    },
    countdown: {
        background: infoColor[0],
        padding: "5px 10px",
        fontSize: "16px",
        borderRadius: "30px",
        marginLeft: "5px"
    },
    driveActions: {
        textAlign: "left",
        "& h4": {
            dispaly: "block",
            paddingLeft: "30px"
        },
        "& svg": {
            fill: theme.customize.iconsColor
        }
    },
    createMenu: {
        "& .MuiMenu-paper": {
            backgroundColor: theme.customize.menuBgColor,
            border: "1px solid"+theme.customize.menuBorderColor,
            boxShadow: theme.customize.menuBoxShadowColor,
            minWidth: "150px",
        },
        "& .MuiMenuItem-root": {
            fontSize: "12px",
            minHeight: "auto",
            "&.folder-menu": {
                color: theme.customize.gridTextColor
            },
            "&.word-menu": {
                color: "#0078D4",
            },
            "&.excel-menu": {
                color: "#138147",
            },
            "&.powerpoint-menu": {
                color: "#C03B1B",
            },
            "& $fileIcon": {
                width: "18px",
                padding: "0px"
            }
        }
    },
    fileActionsDropdown: {
        "& .MuiMenu-paper": {
            zIndex: "3",
            backgroundColor: theme.customize.menuBgColor,
            cursor: "pointer",
            minWidth: "160px",
            textAlign: "left",
            boxShadow: theme.customize.menuBoxShadowColor,
            borderRadius: "5px",
            padding: "5px",
            overflow: "hidden",
        },
        "& .MuiList-root": {
            padding: "0px"
        },
        "& .MuiMenuItem-root": {
            padding: "5px 10px",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            minHeight: "auto",
            color: theme.customize.gridTextColor,
            "& $fileIcon": {
                padding: "0px"
            },
            "& svg": {
                width: "15px",
                marginRight: "7px",
                maxHeight: "15px",
                color: theme.customize.gridTextColor,
                fill: theme.customize.gridTextColor,
            },
        },
    },
    fileActionsMenu: {
        display: "flex",
        alignItems: "center",
        columnGap: "19px",
        "& button": {
            margin: "0px",
            backgroundColor: theme.customize.horizontalMenuBgColor,
            borderRadius: "10px",
            fontSize: "16px",
            fontWeight: "800",
            color: theme.customize.horizontalMenuTextColor,
            opacity: "1",
            textTransform: "none",
            padding: "11px 17px",
            "& svg": {
                marginRight: "10px"
            },
            "& svg, path": {
                fill: theme.customize.horizontalMenuIconsColor,
                color: theme.customize.horizontalMenuIconsColor,
            },
            "&:hover": {
                backgroundColor: theme.customize.horizontalActionsBgHoverColor,
                color: whiteColor,
                "& svg, path": {
                    fill: whiteColor,
                    color: whiteColor,
                },
            },
            "&:focus": {
                backgroundColor: theme.customize.horizontalMenuBgColor,
                "& svg, path": {
                    fill: theme.customize.horizontalMenuIconsColor+" !important",
                    color: theme.customize.horizontalMenuIconsColor+" !important",
                },
            },
            "&.more-button": {
                "& svg": {
                    margin: "0px",
                }
            }
        },
        [theme.breakpoints.down("sm")]: {
            columnGap: "10px",
        },
        [theme.breakpoints.up("sm")]: {
            "& button": {
                "&.more-button": {
                    display: "none"
                }
            }
        },
        [theme.breakpoints.down("xs")]: {
            "& button": {
                fontSize: "14px",
                padding: "8px 11px",
                "&.delete-button, &.download-button": {
                    display: "none"
                }
            }
        }
    },
    breadcrumbsActionsLayouts: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
        marginTop: "40px",
        minHeight: "45px",
        columnGap: "20px",
        "& .file-actions-layouts": {
            display: "flex",
            alignItems: "center",
        },
        "& $breadcrumbs": {
            padding: "0px",
        },
        [theme.breakpoints.down("md")]: {
            display: "block",
            "& .file-actions-layouts": {
                marginTop: "8px",
                justifyContent: "flex-end",
                minHeight: "45px"
            },
        },
        [theme.breakpoints.down("xs")]: {
            "& .file-actions-layouts": {
                minHeight: "37px"
            },
        }
    },
    filesLayoutType: {
        textAlign: "right",
        maxWidth: "100%",
        marginLeft: "20px",
        "& button": {
            padding: "0px",
            "&:last-child": {
                marginRight: "0px",
            }
        },
        "& svg": {
            width: "28px",
            height: "28px",
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft: "15px",
            "& svg": {
                width: "18px",
                height: "18px",
            },
        }
    },
    fileDropdownMenu: {
        backgroundColor: "red"
    },
    recentFiles: {
        marginTop: "40px",
        "& .recent-files-title": {
            display: "flex",
            alignItems: "center",
            columnGap: "5px",
            marginBottom: "17px",
            cursor: "pointer",
            "& h3": {
                fontSize: "16px",
                fontWeight: "600",
                margin: "0px",
                color: theme.customize.color
            },
            "& svg": {
                display: "block",
                fill: theme.customize.color,
                color: theme.customize.color
            }
        }
    },
    snackbar: {
        "& .MuiPaper-root": {
            backgroundColor: blackColor
        }
    },
    tableGridItem: {
        "&.table-grid": {
            [theme.breakpoints.down("xs")]: {
                padding: "0px"
            }
        }
    },
    textContainer: {
        textAlign: "left",
        "& h5": {
            fontWeight: "500",
            color: theme.customize.headingsColor
        },
        "& p": {
            "& span": {
                fontWeight: "500"
            }
        }
    },
    securityContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(8),
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "15px",
        alignItems: "flex-start",
        "& .item": {
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
            width: "100%",
            padding: "10px",
            backgroundColor: theme.customize.horizontalMenuBgColor,
            borderRadius: "5px",
            "& .title": {
                fontWeight: "500",
                marginBottom: "0px",
                textAlign: "left"
            },
            "& .description": {
                marginBottom: "0px",
                textAlign: "left"
            },
            "& .item-content": {
                maxWidth: "500px"
            },
            "& .item-actions": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "& .buttons": {
                    display: "flex",
                    columnGap: "10px"
                },
                "& .MuiFormControlLabel-root": {
                    display: "block",
                    textAlign: "right",
                    margin: "0px",
                    "& .MuiSwitch-root": {
                        width: "auto"
                    }
                },
            },
            '& .auth-keys': {
                paddingLeft: "0px",
                listStyleType: "none",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginBottom: "0px",
                "& li": {
                    padding: "10px",
                    background: theme.customize.backgroundColor,
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "& button": {
                        margin: "0px",
                        padding: "0px",
                        width: "auto",
                        height: "auto",
                    },
                    "& p": {
                        textTransform: "capitalize",
                        margin: "0px",
                    },
                }
            }
        },
    }
});

export default userHomePageStyle;
