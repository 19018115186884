import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api.js";
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth.js";
import BackgroundSlider from "../../components/Slider/BackgroundSlider.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import TwoFaVerificationModal from "../../components/User/TwoFaVerificationModal.js";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "../../components/CustomButtons/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
import LoaderComponent from '../../components/Loader'
import CreateSecurityModal from "../../components/User/CreateSecurityModal.js";
import DeleteConfirmationModal from "../../components/User/DeleteConfirmationModal";
import BackupCodesModal from "../../components/User/BackupCodesModal.js";
import Switch from "@material-ui/core/Switch";

const Security = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        this.state = {
            orignalValues: values,
            values: values,
            savingInfo: false,
            showError: false,
            errorMessage: "",
            validation: {
                cell: '',
                isValid: false
            },
            saving: false,
            activeTab: 0,
            phoneVerificationModal: false,
            verificationError: "",
            verificationSuccess: false,
            createSecurityModal: false,
            creatingSecurity: false,
            securityRegistered: false,
            securityError: false,
            securityErrorMessage: '',
            credential: null,
            security: null,
            deletingSecurity: false,
            deleteModal: false,
            backupCodesModal: false,
        }
        this.newUser = null;
        this.saveUserInfo = this.saveUserInfo.bind(this);   
        this.handleSubmit = this.handleSubmit.bind(this);     
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }     
    }
    saveUserInfo(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({
            savingInfo: true,
            showError: false
        });
        this.updateUser();
    }
    validateForm(){
        const { values } = this.state;
        let validation = {
            cell: "",
            isValid: true
        };

        const cellRegex = /^[0-9]{9,15}$/;
        if(!cellRegex.test(values.cell)){
            validation.cell = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    updateUser(){
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.values);
        values.skipValidation = 1;

        const source = axios.CancelToken.source();
        Api.updateUser(user.id, values, source).then((data) => {
            this.newUser = data.user;
            if(data.requireCellVerification){
                this.setState({phoneVerificationModal: true, savingInfo: false});
            }else{
                this.setState({
                    savingInfo: false, 
                });
                localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
                let newUser = Api.prepareMemberObject(data.user);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
            }
            
        }).catch(err => {
            this.setState({
                savingInfo: false, 
                showError: true, 
                errorMessage: err.message
            });
        });
    }
    handleCellChange(e, name) {
        const { cell } = this.state.values;
        this.tryValidation = true;
        let state = {};
        const value = e.target.value;
        state[name] = value;
        const regex = /^\d+$/;
        if((value.length > 0 && !regex.test(value)) || value.length > 16){
            state[name] = cell;
        }
        this.setState({
            values: {
                ...this.state.values, 
                [name]: state[name]
            }
        });
    }
    handle2FaCheckbox(e, name){
        this.setState({
            values: {
                ...this.state.values, 
                [name]:  e.target.checked ? 1 : 0
            }
        });
    }
    handleCheckbox(e, name){
        this.setState({
            values: {
                ...this.state.values, 
                [name]:  e.target.checked ? 1 : 0
            }
        },() => {
            if(name === 'enable_2fa'){
                const isValid = this.validateForm();
                if(!isValid){
                    this.setState({
                        values: {
                            ...this.state.values, 
                            [name]: 0
                        }
                    })
                    return;
                }
            }
            this.handleSubmit(e);
        });
    }
    handleSubmit() {        
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.values);
        values.skipValidation = 1;

        const source = axios.CancelToken.source();
        Api.updateUser(user.id, values, source).then((data) => {
            this.newUser = data.user;
            if(data.requireCellVerification){
                this.setState({phoneVerificationModal: true, savingInfo: false});
            }else{
                localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
                let newUser = Api.prepareMemberObject(data.user);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
            }
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message
            });
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    onTwoFaSuccess(code){
        this.setState({
            saving: true
        });

        const requestData = {
            code: code
        };

        const source = axios.CancelToken.source();
        Api.verifyCode(requestData, source).then((data) => {
            this.newUser = data.user;
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(this.newUser));
            this.setState({
                saving: false,
                verificationError: "",
                verificationSuccess: true
            });
        }).catch(err => {
            this.setState({
                saving: false, 
                verificationError: err.message
            });
        });
    }
    resendCode(){
        const requestData = {};
        const source = axios.CancelToken.source();
        Api.resendSmsCode(requestData, source).then((data) => {
            this.setState({
                saving: false, 
                showError: false, 
                cancelToken: null,
                errorMessage: null
            });
        }).catch(err => {
            this.setState({
                saving: false, 
                showError: true, 
                cancelToken: null,
                verificationError: err.message
            });
        });
        this.setState({saving: true, showError: false, cancelToken: source});
    }
    onTwoFaClose(){
        const { verificationSuccess } = this.state;
        this.setState({
            phoneVerificationModal: false,
            verificationSuccess: false,
            verificationError: ""
        });
        if(verificationSuccess){
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(this.newUser));
            let newUser = Api.prepareMemberObject(this.newUser);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }else{
            const { user } = this.store.getState();
            let values = Object.assign({}, this.state.values);
            values.enable_2fa = false;
            values.skipValidation = 1;
            
            const source = axios.CancelToken.source();
            Api.updateUser(user.id, values, source).then((data) => {
                localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
                let newUser = Api.prepareMemberObject(data.user);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
            }).catch(err => {
                //Silent
            });
        }
    }
    onTabChange(urlTab){
        if(urlTab === '0'){
            this.setState({activeTab:0})
            this.history.push("/user/security");
        }else if(urlTab === '1'){
            this.setState({activeTab:1})
            this.history.push("/user/accounts/license");
        }else if(urlTab === '2'){
            this.setState({activeTab:2})
            this.history.push("/user/password");
        }else if(urlTab === '3'){
            this.setState({activeTab:3})
            this.history.push("/user/account/recovery");
        }
    }
    oneEditSecurityModal(status = false, security = null){
        if(security === null && status === true){
            return;
        }
        this.setState({
            createSecurityModal: status,
            security: security,
            securityRegistered: true
        })
    }
    onCreateSecurityModal(status = false){
        this.setState({
            createSecurityModal: status,
            securityRegistered: false,
            credential: null,
            security: null,
            securityError: false,
        })
    }
    onDeleteModal(status = true, security = null){
        if(security === null && status === true){
            return;
        }
        this.setState({
            deleteModal: status,
            security: security
        });
    }
    onDeleteModalSuccess(){
        const { security } = this.state;
        
        this.setState({
            deletingSecurity: true
        })
       
        Api.deleteSecurityKey(security.id).then(data => {
            this.setState({
                deletingSecurity: false,
                security: null,
                deleteModal: false,
            })

            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
            let newUser = Api.prepareMemberObject(data.user);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            this.setState({
                deletingSecurity: false,
            })
            //Handle Error
        });
    }
    onBackupCodesModal(status = false, userData = null){
        this.setState({
            backupCodesModal: status,
        }, () => {
            if(status === false && userData !== null){
                localStorage.setItem("sendlinx_userinfo", JSON.stringify(userData));
                let newUser = Api.prepareMemberObject(userData);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
            }
        })
    }
    renderSecurityKeys(){
        const { user } = this.store.getState();

        let securityKeysData = [];
        if(user.securityData.length <= 0){
            return securityKeysData;
        }
                
        user.securityData.map((security, key) => {
            let keyData =  (
                <li key={key}>
                    <div><p>{ security.name }</p></div>
                    <div>
                        <Button justIcon color="transparent" title={"Edit Key"} onClick={() => this.oneEditSecurityModal(true, security)}>
                            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.37559 0.339355L5.5248 1.19014L7.80996 3.47529L8.66074 2.62451C9.1002 2.18506 9.1002 1.47314 8.66074 1.03369L7.96816 0.339355C7.52871 -0.100098 6.8168 -0.100098 6.37734 0.339355H6.37559ZM5.12754 1.5874L1.03008 5.68662C0.847266 5.86943 0.713672 6.09619 0.639844 6.34404L0.0175784 8.45869C-0.0263669 8.60811 0.0140627 8.76807 0.123047 8.87705C0.232031 8.98604 0.391992 9.02647 0.539649 8.98428L2.6543 8.36201C2.90215 8.28818 3.12891 8.15459 3.31172 7.97178L7.4127 3.87256L5.12754 1.5874Z" fill="#556DC2"/>
                            </svg>
                        </Button>
                        <Button justIcon color="transparent" title={"Delete"} onClick={() => this.onDeleteModal(true, security)} >
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.94141 1.25H10.5547C10.7695 1.25 10.9688 1.35938 11.0859 1.54297L11.6836 2.5H5.81641L6.41406 1.54297C6.52734 1.35938 6.73047 1.25 6.94531 1.25H6.94141ZM13.1602 2.5L12.1484 0.882812C11.8047 0.332031 11.2031 0 10.5586 0H6.94141C6.29688 0 5.69531 0.332031 5.35156 0.882812L4.33984 2.5H2.50391H1.25H0.625C0.28125 2.5 0 2.78125 0 3.125C0 3.46875 0.28125 3.75 0.625 3.75H1.33984L2.33594 17.6797C2.42578 18.9883 3.51562 20 4.82812 20H12.6719C13.9844 20 15.0703 18.9883 15.1641 17.6797L16.1602 3.75H16.875C17.2188 3.75 17.5 3.46875 17.5 3.125C17.5 2.78125 17.2188 2.5 16.875 2.5H16.25H14.9961H13.1602ZM14.9102 3.75L13.918 17.5898C13.8711 18.2422 13.3281 18.75 12.6719 18.75H4.82812C4.17188 18.75 3.62891 18.2422 3.58203 17.5898L2.59375 3.75H14.9062H14.9102Z" fill="#556DC2"/>
                            </svg>
                        </Button>
                    </div>
                </li>
            );

            securityKeysData.push(keyData);
            return null;
        });

        return securityKeysData;
    }
    registerSecurity = async () => {
        this.setState({
            creatingSecurity: true
        })

        const source = axios.CancelToken.source();
        let requestData = {};
        const data = await Api.securityRegisterChallange(requestData, source).then(data => {
            this.setState({
                creatingSecurity: false
            })

            return data;
        }).catch(err => {
            this.setState({
                creatingSecurity: false, 
                securityError: true, 
                securityErrorMessage: err.message
            });
        });
    
        const challenge = Uint8Array.from(atob(data.challenge), c => c.charCodeAt(0));

        const credential = await navigator.credentials.create({
            publicKey: {
                challenge,
                rp: { 
                    name: data.rp.name,
                    id: data.rp.id,
                },
                user: {
                    id: new TextEncoder().encode(data.user.id),
                    name: data.user.name,
                    displayName: data.user.displayName,
                },
                pubKeyCredParams: [{ alg: -7, type: "public-key" }],
                authenticatorSelection: { authenticatorAttachment: "cross-platform" },
            },
        });

        this.setState({
            credential: credential,
            securityRegistered: true
        })
    };
    handleResgiterSecurity(name){
        const { credential, security } = this.state;
        const source = axios.CancelToken.source();

        this.setState({
            creatingSecurity: true
        })

        let requestData = {};
        if(security !== null){
            requestData = {
                name: name,
                id: security.id
            }
        }else{
            const attestationObject = btoa(String.fromCharCode(...new Uint8Array(credential.response.attestationObject)));
            const clientDataJSON = btoa(String.fromCharCode(...new Uint8Array(credential.response.clientDataJSON)));
            const publicKey = btoa(String.fromCharCode(...new Uint8Array(credential.response.getPublicKey())));

            requestData = {
                name: name,
                security_id: credential.id,
                security_data: JSON.stringify({
                    attestationObject,
                    clientDataJSON,
                    publicKey
                }),
            };
        }

        Api.securityRegister(requestData, source).then(data => {
            this.setState({
                creatingSecurity: false,
                createSecurityModal: false,
                securityRegistered: false,
                credential: null
            })

            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
            let newUser = Api.prepareMemberObject(data.user);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            this.setState({
                creatingSecurity: false, 
                securityError: true, 
                securityErrorMessage: err.message
            });
        });
    }
    onDeleteBackupCodes(data){
        localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
        let newUser = Api.prepareMemberObject(data.user);
        this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
    }
    render() {
        const { classes } = this.props;
        const { values, validation, showError, activeTab, phoneVerificationModal, saving, verificationError, verificationSuccess,
            savingInfo, createSecurityModal, creatingSecurity, securityRegistered, securityError, securityErrorMessage, deletingSecurity, 
            deleteModal, security, backupCodesModal } = this.state;
        const { authorized, user } = this.store.getState();

        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        let disable2FA = false;
        if((authorized && user.user_type === "personal" && user.businessUser !== null)){
            disable2FA = true;
        }

        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.profileContainer}>
                    <div className={classes.content}>
                        <div className={classes.controls}>
                            <GridItem className={classes.controlsTabs}>
                                <div className='sd-custom-tabs'>
                                    <div className={'sd-custom-tab '+(activeTab === 0 ? 'active': '')} onClick={() => this.onTabChange("0")}>
                                        <span>Two Factor Authentication</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 2 ? 'active': '')} onClick={() => this.onTabChange("2")}>
                                        <span>Password</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 1 ? 'active': '')} onClick={() => this.onTabChange("1")}>
                                        <span>Connected Account(s)</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 3 ? 'active': '')} onClick={() => this.onTabChange("3")}>
                                        <span>Account Recovery</span>
                                    </div>
                                </div>
                            </GridItem>
                        </div>
                        <GridItem className={classes.main} xs={12}>
                            <div className={classes.securityContainer}>
                                <div className="item">
                                    {
                                        showError ?
                                            this.renderErrorMessages()
                                        :
                                            <></>
                                    }
                                    <div>
                                        <p className='title'>Phone Number Authentication</p>
                                        <p className='description'>Phone SMS codes are one-time-use security codes sent to your mobile phone via text message during the login process. They provide an added layer of protection by verifying your identity, ensuring that only you can access your account. The code is typically valid for a short time and must be entered promptly to complete your login.</p>
                                    </div>
                                    <div className='item-content'>
                                        <CustomInput
                                            success={validation.cell === "success"}
                                            error={validation.cell === "error"}
                                            verified={values.cell_verified}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: "custom-input body m-zero"
                                            }}
                                            id="input-cell"
                                            labelText="Cell"
                                            inputProps={{
                                                onChange: (e) => this.handleCellChange(e, 'cell'),
                                                name: "cell",
                                                value: values.cell,
                                            }}
                                        />
                                    </div>
                                    {
                                        authorized && user.user_type === "personal" && user.businessUser !== null ?
                                            <p className={classes.adminNote}>Please contact your administrator to enable or disable this feature.</p>
                                        :
                                            <></>
                                    }
                                    <div className="item-actions">
                                        <div className={"buttons"}>
                                            {
                                                savingInfo ?    
                                                    <Button size="sm" color="custom">
                                                        <LoaderComponent color="white" align="center" saving={true} />
                                                    </Button>
                                                :
                                                    <Button size="sm" color="custom" onClick={() => this.saveUserInfo()}>
                                                        Save
                                                    </Button>
                                            } 
                                        </div>
                                        <FormControlLabel
                                            classes={{ label: classes.label }}
                                            control={
                                                <Switch
                                                    checked={(values.enable_2fa ? true : false)}
                                                    onChange={(e) => this.handleCheckbox(e, 'enable_2fa')}
                                                    value="enable_2fa"
                                                    classes={{
                                                        switchBase: classes.switchBase,
                                                        checked: classes.switchChecked,
                                                        thumb: classes.switchIcon,
                                                        track: classes.switchBar
                                                    }}
                                                    disabled={disable2FA}
                                                />
                                            }
                                            label={""}
                                        />
                                    </div>
                                </div>
                                <div className='item'>
                                    <div>
                                        <p className='title'>Security Key Authentication</p>
                                        <p className='description'>Security keys are physical devices used to authenticate your login securely. When you insert or tap the key on your device, it verifies your identity, providing a strong layer of protection against unauthorized access. Security keys use advanced encryption methods and are resistant to phishing, making them one of the most secure ways to sign in to your accounts.</p>
                                    </div>
                                    <div className='item-content'>
                                        {
                                            user.securityData.length > 0 ?
                                                <ul className={'auth-keys'}>
                                                    { this.renderSecurityKeys() }
                                                </ul>
                                            :
                                                <></>
                                        }
                                    </div>
                                    <div className="item-actions">
                                        <div className={"buttons"}>
                                            <Button size="sm" color="custom" onClick={() => this.onCreateSecurityModal(true)}>
                                                Add Security Key
                                            </Button>
                                        </div>
                                        <FormControlLabel
                                            classes={{ label: classes.label }}
                                            control={
                                                <Switch
                                                    checked={(values.enable_security_key ? true : false)}
                                                    onChange={(e) => this.handleCheckbox(e, 'enable_security_key')}
                                                    value="enable_security_key"
                                                    classes={{
                                                        switchBase: classes.switchBase,
                                                        checked: classes.switchChecked,
                                                        thumb: classes.switchIcon,
                                                        track: classes.switchBar
                                                    }}
                                                />
                                            }
                                            label={""}
                                        />
                                    </div>
                                </div>
                                <div className='item'>
                                    <div>
                                        <p className='title'>Backup Codes</p>
                                        <p className='description'>Backup codes are one-time-use codes that provide an alternative way to access your account if you're unable to use your primary sign-in method (like two-factor authentication or a passkey). These codes can be saved securely and used in place of your regular login process when needed. Keep them in a safe place, as each code can only be used once.</p>
                                    </div>
                                    <div className="item-actions">
                                        <div className={"buttons"}>
                                            <Button size="sm" color="custom" onClick={() => this.onBackupCodesModal(true)}>
                                                {
                                                    user.hasOwnProperty('haveBackupCodes') && user.haveBackupCodes ? 
                                                        "View backup codes"
                                                    :
                                                        "Get backup codes"
                                                }
                                            </Button>
                                        </div>
                                        <FormControlLabel
                                            classes={{ label: classes.label }}
                                            control={
                                                <Switch
                                                    checked={(values.enable_backup_code ? true : false)}
                                                    onChange={(e) => this.handleCheckbox(e, 'enable_backup_code')}
                                                    value="enable_backup_code"
                                                    classes={{
                                                        switchBase: classes.switchBase,
                                                        checked: classes.switchChecked,
                                                        thumb: classes.switchIcon,
                                                        track: classes.switchBar
                                                    }}
                                                />
                                            }
                                            label={""}
                                        />
                                    </div>
                                </div>
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
                {
                    phoneVerificationModal ?
                        <TwoFaVerificationModal 
                            saving={saving}
                            open={true} 
                            onSuccess={(code) => this.onTwoFaSuccess(code)}
                            onClose={() => this.onTwoFaClose()}
                            errorMessage={verificationError}
                            success={verificationSuccess}
                            onResendCode={() => this.resendCode()}
                        />
                    :
                    null
                }
                {
                    createSecurityModal ?
                        <CreateSecurityModal
                            open={createSecurityModal}
                            saving={creatingSecurity}
                            securityRegistered={securityRegistered}
                            store={this.store} 
                            onClose={() => this.onCreateSecurityModal(false)}
                            onRegister={() => this.registerSecurity()}
                            onSubmit={(name) => this.handleResgiterSecurity(name)}
                            security={security}
                            error={securityError}
                            errorMessage={securityErrorMessage}
                        />
                    :
                        <></>
                }
                {
                    deleteModal ?
                        <DeleteConfirmationModal 
                            open={deleteModal} 
                            saving={deletingSecurity} 
                            onClose={() => this.onDeleteModal(false)} 
                            onSuccess={() => this.onDeleteModalSuccess()}
                            confirmationMessage="Are you sure you want to delete key?"
                        />
                    :
                        <></>
                }
                {
                    backupCodesModal ?
                        <BackupCodesModal
                            open={backupCodesModal}
                            store={this.store} 
                            onClose={(status, userData) => this.onBackupCodesModal(false, userData)}
                            onDeleteBackupCodes= {(userData) => this.onDeleteBackupCodes(userData)}
                        />
                    :
                        <></>
                }
            </div>
        )
    }
}

Security.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Security);
