import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import LoaderComponent from '../../components/Loader'
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import DatePicker from "../../components/CustomInput/DatePicker";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Link from '@material-ui/core/Link';
import TwoFaVerificationModal from "../../components/User/TwoFaVerificationModal";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const Profile = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        this.state = {
            orignalValues: values,
            values: values,
            editInfo: false,
            savingInfo: false,
            showError: false,
            errorMessage: "",
            validation: {
                firstName: '',
                lastName: '',
                email: '',
                timezone: '',
                cell: '',
                country_id: '',
                isValid: false
            },
            countries: [],
            states: [],
            cities: [],
            loadingCountries: false,
            loadingStates: false,
            loadingCities: false,
            phoneVerificationModal: false,
            saving: false,
            verificationError: "",
            verificationSuccess: false
        }
        this.newUser = null;
        this.editUserInfo = this.editUserInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveUserInfo = this.saveUserInfo.bind(this);
        
        this.timezoneOptions = Api.getTimezoneOptions();
        this.themeOptions = Api.getThemeOptions();
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }        
        if(authorized){
            this.loadCountries();
            if(this.state.values.country_id){
                this.loadStates(this.state.values.country_id, true)
            }
            if(this.state.values.state_id){
                this.loadCities(this.state.values.state_id, true)
            }
        }
    }
    editUserInfo(editInfo = true){
        let state = {
            editInfo: editInfo
        }

        if(editInfo === false){
            state['values'] = this.state.orignalValues;
        }
        this.setState(state);
    }
    saveUserInfo(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({
            editInfo: false, 
            savingInfo: true,
            showError: false
        });
        this.updateUser();
    }
    validateForm(){
        const { values } = this.state;
        let validation = {
            firstName: "success",
            lastName: "success",
            email: "success",
            timezone: "success",
            country_id: '',
            isValid: true
        };
        if(values.first_name.length <= 0){
            validation.firstName = "error";
            validation.isValid = false;
        }
        if(values.last_name.length <= 0){
            validation.lastName = "error";
            validation.isValid = false;
        }
        var emailRegex = /\S+@\S+\.\S+/;
        if(values.email.length <= 0 || !emailRegex.test(values.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        if(values.timezone.length <= 2){
            validation.timezone = "error";
            validation.isValid = false;
        }

        if((values.country_id.length <= 0 || values.country_id === "0" || values.country_id === 0)){
            validation.country_id = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    updateUser(){
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.values);

        const source = axios.CancelToken.source();
        Api.updateUser(user.id, values, source).then((data) => {
            this.newUser = data.user;
            if(data.requireCellVerification && false){
                this.setState({phoneVerificationModal: true, savingInfo: false});
            }else{
                this.setState({
                    savingInfo: false, 
                });
                localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
                let newUser = Api.prepareMemberObject(data.user);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
            }
        }).catch(err => {
            this.setState({
                savingInfo: false, 
                showError: true, 
                errorMessage: err.message
            });
        });
    }
    handleChange(event, name) {
        let value = event.target.value;
        let limit = 64;
        if(name === "email"){
            limit = 128;
        }
        if(typeof(value) === "string"){
            value = value.substr(0, limit);
        }
        if(name === "email"){
            value = value.toLowerCase();
        }

        this.setState({
            values: {
                ...this.state.values, 
                [name]: value
            }
        });
    }
    handleCellChange(e, name) {
        const { cell } = this.state.values;
        this.tryValidation = true;
        let state = {};
        const value = e.target.value;
        state[name] = value;
        const regex = /^\d+$/;
        if((value.length > 0 && !regex.test(value)) || value.length > 16){
            state[name] = cell;
        }
        this.setState({
            values: {
                ...this.state.values, 
                [name]: state[name]
            }
        });
    }
    handleCheckbox(e, name){
        this.setState({
            values: {
                ...this.state.values, 
                [name]:  e.target.checked ? 1 : 0
            }
        });
    }
    handle2FaCheckbox(e, name){
        this.setState({
            values: {
                ...this.state.values, 
                [name]:  e.target.checked ? 1 : 0
            }
        });
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                values: {
                    ...this.state.values, 
                    [name]: parsedDate.toISOString()
                }
           });
        }catch(e){ console.log(e); }        
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            that.setState({
                countries: data.response,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onCountryChange(e){
        const country = e.target.value;
        this.loadStates(country);
    }
    loadStates(country, onPageLoad = false){
        const { country_id } = this.state.values;
        if(country_id === country && onPageLoad === false){
            return;
        }
        let stateIdState = "";
        let cityIdState = "";
        if(onPageLoad === true){
            stateIdState = this.state.values.state_id;
            cityIdState = this.state.values.city_id;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            values: {
                ...this.state.values,
                country_id: country,
                state_id: stateIdState,
                city_id: cityIdState
            },
            cancelToken: source,
            loadingStates: true,
            states: [],
            cities: [],
        });
        Api.getStates(country, source).then(data => {
            that.setState({
                states: data.response,
                loadingStates: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onStateChange(e){
        const state = e.target.value;
        this.loadCities(state);
    }
    loadCities(state, onPageLoad = false){
        const { state_id } = this.state.values;
        if(state_id === state && onPageLoad === false){
            return;
        }

        let cityIdState = "";
        if(onPageLoad === true){
            cityIdState = this.state.values.city_id;
        }

        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        const { country_id } = this.state.values;
        that.setState({
            values: {
                ...this.state.values,
                state_id: state,
                city_id: cityIdState,
            },
            cancelToken: source,
            loadingCities: true,
            cities: [],
        });
        Api.getCities(country_id, state, source).then(data => {
            that.setState({
                cities: data.response,
                loadingCities: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onTwoFaSuccess(code){
        this.setState({
            saving: true
        });

        const requestData = {
            code: code
        };

        const source = axios.CancelToken.source();
        Api.verifyCode(requestData, source).then((data) => {
            this.newUser = data.user;
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(this.newUser));
            this.setState({
                saving: false,
                verificationError: "",
                verificationSuccess: true
            });
        }).catch(err => {
            this.setState({
                saving: false, 
                verificationError: err.message
            });
        });
    }
    resendCode(){
        const requestData = {};
        const source = axios.CancelToken.source();
        Api.resendSmsCode(requestData, source).then((data) => {
            this.setState({
                saving: false, 
                showError: false, 
                cancelToken: null,
                errorMessage: null
            });
        }).catch(err => {
            this.setState({
                saving: false, 
                showError: true, 
                cancelToken: null,
                verificationError: err.message
            });
        });
        this.setState({saving: true, showError: false, cancelToken: source});
    }
    onTwoFaClose(){
        const { verificationSuccess } = this.state;
        this.setState({
            phoneVerificationModal: false,
            verificationSuccess: false,
            verificationError: ""
        });
        if(verificationSuccess){
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(this.newUser));
            let newUser = Api.prepareMemberObject(this.newUser);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }else{
            const { user } = this.store.getState();
            let values = Object.assign({}, this.state.values);
            values.enable_2fa = false;
            const source = axios.CancelToken.source();
            Api.updateUser(user.id, values, source).then((data) => {
                localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
                let newUser = Api.prepareMemberObject(data.user);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
            }).catch(err => {
                //Silent
            });
        }
    }
    saveButtonDisabled(){
        const { orignalValues, values } = this.state;
        if(
            (orignalValues.first_name !== values.first_name) ||
            (orignalValues.last_name !== values.last_name) ||
            (orignalValues.email !== values.email) ||
            (orignalValues.timezone !== values.timezone) ||
            (orignalValues.account_name !== values.account_name) ||
            (orignalValues.dob !== values.dob) ||
            (orignalValues.phone !== values.phone) ||
            (orignalValues.country_id !== values.country_id) ||
            (orignalValues.state_id !== values.state_id) ||
            (orignalValues.city_id !== values.city_id) ||
            (orignalValues.address1 !== values.address1) ||
            (orignalValues.address2 !== values.address2) ||
            (orignalValues.address3 !== values.address3) ||
            (orignalValues.promotions !== values.promotions) ||
            (orignalValues.terms !== values.terms)
        ){
            return false
        }

        return true;
    }
    render() {
        const { classes } = this.props;
        const { editInfo, savingInfo, validation, values, showError, cities, states, countries, loadingCountries, loadingCities, 
            loadingStates, phoneVerificationModal, saving, verificationError, verificationSuccess } = this.state;
        const { authorized } = this.store.getState();

        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }

        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.profileContainer}>
                    <div className={classes.content}>
                        <GridItem className={classes.main} xs={12} sm={12} md={8} lg={6}>
                            <h3 className={classes.title}>Profile</h3>
                            <div>
                                {
                                    editInfo === false && savingInfo === false ?
                                        <div className={"editButton"}>
                                            <Button color="custom" onClick={() => this.editUserInfo()}>Edit</Button>
                                        </div>
                                    :
                                    savingInfo ? <LoaderComponent color="custom" align="left" /> : <></>
                                }                    
                                {
                                    editInfo === true ?
                                        <div className={"saveButtons"}>
                                            <Button color="white" onClick={() => this.editUserInfo(false)}>
                                                Cancel
                                            </Button>
                                            <Button color="custom" disabled={this.saveButtonDisabled()} onClick={() => this.saveUserInfo()}>
                                                Save
                                            </Button>
                                        </div>
                                    :
                                        <></>
                                }
                            </div>
                            <div className={"userInfo "+(editInfo === false?classes.viewInfo:'')}>
                                {
                                    showError ?
                                        this.renderErrorMessages()
                                    :
                                    <></>
                                }
                                <CustomInput
                                    success={validation.firstName === "success"}
                                    error={validation.firstName === "error"}
                                    id="outlined-firstName"
                                    labelText="First Name"                                    
                                    inputProps={{
                                        value: values.first_name,
                                        onChange: (e) => this.handleChange(e, 'first_name'),
                                        name: "first_name",
                                        disabled: !editInfo,
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                />
                                <CustomInput
                                    success={validation.lastName === "success"}
                                    error={validation.lastName === "error"}
                                    id="outlined-lastName"
                                    labelText="Last Name"                                    
                                    inputProps={{
                                        value: values.last_name,
                                        onChange: (e) => this.handleChange(e, 'last_name'),
                                        name: "last_name",
                                        disabled: !editInfo
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                />
                                <CustomInput
                                    success={validation.email === "success"}
                                    error={validation.email === "error"}
                                    id="input-email"
                                    labelText="Email"
                                    inputProps={{
                                        value: values.email ? values.email : "",
                                        onChange: (e) => this.handleChange(e, "email"),
                                        name: "email",
                                        disabled: !editInfo
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                />
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true,
                                        className: 'custom-input body'
                                    }}
                                    labelText="Timezone"
                                    selectProps={{
                                        onChange: (e) => this.handleChange(e,"timezone"),
                                        value: values.timezone,
                                    }}
                                    inputProps={{
                                        name: "timezone",
                                        id: "input-timezone",
                                        className: classes.alignLeft,
                                        disabled: !editInfo
                                    }}
                                    success={validation.timezone === "success"}
                                    error={validation.timezone === "error"}
                                    items={this.timezoneOptions}
                                    itemLabel="value"
                                    itemValue="key"
                                    color="custom"
                                />
                                <CustomInput
                                    id="input-accountName"
                                    labelText="Account Name"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e,'account_name'),
                                        name: "account_name",
                                        value: values.account_name,
                                        disabled: !editInfo
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                />
                                <DatePicker
                                    labelText={"Date of Birth"}
                                    id="input-dob"
                                    value={values.dob}
                                    disableFuture={true}
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    onChange={(date) => this.handleDateChange(date, 'dob')}
                                    inputProps={{
                                        disabled: !editInfo
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                />
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                    id="input-phone"
                                    labelText="Phone"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e, 'phone'),
                                        name: "phone",
                                        value: values.phone,
                                        disabled: !editInfo
                                    }}
                                />
                                {
                                    countries.length > 0 ?
                                        <CustomSelect
                                            success={validation.country_id === "success"}
                                            error={validation.country_id === "error"}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: 'custom-input body'
                                            }}
                                            labelText="Country"
                                            selectProps={{
                                                onChange: (e) => this.onCountryChange(e),
                                                value: values.country_id
                                            }}
                                            inputProps={{
                                                name: "country_id",
                                                id: "input-countryId",
                                                className: classes.alignLeft,
                                                disabled: !editInfo
                                            }}
                                            items={countries}
                                            itemLabel="name"
                                            itemValue="id"
                                            color="custom"
                                        />
                                    :
                                        loadingCountries ?
                                            <LoaderComponent color="custom" align="left" />
                                        :
                                        <></>
                                }
                                {
                                    states.length > 0 ?
                                        <CustomSelect 
                                            formControlProps={{
                                                fullWidth: true,
                                                className: 'custom-input body'
                                            }}
                                            labelText="State / Province"
                                            selectProps={{
                                                onChange: (e) => this.onStateChange(e),
                                                value: values.state_id
                                            }}
                                            inputProps={{
                                                name: "state_id",
                                                id: "input-stateId",
                                                className: classes.alignLeft,
                                                disabled: !editInfo
                                            }}
                                            items={states}
                                            itemLabel="name"
                                            itemValue="id"
                                            color="custom"
                                        />
                                    :
                                        loadingStates ?
                                            <LoaderComponent color="custom" align="left" />
                                        :
                                        <></>
                                }
                                {
                                    cities.length > 0 ?
                                        <CustomSelect 
                                            formControlProps={{
                                                fullWidth: true,
                                                className: 'custom-input body'
                                            }}
                                            labelText="City"
                                            selectProps={{
                                                onChange: (e) => this.handleChange(e, "city_id"),
                                                value: values.city_id
                                            }}
                                            inputProps={{
                                                name: "city_id",
                                                id: "input-cityId",
                                                className: classes.alignLeft,
                                                disabled: !editInfo
                                            }}
                                            items={cities}
                                            itemLabel="name"
                                            itemValue="id"
                                            color="custom"
                                        />
                                    :
                                        loadingCities ?
                                            <LoaderComponent color="custom" align="left" />
                                        :
                                        <></>
                                }
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                    id="input-address1"
                                    labelText="Address1"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e, 'address1'),
                                        name: "address1",
                                        value: values.address1,
                                        disabled: !editInfo
                                    }}
                                />
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                    id="input-address2"
                                    labelText="Address2"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e, 'address2'),
                                        name: "address2",
                                        value: values.address2,
                                        disabled: !editInfo
                                    }}
                                />
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input body"
                                    }}
                                    id="input-address3"
                                    labelText="Address3"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e, 'address3'),
                                        name: "address3",
                                        value: values.address3,
                                        disabled: !editInfo
                                    }}
                                />
                                <FormControlLabel
                                    classes={{label: classes.label}}
                                    disabled={!editInfo}
                                    control={
                                        <Checkbox                                                
                                            tabIndex={-1}
                                            onClick={(e) => this.handleCheckbox(e, "promotions")}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{checked: classes.checked, root: classes.checkRoot}}
                                            checked={(values.promotions ? true : false)}
                                        />
                                    }
                                    label={"I agree to recieve instructional and promotion emails"}
                                />
                                <FormControlLabel
                                    classes={{label: classes.label}}
                                    disabled={!editInfo}
                                    control={
                                        <Checkbox                                                
                                            tabIndex={-1}
                                            onClick={(e) => this.handleCheckbox(e, "terms")}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{checked: classes.checked, root: classes.checkRoot}}
                                            checked={(values.terms ? true : false)}
                                        />
                                    }
                                    label={
                                        <span>I agree to Sendlinx's <Link href="https://sendlinx.com/terms-of-use/" target="_blank">Term of Use</Link> & <Link href="https://sendlinx.com/privacy-policy/" target="_blank">Privacy Policy</Link></span>
                                    }
                                />
                            </div>
                            <div>
                                {
                                    editInfo === true ?
                                        <div className={"saveButtons saveButtonsBottom"}>
                                            <Button color="white" onClick={() => this.editUserInfo(false)}>
                                                Cancel
                                            </Button>
                                            <Button color="custom" disabled={this.saveButtonDisabled()} onClick={() => this.saveUserInfo()}>
                                                Save
                                            </Button>
                                        </div>
                                    :
                                        editInfo === false && savingInfo === false ?
                                            <div className={"editButton editButtonBottom"}>
                                                <Button color="custom" onClick={() => this.editUserInfo()}>Edit</Button>
                                            </div>
                                        :
                                        savingInfo ? <LoaderComponent color="custom" align="left" /> : <></>
                                }
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
                {
                    phoneVerificationModal ?
                        <TwoFaVerificationModal 
                            saving={saving}
                            open={true} 
                            onSuccess={(code) => this.onTwoFaSuccess(code)}
                            onClose={() => this.onTwoFaClose()}
                            errorMessage={verificationError}
                            success={verificationSuccess}
                            onResendCode={() => this.resendCode()}
                        />
                    :
                    null
                }
            </div>
        )
    }
}

Profile.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Profile);
