import Cookie from './app/assets/js/utils/Cookie';

const Config = {
    FB_APP_ID: "550186362162847",
    GOOGLE_CLIENT_ID: "368231056525-n3nlvvpjbo257u5tm2ahhkg159fqcsre.apps.googleusercontent.com",
    X_API_KEY: "XIHscYkVIZ8k0NDODleMR9a0wKcuR0Dn2xFwvqlb",
    GOOGLE_ANALYTICS_ID: "UA-85612507-5",
    REACT_APP_LOG_LEVEL: "DEBUG",
    REACT_APP_CLOUDFRONT_IMAGES: "https://int-cdn1-us-west-2.smearch.com",
    API_URL: "https://localhost/sendlinxapi/public/api/",
    WOPI_API_URL: "https://localhost/sendlinxapi/public/",
    REACT_APP_OAUTH_CONSUMER_KEY: "k8h2kzgr4snfj7bhuv5242ct1k305nlg",
    REACT_APP_OAUTH_CONSUMER_SECRET: "b63cocgu8m73wtskd3lvv5m9esn3b4ju",
    YOUTUBE_DATA_API_KEY: "AIzaSyBjqffUyA85fYrxNnlbiaVqZFQ34QkIHI4",
    GOOGLEDRIVE_CLIENT_ID: "404939773326-jl8i30va3e47v19ustt5rk9sup9ro9pp.apps.googleusercontent.com",
    GOOGLEDRIVE_REDIRECT_URL: "https://localhost:3000/user/extensions",
    GOOGLEDRIVE_SCOPE: "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    GOOGLEDRIVE_APPID: "404939773326",
    getApiUrl(){
        try{
            if(process.env.REACT_APP_API_URL){
                return process.env.REACT_APP_API_URL;
            }
        }catch(e){  }
        return this.API_URL;
    },
    getWopiApiUrl(){
        try{
            if(process.env.REACT_APP_WOPI_API_URL){
                return process.env.REACT_APP_WOPI_API_URL;
            }
        }catch(e){  }
        return this.WOPI_API_URL;
    },
    getRedirectUri(){
        let returnUrl = "";
        return "https://"+document.location.host+returnUrl;
    },
    
    getConsumerKey(){
        try{
            if(process.env.REACT_APP_OAUTH_CONSUMER_KEY){
                return process.env.REACT_APP_OAUTH_CONSUMER_KEY;
            }
        }catch(e){  }
        return this.REACT_APP_OAUTH_CONSUMER_KEY;
    },
    getConsumerSecret(){
        try{
            if(process.env.REACT_APP_OAUTH_CONSUMER_SECRET){
                return process.env.REACT_APP_OAUTH_CONSUMER_SECRET;
            }
        }catch(e){  }
        return this.REACT_APP_OAUTH_CONSUMER_SECRET;
    },
    getGoogleAnalyticsId(){
        try{
            if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID){
                return process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
            }
        }catch(e){  }
        return this.GOOGLE_ANALYTICS_ID;
    },
    getFbAppId(){
        try{
            if(process.env.REACT_APP_FB_APP_ID){
                return process.env.REACT_APP_FB_APP_ID;
            }
        }catch(e){  }
        return this.FB_APP_ID;
    },
    getGoogleClientId(){
        try{
            if(process.env.REACT_APP_GOOGLE_CLIENT_ID){
                return process.env.REACT_APP_GOOGLE_CLIENT_ID;
            }
        }catch(e){  }
        return this.GOOGLE_CLIENT_ID;
    },
    getLogType(){
        try{
            if(process.env.REACT_APP_LOG_LEVEL){
                return process.env.REACT_APP_LOG_LEVEL;
            }
        }catch(e){  }
        return this.REACT_APP_LOG_LEVEL;
    },
    getApiHeaders(){
        let authorization = Cookie.read("authorization");
        if(!authorization || authorization === null){
            authorization = this.getConsumerKey();
        }
        const token = Cookie.read("oauth_token");
        const secret = Cookie.read("oauth_secret");
        const oauthIdentity = localStorage.getItem("oauth-identity");
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': document.location.origin,
            'oauth-consumer-key': authorization,
        };
        if(token){
            headers['oauth-token'] = token;
        }
        if(secret){
            headers['oauth-secret'] = secret;
        }
        if(oauthIdentity){
            headers['oauth-identity'] = oauthIdentity;
        }
        
        return headers;
    },
    getDisallowedExtensions(){
        try{
            if(process.env.REACT_APP_DISALLOWED_EXTENSIONS){
                return process.env.REACT_APP_DISALLOWED_EXTENSIONS;
            }
        }catch(e){  }
        return "";
    },
    isWopiAllowed(){
        try{
            if(process.env.REACT_APP_WOPI_ALLOWED && process.env.REACT_APP_WOPI_ALLOWED === "true"){
                return true;
            }
        }catch(e){  }
        return false;
    },
    isLimitedAccess(){
        try{
            if(process.env.REACT_APP_LIMITED_ACCESS && process.env.REACT_APP_LIMITED_ACCESS === "true"){
                return true;
            }
        }catch(e){  }
        return false;
    },
    isDocusignAllowed(){
        try{
            if(process.env.REACT_APP_DOCUSIGN_ALLOWED && process.env.REACT_APP_DOCUSIGN_ALLOWED === "true"){
                return true;
            }
        }catch(e){  }
        return false;
    },
    isAdobesignAllowed(){
        try{
            if(process.env.REACT_APP_ADOBESIGN_ALLOWED && process.env.REACT_APP_ADOBESIGN_ALLOWED === "true"){
                return true;
            }
        }catch(e){  }
        return false;
    },
    getEmailRegex(){
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    },
    getYoutubeDataApiKey(){
        try{
            if(process.env.YOUTUBE_DATA_API_KEY){
                return process.env.YOUTUBE_DATA_API_KEY;
            }
        }catch(e){  }
        return this.YOUTUBE_DATA_API_KEY;
    },
    getDocusignAuthUrl(){
        try{
            if(process.env.REACT_APP_DOCUSIGN_AUTH_URL){
                return process.env.REACT_APP_DOCUSIGN_AUTH_URL;
            }
        }catch(e){  }
        return this.REACT_APP_DOCUSIGN_AUTH_URL;
    },
    getDocusignRedirectUrl(){
        return window.location.origin+window.location.pathname+"/connect/docusign";
    },
    getDocusignLoginUrl(){
        const clientId = this.getDocusignClientId();
        return this.getDocusignAuthUrl()+"/oauth/auth?response_type=code&scope=signature impersonation&client_id="+clientId+"&redirect_uri="+encodeURIComponent(this.getDocusignRedirectUrl());
    },
    getDocusignSignupUrl(){
        const clientId = this.getDocusignClientId();
        return this.getDocusignAuthUrl()+"/sign-up?response_type=code&scope=signature impersonation&client_id="+clientId+"&redirect_uri="+encodeURIComponent(this.getDocusignRedirectUrl());
    },
    getDocusignClientId(){
        try{
            if(process.env.REACT_APP_DOCUSIGN_CLIENT_ID){
                return process.env.REACT_APP_DOCUSIGN_CLIENT_ID;
            }
        }catch(e){  }
        return this.REACT_APP_DOCUSIGN_CLIENT_ID;
    },
    getAdobesignAuthUrl(){
        try{
            if(process.env.REACT_APP_ADOBESIGN_AUTH_URL){
                return process.env.REACT_APP_ADOBESIGN_AUTH_URL;
            }
        }catch(e){  }
        return this.REACT_APP_ADOBESIGN_AUTH_URL;
    },
    getAdobesignRedirectUrl(){
        return window.location.origin+window.location.pathname+"/connect/adobesign";
    },
    getAdobesignLoginUrl(state){
        const clientId = this.getAdobesignClientId();
        return this.getAdobesignAuthUrl()+"/public/oauth/v2?response_type=code&client_id="+clientId+"&redirect_uri="+encodeURIComponent(this.getAdobesignRedirectUrl())+"&scope=user_read:self+user_write:self+user_login:self+agreement_read:self+agreement_write:self+agreement_send:self+webhook_read:self+webhook_write:self+webhook_retention:self+workflow_read:self+workflow_write:self&state="+state;
    },
    getAdobesignSignupUrl(state){
        const clientId = this.getAdobesignClientId();
        return this.getAdobesignAuthUrl()+"/public/oauth/v2?response_type=code&client_id="+clientId+"&redirect_uri="+encodeURIComponent(this.getAdobesignRedirectUrl())+"&scope=user_read:self+user_write:self+user_login:self+agreement_read:self+agreement_write:self+agreement_send:self+webhook_read:self+webhook_write:self+webhook_retention:self+workflow_read:self+workflow_write:self&state="+state;
    },
    getAdobesignClientId(){
        try{
            if(process.env.REACT_APP_ADOBESIGN_CLIENT_ID){
                return process.env.REACT_APP_ADOBESIGN_CLIENT_ID;
            }
        }catch(e){  }
        return this.REACT_APP_ADOBESIGN_CLIENT_ID;
    },
    getClientIdSharepoint(){
        try{
            if(process.env.REACT_APP_SHAREPOINT_CLIENT_ID){
                return process.env.REACT_APP_SHAREPOINT_CLIENT_ID;
            }
        }catch(e){  }
       // return this.API_URL;
    },
    getSharepointRedirectUrl(){
        try{
            if(process.env.REACT_APP_SHAREPOINT_REDIRECT_URL){
                return process.env.REACT_APP_SHAREPOINT_REDIRECT_URL;
            }
        }catch(e){  }
    },
    getSharepointScope(){
        try{
            if(process.env.REACT_APP_SHAREPOINT_SCOPE){
                return process.env.REACT_APP_SHAREPOINT_SCOPE;
            }
        }catch(e){  }
    },
    getClientIdOneDrive(){
        try{
            if(process.env.REACT_APP_ONEDRIVE_CLIENT_ID){
                return process.env.REACT_APP_ONEDRIVE_CLIENT_ID;
            }
        }catch(e){  }
       // return this.API_URL;
    },
    getOnedriveScope(){
        try{
            if(process.env.REACT_APP_ONEDRIVE_SCOPE){
                return process.env.REACT_APP_ONEDRIVE_SCOPE;
            }
        }catch(e){  }
    },
    getRedirectUrlOneDrive(){
        try{
            if(process.env.REACT_APP_ONEDRIVE_REDIRECT_URL){
                return process.env.REACT_APP_ONEDRIVE_REDIRECT_URL;
            }
        }catch(e){  }
       // return this.API_URL;
    },
    getGoogleDriveClientId(){
        try {
            if(process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID){
                return process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID;
            }
        }catch(e) { }
        return this.GOOGLEDRIVE_CLIENT_ID;
    },
    getGoogleDriveScope(){
        try{
            if(process.env.REACT_APP_GOOGLEDRIVE_SCOPE){
                return process.env.REACT_APP_GOOGLEDRIVE_SCOPE;
            }
        }catch(e){  }
        return this.GOOGLEDRIVE_SCOPE;
    },
    getGoogleDriveRedirectUrl(){
        try{
            if(process.env.REACT_APP_GOOGLEDRIVE_REDIRECT_URL){
                return process.env.REACT_APP_GOOGLEDRIVE_REDIRECT_URL;
            }
        }catch(e){  }
        return this.GOOGLEDRIVE_REDIRECT_URL;
    },
    getGoogleDriveAppId(){
        try{
            if(process.env.REACT_APP_GOOGLEDRIVE_APPID){
                return process.env.REACT_APP_GOOGLEDRIVE_APPID;
            }
        }catch(e){  }
        return this.GOOGLEDRIVE_APPID;
    },
};

export default Config;